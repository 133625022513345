import {Feedback} from "../types/create-feedback";
import {Option} from "../types/common";

export const POSTS_PER_PAGE = 24;

export const PHOTO_GALLERIES_PER_PAGE = 9;

export const VIDEOS_PER_PAGE = 18;

export const MAX_PAGINATION_ITEMS = 10;

export const FEEDBACKS_PER_PAGE = 24;

export enum SendFeedbackStatus {
  Success = 'SUCCESS',
  UnSuccess = 'UN_SUCCESS',
  Pending = 'PENDING',
}

export const INITIAL_FORM_STATE: Feedback = {
  first_name: '',
  last_name: '',
  email: '',
  city: '',
  request: '',
  recipient: 'admin',
};

export const TEAM_IDS = {
  YENISEY: 1,
  YENISEY_2: 2
};

export const teamOptions: Option[] = [
  {value: '1', label: 'Енисей'},
  {value: '2', label: 'Енисей-2'}
];

export const playersTypeOptions: Option[] = [
  {value: 'all', label: 'Все игроки'},
  {value: 'goalkeepers', label: 'Вратари'}
];

export const PLAYERS_FILTERS = {
  Goalkeepers: 'Вратари',
  Players: 'Полевые игроки',
  Staff: 'Тренеры и администрация'
};

export enum BannersPositions {
  Sidebar = 'sidebar',
  MainPage = 'main-index-after-slider'
}

export const RoundsTypes = {
  RoundRobinOne: 'round-robin-one',
  RoundRobinTwo: 'round-robin-two',
  RoundRobinThree: 'round-robin-three',
  PlayoffOlympic: 'playoff-olympic',
  Free: 'free'
}

//временно
export const seasonOptions: Option[] = [
  {value: '1', label: 'Все сезоны'},
  {value: '2', label: 'вариант 1'},
  {value: '1', label: 'вариант 2 длинный в несколько строчек длинный'},
];

export enum SendRequestStatus {
  Success = "SUCCESS",
  UnSuccess = "UN_SUCCESS",
  Pending = "PENDING",
  Normal = "NORMAL",
}

export const PAGESIZE = 0;