import React from "react";
import {Banner} from "../../../core/types/banner";
import {Link} from "react-router-dom";

function SidebarBannersItem({banner}: { banner: Banner }) {
  return (
    <>
      <Link to={banner.link} target={banner.blank ? '_blank' : '_self'}>
        <img className="sidebar-banner" src={banner._links.image.href} width="205" height="273" alt={banner.name}/>
      </Link>
      <br/>
    </>
  );
}

export default SidebarBannersItem;