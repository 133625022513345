import React from 'react';
import {Banner} from "../../../core/types/banner";
import {Link} from "react-router-dom";

function BannersSliderItem({banner}: {banner: Banner}) {
  return (
    <div>
      <Link className="banner" to={banner.link} target={banner.blank ? '_blank' : '_self'}>
        <img className="banner__image" src={banner._links.image.href} width="720" alt="Изображение баннера" loading="lazy"/>
      </Link>
    </div>
  );
}

export default BannersSliderItem;