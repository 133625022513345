import React from 'react';
import {Link} from 'react-router-dom';;
import {PhotoGallery} from '../../../core/types/media';
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {AppRoute} from "../../../core/constants/routes";

function PhotoGalleryItem({photoGallery} : {photoGallery: PhotoGallery}) {
  const photoGalleryDate = new Date(photoGallery.published_dt);

  return (
    <li>
      <Link to={`${AppRoute.PhotoGalleries.url}/${photoGallery.album_id}`}>
        <div className="preview">
          <img src={photoGallery._links.coverUrl.href} alt={photoGallery.title}/>
          <i><span>Смотреть {photoGallery.photos.length} фото</span></i>
        </div>
        <div className="title">{photoGallery.title}</div>
        <div className="addit">{simpleDateFormat(photoGalleryDate, 'dd MMMM yyyy')}</div>
      </Link>
    </li>
  );
}

export default PhotoGalleryItem;
