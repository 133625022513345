import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getPressPage } from "../../../core/store/clubs-data/selectors";
import SidebarNavigation from "../sidebar-navigation/sidebar-navigation";
import { AppRoute } from "../../../core/constants/routes";
import { useParams } from "react-router-dom";
import { fetchChaptersChild } from "../../../core/store/api-actions";
import { getChapters } from "../../../core/store/chapters-data/selectors";
import { NavigationsType } from "../../../core/types/navigation";

const PressSidebar = () => {
  const dispatch = useAppDispatch();
  const { id: chapterId } = useParams();
  const pressPage = useAppSelector(getPressPage);
  const chaptersChild = useAppSelector(getChapters);

  useEffect(() => {
    if (chapterId !== undefined) {
      dispatch(fetchChaptersChild(Number(chapterId)));
    }
  }, [dispatch, chapterId, pressPage]);

  let navigationArray: NavigationsType;
  if (chaptersChild.length > 0) {
    navigationArray = chaptersChild.map((chapter) => ({
      title: chapter.name,
      url: `${AppRoute.PressPage.url}/chapters/${chapter.chapter_id}`,
      id: chapter.chapter_id,
    }));
  } else if (pressPage && pressPage.chapters) {
    navigationArray = pressPage.chapters.map((chapter) => ({
      title: chapter.name,
      url: `${AppRoute.PressPage.url}/chapters/${chapter.chapter_id}`,
      id: chapter.chapter_id,
    }));
  } else {
    navigationArray = [];
  }
  return (
    <aside>
      <SidebarNavigation navigationArray={navigationArray} />
    </aside>
  );
};

export default PressSidebar;
