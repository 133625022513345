import React, {useEffect} from 'react';
import {PlayerItem} from "../../../core/types/player";
import {
  calculateAge,
  simpleDateFormat
} from "../../../core/utils/date-time-helpers";
import {fetchPosition} from "../../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import { getPosition} from "../../../core/store/players-data/selectors";

function PlayerHead({player}: { player: PlayerItem | null }) {
  const dispatch = useAppDispatch();
  const birthDate = player && player.birthday ? new Date(player.birthday) : null;
  const birthInfo = birthDate ? `${calculateAge(birthDate)} ${simpleDateFormat(birthDate, '(dd.MM.yyyy)')}` : '-';
  const position = useAppSelector(getPosition);


  useEffect(() => {
    if (player) {
      dispatch(fetchPosition(Number(player?.position_id)));
    }
  }, [player?.position_id]);

  return (
    <>
      <div style={{float: 'left', margin: '0 20px 15px 0'}}>
        <a href="#" data-fancybox="">
          <img src={player?._links.photo.href.includes('photo_placeholder_thumb.png') ? '/images/defaults/person.svg' : player?._links.photo.href} style={{width: '200px', height: 'auto'}} alt={`${player?.last_name} ${player?.first_name} ${player?.middle_name}`}/>
        </a>
      </div>
      <table className="stat-table char" style={{width: 'calc(100% - 220px)'}}>
        <tbody>
        <tr>
          <th>Характеристика</th>
          <th style={{width: '35%'}}>Данные</th>
        </tr>
        <tr>
          <td>Команда</td>
          <td>{player?.team?.full_name} {player?.team?.city?.title ? `(${player?.team?.city?.title})` : ''}</td>
        </tr>
        <tr>
          <td>Сезоны в команде</td>
          <td>{player?.seasons_in_club || '-'}</td>
        </tr>
        <tr>
          <td>Номер в команде</td>
          <td>{player?.number || "-"}</td>
        </tr>
        <tr>
          <td>Возраст (Дата рождения)</td>
          <td>{birthInfo}</td>
        </tr>
        <tr>
          <td>Амплуа</td>
          <td>{position?.long_title || "-"}</td>
        </tr>
        <tr>
          <td>Звание</td>
          <td>{player?.sports_category || "-"}</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default PlayerHead;
