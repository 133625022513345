import React from 'react';

function PointsItem() {
  return (
    <li className="points__item">
      <div className="points__circle"/>
    </li>
  );
}

export default PointsItem;