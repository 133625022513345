import React, { useState } from "react";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { Video } from "../../../core/types/media";
import Modal from "react-modal";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

Modal.setAppElement("#root");

function VideosSliderItem({ video }: { video: Video }) {
  const videoDate = new Date(video.published_dt);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <div className="slides__item">
      <a className="slides__link" onClick={openModal}>
        <div className="slides__relative">
          <img
            className="slides__img"
            src={video.previewUrl || "/images/defaults/video.svg"}
            width="690"
            height="386"
            alt={video.title}
          />
          <svg className="slides__play" width="100" height="100">
            <use xlinkHref="images/sprite.svg#play" />
          </svg>
        </div>
        <div className="slides__content">
          <p className="slides__title">{video.title}</p>
          <div hidden={video.description === null} className="slides__text">
            <HtmlRenderer htmlString={video.description} />
          </div>
          <p className="slides__date">
            {simpleDateFormat(videoDate, "dd.MM.yyyy")}
          </p>
        </div>
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Видео"
      >
        <div className="video-modal">
          <a className="video-modal__closer" onClick={closeModal}>
            <i className="video-modal__icon fa fa-times" aria-hidden="true" />
          </a>
          <HtmlRenderer htmlString={video?.code || ""} />
        </div>
      </Modal>
    </div>
  );
}

export default VideosSliderItem;
