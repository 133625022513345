import {TeamsTablesState} from "../../types/state";
import {
  createSlice,
} from "@reduxjs/toolkit";
import {NameSpace} from "../../constants/api-constants";
import {
  fetchTeamsTables,
  fetchTeamsTablesGroup,
} from "../api-actions";

const initialState: TeamsTablesState = {
  isTeamsTablesLoading: false,
  isTeamsTablesError: false,
  teamsTables: [],
  isTeamsTablesGroupLoading: false,
  isTeamsTablesGroupError: false,
  teamsTablesGroups: [],
};

export const teamsTablesData = createSlice({
  name: NameSpace.TeamsTables,
  initialState,
  reducers: {
    resetTeamsTables: (state) => {
      state.teamsTables = initialState.teamsTables;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTeamsTables.pending, (state) => {
        state.isTeamsTablesLoading = true;
      })
      .addCase(fetchTeamsTables.fulfilled, (state, action) => {
        state.teamsTables = action.payload;
        state.isTeamsTablesLoading = false;
      })
      .addCase(fetchTeamsTables.rejected, (state) => {
        state.isTeamsTablesError = true;
        state.isTeamsTablesLoading = false;
      })
      .addCase(fetchTeamsTablesGroup.pending, (state) => {
        state.isTeamsTablesGroupLoading = true;
      })
      .addCase(fetchTeamsTablesGroup.fulfilled, (state, action) => {
        const existingTeamsTable = state.teamsTablesGroups.find(teamsTablesGroup => teamsTablesGroup.id === action.payload.id);
        if (!existingTeamsTable) {
          state.teamsTablesGroups.push(action.payload);
        }
        state.isTeamsTablesGroupLoading = false;
      })
      .addCase(fetchTeamsTablesGroup.rejected, (state) => {
        state.isTeamsTablesGroupError = true;
        state.isTeamsTablesGroupLoading = false;
      })
  }
});

export const {resetTeamsTables} = teamsTablesData.actions