import React from 'react';

function SidebarFilterItem({activeSection, onSectionClick, filterItem} : {activeSection: string | null, onSectionClick: (sectionTitle: string) => void, filterItem: string}) {
  return (
    <li className={activeSection === filterItem ? 'active' : ''}>
      <a onClick={() => onSectionClick(filterItem)}>{filterItem}</a>
    </li>
  );
}

export default SidebarFilterItem;