import {useAppSelector} from "../../core/hooks";
import {useBreadcrumbsContext} from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import React, {useEffect} from "react";
import {AppRoute} from "../../core/constants/routes";
import Page from "../../components/ordinary/page/page";
import FeedbackComponent from "../../components/ordinary/feedback-component/feedback-component";
import {
  getContactsPage,
  getContactsPageErrorStatus,
  getContactsPageLoadingStatus
} from "../../core/store/contacts-data/selectors";
import {store} from "../../core/store";
import {fetchContactsPage} from "../../core/store/api-actions";

store.dispatch(fetchContactsPage());

function Contacts() {
  const contactsPage = useAppSelector(getContactsPage);
  const isContactsPageLoading = useAppSelector(getContactsPageLoadingStatus);
  const isContactsPageError = useAppSelector(getContactsPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Contacts]);
  }, [setBreadcrumbs]);

  return (
    <div className="container-center">
      <main className="full">
        <Page content={contactsPage?.text} isPageLoading={isContactsPageLoading} isPageError={isContactsPageError}/>
        <FeedbackComponent title="Обратная связь"/>
      </main>
    </div>
  );
}

export default Contacts;