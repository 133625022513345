import {createSlice} from '@reduxjs/toolkit';
import {fetchPartners} from '../api-actions';
import {PartnersState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: PartnersState = {
  isPartnersLoading: false,
  isPartnersError: false,
  partners: [],
};

export const partnersData = createSlice({
  name: NameSpace.Partners,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPartners.pending, (state) => {
        state.isPartnersLoading = true;
      })
      .addCase(fetchPartners.fulfilled, (state, action) => {
        state.partners = action.payload;
        state.isPartnersLoading = false;
      })
      .addCase(fetchPartners.rejected, (state) => {
        state.isPartnersError = true;
        state.isPartnersLoading = false;
      })
  }
});
