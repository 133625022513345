import React from 'react';
import PostsList from "../posts-list/posts-list";
import {useAppSelector} from "../../../core/hooks";
import {getPosts} from "../../../core/store/posts-data/selectors";
import Title from "../title/title";
import {AppRoute} from "../../../core/constants/routes";
import SidebarBannersList from "../../smart/sidebar-banners-list/sidebar-banners-list";
import {
  excludePostById,
  getTopItems
} from "../../../core/utils/array-prepare-helpers";

function OtherNewsSidebar({postId}: { postId: string | undefined }) {
  const posts = useAppSelector(getPosts);
  const updatedPosts = excludePostById(posts, Number(postId));
  const shortenedPosts = getTopItems(updatedPosts, 3);

  return (
    <aside>
      <Title title="Другие новости" url={AppRoute.Posts.url}/>
      <div className="block-press">
        <PostsList currentPosts={shortenedPosts}/>
      </div>
      <br/>
      <SidebarBannersList/>
    </aside>
  );
}

export default OtherNewsSidebar;
