import React from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {
  fetchTournament,
  fetchTournamentStats
} from '../../core/store/api-actions';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import 'font-awesome/css/font-awesome.min.css';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {
  getTournament,
  getTournamentErrorStatus,
  getTournamentLoadingStatus,
  getTournamentsStats
} from "../../core/store/tournaments-data/selectors";
import {TournamentsNavigations} from "../../core/constants/navigations";
import TournamentNavigation from "../../components/ordinary/tournament-navigation/tournament-navigation";
import {findTournamentStatsById} from "../../core/utils/array-prepare-helpers";
import TournamentSpecifications from "../../components/ordinary/tournament-specifications/tournament-specifications";
import TournamentScore from "../../components/ordinary/tournament-score/tournament-score";
import TournamentStats from "../../components/ordinary/tournament-stats/tournament-stats";
import {tournamentTypes} from "../../core/constants/tournament";

function TournamentStatistics() {
  const dispatch = useAppDispatch();
  const {id: tournamentId} = useParams();
  const tournament = useAppSelector(getTournament);
  const isTournamentError = useAppSelector(getTournamentErrorStatus);
  const isTournamentLoading = useAppSelector(getTournamentLoadingStatus);
  const tournamentsStats = useAppSelector(getTournamentsStats);
  const tournamentStats = findTournamentStatsById(tournamentsStats, Number(tournamentId));
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchTournament(Number(tournamentId)));
    dispatch(fetchTournamentStats(Number(tournamentId)));
  }, [tournamentId]);

  useEffect(() => {
    if (tournament) {
      setBreadcrumbs([
        AppRoute.Main,
        {
          title: 'Статистика',
          url: AppRoute.Tournaments.url
        },
        {
          title: `${tournamentTypes[tournament.category]}.${tournament.full_name}`,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/info`
        },
        {
          title: 'Сводная статистика',
          url: '/'
        },
      ]);
    }
  }, [tournament]);

  return (
    <div className="container-center">
      <main>
        <div hidden={!isTournamentLoading}>
          <Loader/>
        </div>
        <div hidden={!isTournamentError || isTournamentLoading}>
          <Error/>
        </div>
        <div className="stats other" hidden={isTournamentError || isTournamentLoading}>
          <TournamentSpecifications tournamentStats={tournamentStats}/>
          <br/>
          <TournamentScore tournamentStats={tournamentStats}/>
          <br/>
          <TournamentStats tournamentStats={tournamentStats}/>
        </div>
      </main>
      <aside>
        <TournamentNavigation navigationArray={TournamentsNavigations} id={tournamentId}/>
      </aside>
    </div>
  );
}

export default TournamentStatistics;
