import {createSlice} from "@reduxjs/toolkit";
import {NameSpace} from "../../constants/api-constants";
import {
  fetchCities,
  fetchCity
} from "../api-actions";
import {CitiesState} from "../../types/state";

const initialState: CitiesState = {
  isCitiesLoading: false,
  isCityLoading: false,
  isCitiesError: false,
  isCityError: false,
  currentCity: null,
  cities: [],
};

export const citiesData = createSlice({
  name: NameSpace.Cities,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.isCitiesLoading = true;
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.isCitiesLoading = false;
      })
      .addCase(fetchCities.rejected, (state) => {
        state.isCitiesError = true;
        state.isCitiesLoading = false;
      })
      .addCase(fetchCity.pending, (state) => {
        state.isCityLoading = true;
      })
      .addCase(fetchCity.fulfilled, (state, action) => {
        state.currentCity = action.payload;
        state.isCityLoading = false;
      })
      .addCase(fetchCity.rejected, (state) => {
        state.isCityError = true;
        state.isCityLoading = false;
      });
  },
});
