import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PostsSlider from "../posts-slider/posts-slider";
import PostPreview from "../post-preview/post-preview";
import { AppRoute } from "../../../core/constants/routes";
import Title from "../title/title";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getPosts } from "../../../core/store/posts-data/selectors";
import {
  excludePostById,
  filterItemsByMain,
  getActualItem,
  sortItemsByPublicationDate,
} from "../../../core/utils/array-prepare-helpers";
import { fetchPosts } from "../../../core/store/api-actions";
import Loader from "../loader/loader";

function PostsWidget() {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(getPosts);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchPosts())
      .then(() => setIsLoading(false))
      .catch((error) => {
        console.error("Failed to fetch posts:", error);
        setIsLoading(false);
      });
  }, [dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  if (!posts) {
    return null;
  }

  const filteredPosts = filterItemsByMain(posts);
  const sortedPosts = sortItemsByPublicationDate(filteredPosts)

  const mainPost = getActualItem(sortedPosts);

  if (filteredPosts.length === 0 && !mainPost) {
    return null;
  }
  return (
    <div
      hidden={filteredPosts.length === 0 && mainPost === undefined}
      className="block-press"
    >
      <div className="container-center">
        <Title title="Новости" />
        <div className="all-news">
          <Link to={AppRoute.Posts.url}>Все новости</Link>
        </div>
        <div className="media media--dark">
          <PostPreview post={mainPost} />
          <PostsSlider
            posts={excludePostById(sortedPosts, mainPost?.post_id)}
          />
        </div>
      </div>
    </div>
  );
}

export default PostsWidget;
