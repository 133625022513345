import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {
  PlayerItems,
  TeamPlayersGroups
} from "../../types/player";

export const getTeamPlayersLoading = (state: State): boolean => state[NameSpace.TeamPlayers].teamPlayersLoading;
export const getTeamPlayersError = (state: State):boolean => state[NameSpace.TeamPlayers].teamPlayersError;
export const getTeamPlayers = (state: State): PlayerItems => state[NameSpace.TeamPlayers].teamPlayers;
export const getTeamPlayersGroupLoading = (state: State): boolean => state[NameSpace.TeamPlayers].teamPlayersGroupLoading;
export const getTeamPlayersGroupError = (state: State):boolean => state[NameSpace.TeamPlayers].teamPlayersGroupError;
export const getTeamPlayersGroups = (state: State): TeamPlayersGroups => state[NameSpace.TeamPlayers].teamPlayersGroups;