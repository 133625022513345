import React from "react";
import {RepresentativeItem} from "../../../core/types/representative";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {AppRoute} from "../../../core/constants/routes";
import {Link} from "react-router-dom";

function RepresentativesItem({representative}: {representative: RepresentativeItem}) {
  const birthDate = representative?.birthday ? new Date(representative.birthday) : null;
  const src = representative._links.photo.href.includes('photo_placeholder_thumb.png') ? '/images/defaults/person.svg' : representative._links.photo.href;

  return (
    <li className="persons__item">
      <Link className="persons__link" to={`${AppRoute.Representatives.url}/${representative.staff_id}`}>
        <div className="persons__wrap">
          <img className="persons__img" src={src} width="350" height="350" alt={`${representative.first_name} ${representative.last_name}`} />
        </div>
        <div className="persons__content">
          <div className="persons__row">
            <div className="persons__column">
              <p className="persons__name">{representative.first_name} {representative.last_name}</p>
              <p className="persons__role">{representative.position}</p>
            </div>
          </div>
          <p className="persons__name" hidden={!birthDate}>{birthDate ? simpleDateFormat(birthDate, 'dd.MM.yyyy') : ''}</p>
        </div>
      </Link>
    </li>
  );
}

export default RepresentativesItem;
