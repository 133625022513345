import React from 'react';
import {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from '../../core/constants/routes';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {
  getProcurements,
  getProcurementsPage,
  getProcurementsPageErrorStatus,
  getProcurementsPageLoadingStatus
} from '../../core/store/procurements-data/selectors';
import {
  fetchProcurements,
  fetchProcurementsPage
} from '../../core/store/api-actions';
import ProcurementList from '../../components/ordinary/procurement-list/procurements-list';
import Page from "../../components/ordinary/page/page";

function Procurements() {
  const dispatch = useAppDispatch();
  const procurements = useAppSelector(getProcurements);
  const procurementsPage = useAppSelector(getProcurementsPage);
  const isProcurementsPageLoading = useAppSelector(getProcurementsPageLoadingStatus);
  const isProcurementsPageError = useAppSelector(getProcurementsPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchProcurements());
    dispatch(fetchProcurementsPage());
  }, [dispatch]);

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Procurements]);
  }, []);

  return (
    <div className="container-center">
      <main className="full">
        <Page content={procurementsPage?.text} isPageLoading={isProcurementsPageLoading} isPageError={isProcurementsPageError}/>
        <ProcurementList currentProcurements={procurements} />
      </main>
    </div>
  );
}

export default Procurements;
