import React from "react";

function Inspector({ inspector }: { inspector: string | null | undefined }) {
  return (
    <table className="stat-table char" hidden={inspector === undefined || inspector === null}>
      <tbody>
        <tr>
          <th colSpan={2}>Инспектор</th>
        </tr>
        <tr>
          <td colSpan={2}>
            <p>{inspector}</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default Inspector;
