import React from 'react';
import {BreadcrumbsType, BreadcrumbType} from '../../../core/types/breadcrumbs';
import {Link} from "react-router-dom";

const Breadcrumbs = ({breadcrumbs}: {breadcrumbs: BreadcrumbsType}) => {
  const lastBreadcrumb:BreadcrumbType = breadcrumbs[breadcrumbs.length - 1];
  const title = lastBreadcrumb?.title ?? '';

  return (
    <div className="title-block">
      <ul className="breadcrumbs-block">
        {breadcrumbs.map((breadcrumb: BreadcrumbType) => (
          <li key={breadcrumb.title}>
            <Link className="nav_link" to={breadcrumb.url}>
              {breadcrumb.title}
            </Link>
          </li>
        ))}
      </ul>
      <h1>{title}</h1>
    </div>
  );
};

export default Breadcrumbs;
