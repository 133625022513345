import React from "react";
import {Team} from "../../../core/types/team";
import {TeamsTable} from "../../../core/types/teams-tables";

function TournamentTableRow({team, tableTeam, index}: {
  team: Team | undefined;
  tableTeam: TeamsTable;
  index: number;
}) {
  const goalDifference = tableTeam.gf - tableTeam.ga ?? 0;
  return (
    <tr>
      <td>{index}</td>
      <td className={team?.team_id === 1 || team?.team_id === 2 ? 'myteam' : ''}>
        <div className="logo">
          <img
            src={team?._links.logo.href.includes('logo_placeholder_thumb.png') ? '/images/defaults/team.svg' : team?._links.logo.href}
            alt={team?.full_name}
          />
        </div>
        {team?.full_name}
      </td>
      <td>{tableTeam.games}</td>
      <td>{tableTeam.wins}</td>
      <td>{tableTeam.draws}</td>
      <td>{tableTeam.losses}</td>
      <td>
        {tableTeam.gf}-{tableTeam.ga}{" "}
        <span className="tb_r">[{goalDifference}]</span>
      </td>
      <td>{tableTeam.points}</td>
    </tr>
  );
}

export default TournamentTableRow;
