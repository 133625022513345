import {RepresentativesState, State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {RepresentativeItem, RepresentativeItems} from "../../types/representative";

export const getRepresentativesLoadingStatus = (state: State): boolean => state[NameSpace.Representatives].isRepresentativesLoading;
export const getRepresentativesErrorStatus = (state: State): RepresentativesState['isRepresentativesError'] => state[NameSpace.Representatives].isRepresentativesError;
export const getRepresentatives = (state: State): RepresentativeItems => state[NameSpace.Representatives].representatives;

export const getRepresentativeLoadingStatus = (state: State): boolean => state[NameSpace.Representatives].isRepresentativeLoading;
export const getRepresentativeErrorStatus = (state: State): RepresentativesState['isRepresentativeError'] => state[NameSpace.Representatives].isRepresentativeError;
export const getRepresentative = (state: State): RepresentativeItem | null => state[NameSpace.Representatives].representative;