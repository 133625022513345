import React from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {
  fetchRounds,
  fetchTournament,
  fetchTournamentMatches,
} from '../../core/store/api-actions';
import Loader from '../../components/ordinary/loader/loader';
import 'font-awesome/css/font-awesome.min.css';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {
  getTournament,
  getTournamentLoadingStatus,
} from "../../core/store/tournaments-data/selectors";
import {TournamentsNavigations} from "../../core/constants/navigations";
import TournamentNavigation from "../../components/ordinary/tournament-navigation/tournament-navigation";
import TournamentTable from "../../components/ordinary/tournament-table/tournament-table";
import LinkFull from "../../components/ordinary/link-full/link-full";
import TournamentMatches from "../../components/ordinary/tournament-matches/tournament-matches";
import {
  filterRoundsByHasTable,
  groupMatchesByDay,
  sortGroupedMatchesByDate
} from "../../core/utils/array-prepare-helpers";
import {simpleDateFormat} from "../../core/utils/date-time-helpers";
import {
  getTournamentMatches,
  getTournamentMatchesLoadingStatus
} from "../../core/store/matches-data/selectors";
import {
  getTournamentTeamsLoadingStatus
} from "../../core/store/teams-data/selectors";
import {
  getRounds,
  getRoundsLoadingStatus
} from "../../core/store/rounds-data/selectors";
import {getTeamsTablesGroupLoading} from "../../core/store/teams-tables-data/selectors";
import {tournamentTypes} from "../../core/constants/tournament";

function TournamentInfo() {
  const {id: tournamentId} = useParams();
  const dispatch = useAppDispatch();
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const tournament = useAppSelector(getTournament);
  const matches = useAppSelector(getTournamentMatches);
  const groupedMatches = groupMatchesByDay(matches);
  const sortedMatches = sortGroupedMatchesByDate(groupedMatches);
  const lastGroupedMatch = sortedMatches[sortedMatches.length - 1];
  const rounds = useAppSelector(getRounds);
  const roundsWithTable = filterRoundsByHasTable(rounds)
  const firstRound = roundsWithTable[0];
  const matchesLoading = useAppSelector(getTournamentMatchesLoadingStatus);
  const tournamentLoading = useAppSelector(getTournamentLoadingStatus);
  const teamsLoading = useAppSelector(getTournamentTeamsLoadingStatus);
  const roundsLoading = useAppSelector(getRoundsLoadingStatus);
  const tableTeamsLoading = useAppSelector(getTeamsTablesGroupLoading);
  const tournamentInfoLoading = tournamentLoading || teamsLoading || matchesLoading || roundsLoading || tableTeamsLoading;

  useEffect(() => {
    dispatch(fetchTournament(Number(tournamentId)));
    dispatch(fetchRounds(Number(tournamentId)));
    dispatch(fetchTournamentMatches(Number(tournamentId)));
  }, [tournamentId]);

  useEffect(() => {
    if (tournament) {
      setBreadcrumbs([
        AppRoute.Main,
        {
          title: 'Статистика',
          url: AppRoute.Tournaments.url
        },
        {
          title: `${tournamentTypes[tournament.category]}.${tournament.full_name}`,
          url: '#'
        }
      ]);
    }
  }, [tournament]);

  return (
    <div className="container-center" hidden={tournamentLoading}>
      <main>
        <div hidden={!tournamentInfoLoading}>
          <Loader/>
        </div>
        <div className="stats other" hidden={tournamentInfoLoading}>
          <TournamentTable round={firstRound}/>
          <LinkFull title="Все турнирные таблицы, шахматка" url={`${AppRoute.Tournaments.url}/${tournamentId}/tables`}/>
          {lastGroupedMatch ? (
            <>
              <TournamentMatches tournament={Number(tournamentId)} title={simpleDateFormat(new Date(lastGroupedMatch.date), "d MMMM yyyy 'года,' EEEE:")} matches={lastGroupedMatch.matches} />
              <LinkFull title="Все матчи турнира" url={`${AppRoute.Tournaments.url}/${tournamentId}/calendar`} />
            </>
          ) : null}
        </div>
      </main>
      <aside>
        <TournamentNavigation navigationArray={TournamentsNavigations} id={tournamentId}/>
      </aside>
    </div>
  );
}

export default TournamentInfo;
