import React, {useEffect} from 'react';
import TrophiesList from "../trophies-list/trophies-list";
import Title from "../title/title";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getTrophies,
  getTrophiesLoadingStatus
} from "../../../core/store/trophies-data/selectors";
import {fetchTrophies} from "../../../core/store/api-actions";
import Loader from "../loader/loader";


function TrophiesWidget() {
  const dispatch = useAppDispatch();
  const trophies = useAppSelector(getTrophies);
  const trophiesLoading = useAppSelector(getTrophiesLoadingStatus);

  useEffect(() => {
    dispatch(fetchTrophies());
  }, [dispatch]);

  return (
    <div className="block-trophy" hidden={trophies.length === 0}>
      <div className="container-center" hidden={trophiesLoading}>
        <Title title="Трофеи"/>
        <TrophiesList trophies={trophies}/>
      </div>
      <Loader hidden={!trophiesLoading}/>
    </div>
  );
}

export default TrophiesWidget;