import React from 'react';
import {Trophy} from "../../../core/types/trophy";

function TrophiesItem({trophy}: { trophy: Trophy }) {
  return (
    <li className="trophies__item">
      <img className="trophies__img" src={trophy.photo_url.includes('photo_placeholder_thumb.png') ? '/images/defaults/trophy.svg' : trophy.photo_url} width="230" height="230" alt={trophy.tournament_name}/>
      <div className="trophies__row">
        <p className="trophies__sum">{trophy.count}</p>
        <p className="trophies__name">{trophy.tournament_name}</p>
      </div>
    </li>
  );
}

export default TrophiesItem;
