import React, { useEffect, useState } from "react";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { AppRoute } from "../../core/constants/routes";
import { useAppSelector } from "../../core/hooks";
import {
  getSearchString,
  getSearchedPosts,
  getSearchedPostsError,
  getSearchedPostsLoading,
} from "../../core/store/searches-data/selectors";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import Pagination from "../../components/smart/pagination/pagination";
import { POSTS_PER_PAGE } from "../../core/constants/common";
import Loader from "../../components/ordinary/loader/loader";
import Error from "../error/error";

function SearchPage() {
  const searchedPosts = useAppSelector(getSearchedPosts);
  const searchedPostsError = useAppSelector(getSearchedPostsError);
  const searchedPostsLoading = useAppSelector(getSearchedPostsLoading);
  const searchString = useAppSelector(getSearchString);
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const updateBreadcrumbs = () => {
      const numResults = searchedPosts?.length || 0;
      setBreadcrumbs([
        AppRoute.Main,
        { title: "Поиск по сайту", url: "#" },
        {
          title:
            numResults === 0
              ? "Поиск:"
              : `Поиск: "${searchString}" (${numResults})`,
          url: AppRoute.SearchPage.url,
        },
      ]);
    };

    updateBreadcrumbs();
    return updateBreadcrumbs;
  }, [searchString, searchedPosts]);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= searchedPosts.length / POSTS_PER_PAGE) {
      setCurrentPage(page);
    }
  };

  return (
    <div>
      {searchedPostsLoading ? (
        <Loader />
      ) : searchedPostsError ? (
        <Error />
      ) : searchedPosts ? (
        <div className="container-center">
          <PostsList currentPosts={searchedPosts} />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(searchedPosts.length / POSTS_PER_PAGE)}
            goToPage={handlePageChange}
          />
        </div>
      ) : (
        <p>Нет результатов поиска.</p>
      )}
    </div>
  );
}

export default SearchPage;
