import React from 'react';
import TablesWidget from "../tables-widget/tables-widget";
import StatsWidget from "../../smart/stats-widget/stats-widget";

function InfoWidget() {
  return (
    <div className="stats main" data-url="images/bg-ice.jpg">
      <div className="container-center">
        <div className="tables">
          <TablesWidget/>
          <StatsWidget/>
        </div>
      </div>
    </div>
  );
}

export default InfoWidget;
