import React from 'react';
import {PlayerItem} from "../../../core/types/player";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function PlayersItem({player}: {player: PlayerItem}) {
  return (
    <li>
      <Link to={`${AppRoute.Players.url}/${player.player_id}`}>
        <div className="image">
          <img src={player._links.photo.href} alt={`${player.first_name} ${player.last_name}`}/>
        </div>
        <div className="title">{player.number ? `№${player.number}` : ''} {player.first_name} {player.last_name}</div>
      </Link>
    </li>
  );
}

export default PlayersItem;