import React, {useState} from 'react';
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {Video} from "../../../core/types/media";
import Modal from 'react-modal';
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

Modal.setAppElement('#root');

function VideoPreview({video}: { video: Video | undefined }) {
  const videoDate = video && video.published_dt ? new Date(video.published_dt) : null;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div hidden={video === undefined}>
      <a className="media__preview" onClick={openModal}>
        <div className="media__relative">
          <img className="media__img" src={video?.previewUrl || '/images/defaults/video.svg'} width="930" height="520" alt={video?.title}/>
          <svg className="media__play" width="150" height="150">
            <use xlinkHref="images/sprite.svg#play"/>
          </svg>
        </div>
        <div className="media__content">
          <p className="media__info">Актуальное видео</p>
          <div className="media__container">
            <p className="media__name">{video?.title}</p>
            <p className="media__date">{simpleDateFormat(videoDate, 'dd.MM.yyyy')}</p>
          </div>
        </div>
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Видео"
      >
        <div className="video-modal">
          <a className="video-modal__closer" onClick={closeModal}>
            <i className="video-modal__icon fa fa-times" aria-hidden="true"/>
          </a>
          <HtmlRenderer htmlString={video?.code || ''}/>
        </div>
      </Modal>
    </div>
  );
}

export default VideoPreview;