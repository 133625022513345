import React from "react";
import { Match } from "../../../core/types/match";
import { Player } from "../../../core/types/player";

function TeamCompositions({ match }: { match: Match | null }) {
  const renderPlayers = (players: Player[]) => {
    return players.map((player, index) => (
      <span key={player.id}>
        {player.number}. {player.player.last_name} {player.player.first_name}
        {player.goalkeeper ? "(В)" : ""}
        {player.captain ? "(К)" : ""}
        {index !== players.length - 1 && " | "}
      </span>
    ));
  };

  return (
    <table className="stat-table char" hidden={!(match?.matchPlayers1?.length ?? 0)}>
      <tbody>
        <tr>
          <th colSpan={2}>Составы команд</th>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{ textAlign: "center" }}>
              {match?.team1?.full_name}{match?.team1?.city?.title ? `(${match?.team1?.city?.title})` : ''}:{" "}
              {match?.matchPlayers1 && renderPlayers(match.matchPlayers1)}
              {match?.matchStaff && match?.team1_id === match?.matchStaff[0]?.team_id && (
                  <span>
                    {" "}
                    Тренер - {match?.matchStaff[0]?.staff.last_name}{" "}
                    {match?.matchStaff[0]?.staff.first_name[0]}.
                    {match?.matchStaff[0]?.staff.middle_name[0]}.
                  </span>
                )}
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{ textAlign: "center" }}>
              {match?.team2?.full_name}{match?.team2?.city?.title ? `(${match?.team2?.city?.title})` : ''}:{" "}
              {match?.matchPlayers2 && renderPlayers(match.matchPlayers2)}
              {match?.matchStaff && match?.team2_id === match?.matchStaff[1]?.team_id && (
                  <span>
                    {" "}
                    Тренер - {match?.matchStaff[1]?.staff.last_name}{" "}
                    {match?.matchStaff[0]?.staff.first_name[0]}.
                    {match?.matchStaff[0]?.staff.middle_name[0]}.
                  </span>
                )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default TeamCompositions;
