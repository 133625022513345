import {createSlice} from '@reduxjs/toolkit';
import {
  fetchGroupSeason,
  fetchSeason,
} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {SeasonsState} from "../../types/state";

const initialState: SeasonsState = {
  isSeasonLoading: false,
  isSeasonError: false,
  season: null,
  isGroupSeasonLoading: false,
  isGroupSeasonError: false,
  seasonGroups: [],
};

export const seasonsData = createSlice({
  name: NameSpace.Seasons,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSeason.pending, (state) => {
        state.isSeasonLoading = true;
      })
      .addCase(fetchSeason.fulfilled, (state, action) => {
        state.season = action.payload;
        state.isSeasonLoading = false;
      })
      .addCase(fetchSeason.rejected, (state) => {
        state.isSeasonError = true;
        state.isSeasonLoading = false;
      })
      .addCase(fetchGroupSeason.pending, (state) => {
        state.isGroupSeasonLoading = true;
      })
      .addCase(fetchGroupSeason.fulfilled, (state, action) => {
        const existingSeasonGroups = state.seasonGroups.find(stats => stats.id === action.payload.id);
        if (!existingSeasonGroups) {
          state.seasonGroups.push(action.payload);
        }
        state.isGroupSeasonLoading = false;
      })
      .addCase(fetchGroupSeason.rejected, (state) => {
        state.isGroupSeasonError = true;
        state.isGroupSeasonLoading = false;
      })
  }
});
