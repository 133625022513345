import {WidgetState} from "../../types/state";
import {
  playersTypeOptions,
  teamOptions
} from "../../constants/common";
import {
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import {NameSpace} from "../../constants/api-constants";
import {
  Option,
  RoundOption
} from "../../types/common";

const initialState: WidgetState = {
  widgetTeamFilter: teamOptions[0],
  widgetRoundFilter: null,
  widgetPlayersTypeFilter: playersTypeOptions[0],
};

export const widgetData = createSlice({
  name: NameSpace.Widget,
  initialState,
  reducers: {
    changeWidgetTeamFilter: (
      state,
      action: PayloadAction<{widgetTeamFilter: Option}>
    ) => {
      const {widgetTeamFilter} = action.payload;
      state.widgetTeamFilter = widgetTeamFilter;
    },
    changeWidgetRoundFilter: (
      state,
      action: PayloadAction<{widgetRoundFilter: RoundOption}>
    ) => {
      const {widgetRoundFilter} = action.payload;
      state.widgetRoundFilter = widgetRoundFilter;
    },
    chooseFirstWidgetRoundFilter: (
      state,
      action: PayloadAction<{firstRoundOption: RoundOption}>
    ) => {
      const {firstRoundOption} = action.payload;
      state.widgetRoundFilter = firstRoundOption;
    },
    changeWidgetPlayersTypeFilter: (
      state,
      action: PayloadAction<{widgetPlayersTypeFilter: Option}>
    ) => {
      const {widgetPlayersTypeFilter} = action.payload;
      state.widgetPlayersTypeFilter = widgetPlayersTypeFilter;
    },
    resetWidgetRoundFilter: (
      state
    ) => {
      state.widgetRoundFilter = initialState.widgetRoundFilter;
    },
  }
});

export const {
  changeWidgetTeamFilter,
  changeWidgetRoundFilter,
  chooseFirstWidgetRoundFilter,
  resetWidgetRoundFilter,
  changeWidgetPlayersTypeFilter
} = widgetData.actions