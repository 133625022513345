import React from 'react';
import Loader from "../loader/loader";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";
import Message from "../message/message";
import Sharing from "../../smart/sharing/sharing";

function Page({content, isPageLoading, isPageError}: {
  content: string | undefined,
  isPageLoading: boolean,
  isPageError: boolean
}) {

  return (
    <>
      <div hidden={!isPageLoading}>
        <Loader/>
      </div>
      <div hidden={!isPageError || isPageLoading}>
        <Message message="Информация не загрузилась"/>
      </div>
      <div className="detail" hidden={content === undefined}>
        <div className="easy-text">
          <HtmlRenderer htmlString={content || ''}/>
        </div>
        <Sharing/>
      </div>
    </>
  );
}

export default Page;
