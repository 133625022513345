import React, {
  useEffect,
  useState
} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {PHOTO_GALLERIES_PER_PAGE} from '../../core/constants/common';
import PhotoGalleriesList from '../../components/ordinary/photo-galleries-list/photo-galleries-list';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {
  getPhotoGalleries,
  getPhotoGalleriesErrorStatus,
  getPhotoGalleriesLoadingStatus
} from '../../core/store/photos-data/selectors';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import {fetchPhotoGalleries} from '../../core/store/api-actions';
import Pagination from '../../components/smart/pagination/pagination';
import {AppRoute} from "../../core/constants/routes";
import { sortItemsByPublicationDate } from '../../core/utils/array-prepare-helpers'

function PhotoGalleries() {
  const dispatch = useAppDispatch();
  const photoGalleries = useAppSelector(getPhotoGalleries);
  const sortedPhotoGalleries = sortItemsByPublicationDate(photoGalleries)
  const isPhotoGalleriesLoading = useAppSelector(getPhotoGalleriesLoadingStatus);
  const isPhotoGalleriesError = useAppSelector(getPhotoGalleriesErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(photoGalleries.length / PHOTO_GALLERIES_PER_PAGE);
  const start = (currentPage - 1) * PHOTO_GALLERIES_PER_PAGE;
  const end = start + PHOTO_GALLERIES_PER_PAGE;
  const currentPhotoGalleries = sortedPhotoGalleries.slice(start, end);

  useEffect(() => {
    dispatch(fetchPhotoGalleries());
  }, [dispatch]);

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Media, AppRoute.PhotoGalleries]);
  }, [setBreadcrumbs]);

  return (
    <>
      <div hidden={!isPhotoGalleriesLoading}>
        <Loader/>
      </div>
      <div hidden={!isPhotoGalleriesError}>
        <Error/>
      </div>
      <div className="container-center" hidden={isPhotoGalleriesLoading}>
        <main className="full">
          <PhotoGalleriesList currentPhotoGalleries={currentPhotoGalleries}/>
          <Pagination currentPage={currentPage} totalPages={totalPages} goToPage={goToPage}/>
        </main>
      </div>
    </>
  );
}

export default PhotoGalleries;
