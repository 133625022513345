import React from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {
  fetchTournament,
  fetchTournamentTeams
} from '../../core/store/api-actions';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import 'font-awesome/css/font-awesome.min.css';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {
  getTournament,
  getTournamentErrorStatus,
  getTournamentLoadingStatus,
} from "../../core/store/tournaments-data/selectors";
import {TournamentsNavigations} from "../../core/constants/navigations";
import TournamentNavigation from "../../components/ordinary/tournament-navigation/tournament-navigation";
import TournamentTeamTable from "../../components/ordinary/tournament-team-table/tournament-team-table";
import {getTournamentTeams} from "../../core/store/teams-data/selectors";
import {tournamentTypes} from "../../core/constants/tournament";

function TournamentTeams() {
  const dispatch = useAppDispatch();
  const {id: tournamentId} = useParams();
  const tournament = useAppSelector(getTournament);
  const isTournamentError = useAppSelector(getTournamentErrorStatus);
  const isTournamentLoading = useAppSelector(getTournamentLoadingStatus);
  const teams = useAppSelector(getTournamentTeams);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchTournament(Number(tournamentId)));
    dispatch(fetchTournamentTeams(Number(tournamentId)))
  }, [tournamentId]);

  useEffect(() => {
    if (tournament) {
      setBreadcrumbs([
        AppRoute.Main,
        {
          title: 'Статистика',
          url: AppRoute.Tournaments.url
        },
        {
          title: `${tournamentTypes[tournament.category]}.${tournament.full_name}`,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/info`
        },
        {
          title: 'Статистика по командам',
          url: '#'
        }]);
    }
  }, [tournament]);

  return (
    <>
      <div hidden={!isTournamentLoading}>
        <Loader/>
      </div>
      <div hidden={!isTournamentError}>
        <Error/>
      </div>
      <div className="container-center" hidden={isTournamentLoading}>
        <main>
          <div className="stats other">
            <TournamentTeamTable teams={teams}/>
          </div>
        </main>
        <aside>
          <TournamentNavigation navigationArray={TournamentsNavigations} id={tournamentId}/>
        </aside>
      </div>
    </>
  );
}

export default TournamentTeams;
