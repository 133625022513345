import React from 'react';
import {Videos} from '../../../core/types/media';
import VideoItem from '../video-item/video-item';
import Message from "../message/message";

function VideosList({currentVideos} : {currentVideos: Videos}) {
  return (
    <>
      <div hidden={currentVideos.length === 0}>
        <ul className="photo-list-other">
          {currentVideos.map((video) => <VideoItem key={video.video_id} video={video}/>)}
        </ul>
      </div>
      <div hidden={currentVideos.length > 0}>
        <Message message="Извините, но у нас пока нет видео. Пожалуйста, загляните позже"/>
      </div>
    </>
  );
}

export default VideosList;