import {createSlice} from '@reduxjs/toolkit';
import {
  fetchGroupTeam,
  fetchGroupTournamentTeams,
  fetchTeam,
  fetchTeams,
  fetchTeamStats,
  fetchTournamentTeams
} from '../api-actions';
import {TeamsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: TeamsState = {
  isTeamsLoading: false,
  isTeamsError: false,
  teams: [],
  isTeamLoading: false,
  isTeamError: false,
  team: null,
  isTeamsStatsLoading: false,
  isTeamsStatsError: false,
  teamsStats: [],
  isGroupTeamLoading: false,
  isGroupTeamError: false,
  groupTeam: [],
  isTournamentTeamsLoading: false,
  isTournamentTeamsError: false,
  tournamentTeams: [],
  isTournamentTeamsGroupsLoading: false,
  isTournamentTeamsGroupsError: false,
  tournamentTeamsGroups: [],
};

export const teamsData = createSlice({
  name: NameSpace.Teams,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeams.pending, (state) => {
        state.isTeamsLoading = true;
      })
      .addCase(fetchTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
        state.isTeamsLoading = false;
      })
      .addCase(fetchTeams.rejected, (state) => {
        state.isTeamsError = true;
        state.isTeamsLoading = false;
      })
      .addCase(fetchTeam.pending, (state) => {
        state.isTeamLoading = true;
      })
      .addCase(fetchTeam.fulfilled, (state, action) => {
        state.team = action.payload;
        state.isTeamLoading = false;
      })
      .addCase(fetchTeam.rejected, (state) => {
        state.isTeamError = true;
        state.isTeamLoading = false;
      })
      .addCase(fetchTeamStats.pending, (state) => {
        state.isTeamsStatsLoading = true;
      })
      .addCase(fetchTeamStats.fulfilled, (state, action) => {
        const existingTeams = state.teamsStats.find(stats => stats.teamId === action.payload.teamId);
        if (!existingTeams) {
          state.teamsStats.push(action.payload);
        }
        state.isTeamsStatsLoading = false;
      })
      .addCase(fetchTeamStats.rejected, (state) => {
        state.isTeamsStatsError = true;
        state.isTeamsStatsLoading = false;
      })
      .addCase(fetchGroupTeam.pending, (state) => {
        state.isGroupTeamLoading = true;
      })
      .addCase(fetchGroupTeam.fulfilled, (state, action) => {
        const existingTeams = state.groupTeam.find(stats => stats.id === action.payload.id);
        if (!existingTeams) {
          state.groupTeam.push(action.payload);
        }
        state.isGroupTeamLoading = false;
      })
      .addCase(fetchGroupTeam.rejected, (state) => {
        state.isGroupTeamError = true;
        state.isGroupTeamLoading = false;
      })
      .addCase(fetchTournamentTeams.pending, (state) => {
        state.isTournamentTeamsLoading = true;
      })
      .addCase(fetchTournamentTeams.fulfilled, (state, action) => {
        state.tournamentTeams = action.payload;
        state.isTournamentTeamsLoading = false;
      })
      .addCase(fetchTournamentTeams.rejected, (state) => {
        state.isTournamentTeamsError = true;
        state.isTournamentTeamsLoading = false;
      })
      .addCase(fetchGroupTournamentTeams.pending, (state) => {
        state.isTournamentTeamsGroupsLoading = true;
      })
      .addCase(fetchGroupTournamentTeams.fulfilled, (state, action) => {
        const existingTournamentTeamsGroup = state.tournamentTeamsGroups.find(stats => stats.id === action.payload.id);
        if (!existingTournamentTeamsGroup) {
          state.tournamentTeamsGroups.push(action.payload);
        }
        state.isTournamentTeamsGroupsLoading = false;
      })
      .addCase(fetchGroupTournamentTeams.rejected, (state) => {
        state.isTournamentTeamsGroupsError = true;
        state.isTournamentTeamsGroupsLoading = false;
      })
  }
});
