export enum DropdownTypes {
  Team = 'team',
  Round = 'round',
  Season = "season",
}

export enum DropdownPlaceholders {
  Team = 'Команда',
  Round = 'Этап соревнований',
  Undefined = 'Не работает',
}

export enum ItemsForFilter {
  TableTeams = 'tableTeams',
  Players = 'players',
}
