import Loader from "../loader/loader";
import React from "react";
import TeamsItem from "../teams-item/teams-item";
import {Teams} from "../../../core/types/team";
import Message from "../message/message";

function TeamsList({teams, isTeamsLoading, isTeamsError}: {teams: Teams, isTeamsLoading: boolean, isTeamsError: boolean}) {
  return (
    <>
      <div hidden={!isTeamsLoading}>
        <Loader />
      </div>
      <div hidden={!isTeamsError} className="easy-text">
        <Message message="Произошла ошибка при загрузке команд."/>
      </div>
      <div hidden={teams.length === 0}>
        {teams.map((team) =>
          <TeamsItem key={team.team_id} team={team} />
        )}
      </div>
    </>
  );
}

export default TeamsList;