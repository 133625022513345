import React from 'react';
import SidebarNavigation from "../sidebar-navigation/sidebar-navigation";
import {useAppSelector} from "../../../core/hooks";
import {getPosts} from "../../../core/store/posts-data/selectors";
import {
  filterPostsByTag,
  sortItemsByPublicationDate,
} from "../../../core/utils/array-prepare-helpers";
import SidebarInformation from "../sidebar-information/sidebar-information";
import {MassSkatingNavigations} from "../../../core/constants/navigations";

function MassSkatingSidebar() {
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByTag(posts, 'Массовые катания');
  const sortedPosts = sortItemsByPublicationDate(filteredPosts);

  return (
    <aside>
      <SidebarNavigation navigationArray={MassSkatingNavigations}/>
      <SidebarInformation post={sortedPosts[0]}/>
    </aside>
  );
}

export default MassSkatingSidebar;
