import React from 'react';
import {Link} from "react-router-dom";
import {store} from "../../../core/store";
import {fetchSocials} from "../../../core/store/api-actions";
import {useAppSelector} from "../../../core/hooks";
import {getSocials} from "../../../core/store/socials-data/selectors";
import {filterSocialNetworks, sortByRank} from "../../../core/utils/array-prepare-helpers";

store.dispatch(fetchSocials());

function SocialsLinks() {
  const socials = useAppSelector(getSocials)
  const filteredSocials = filterSocialNetworks(socials, 'settings');
  const sortedSocials = sortByRank(filteredSocials)
  return (
    <>
      {sortedSocials.map((social) =>
        <Link to={social.url} style={{display: "inline-block"}} key={social.social_id} target="_blank" rel="noreferrer">
          <i className={`fa fa-${social.social === 'vk' ? 'vk' : social.social === 'yt' ? 'youtube' : social.social === 'tg' ? 'telegram' : ''}`} aria-hidden="true" />
        </Link>)}
    </>
  );
}

export default SocialsLinks;
