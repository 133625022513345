import React from "react";

function StatsWidgetHead({newLetter}:{newLetter:string}) {
  return (
    <thead>
    <tr>
      <th>Команда</th>
      <th>И</th>
      <th>{newLetter}</th>
      <th>П</th>
      <th>БК</th>
      <th>СК</th>
      <th>КК</th>
    </tr>
    </thead>
  );
}

export default StatsWidgetHead;
