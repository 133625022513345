import React from "react";

function PlayerButtons({
  selectedView,
  onSwitchView,
}: {
  selectedView: "bio" | "statistics" | "career" | "matches";
  onSwitchView: (view: "bio" | "statistics" | "career" | "matches") => void;
}) {
  return (
    <div className="pbutton">
      <section className="pbutton__container">
        <ul
          className="pbutton__navigation js-tabs"
          data-cont-id="tabs-content-player"
        >
          <li
            className={`pbutton__item ${
              selectedView === "bio" ? "js-active tabs__item--active" : ""
            }`}
            data-tab-id="tab-player-biography"
          >
            <a className="pbutton__link" onClick={() => onSwitchView("bio")}>
              Биография
            </a>
          </li>
          <li
            className={`pbutton__item ${
              selectedView === "statistics"
                ? "js-active tabs__item--active"
                : ""
            }`}
            data-tab-id="tab-player-statistics"
          >
            <a
              className="pbutton__link"
              onClick={() => onSwitchView("statistics")}
            >
              Статистика
            </a>
          </li>
          <li
            className={`pbutton__item ${
              selectedView === "career" ? "js-active tabs__item--active" : ""
            }`}
            data-tab-id="tab-player-career"
          >
            <a className="pbutton__link" onClick={() => onSwitchView("career")}>
              Карьера
            </a>
          </li>
          <li
            className={`pbutton__item ${
              selectedView === "matches" ? "js-active tabs__item--active" : ""
            }`}
            data-tab-id="tab-player-matches"
          >
            <a
              className="pbutton__link"
              onClick={() => onSwitchView("matches")}
            >
              Матчи
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
}

export default PlayerButtons;
