import { createSlice } from "@reduxjs/toolkit";
import {
  fetchClubs,
  fetchClubAttributesPage,
  fetchInternationalMatchesPage,
  fetchCountryCupsPage,
  fetchPastPresentFuturePage,
  fetchCountryTournamentsPage,
  fetchAchievementsPage,
  fetchSymbolismAnthemPage,
  fetchProgramsPage,
  fetchGoalkeeperSchoolPage,
  fetchPressPage,
} from "../api-actions";
import { ClubsState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";

const initialState: ClubsState = {
  isClubsLoading: false,
  isClubsError: false,
  clubs: [],
  isClubAttributesPageLoading: false,
  isClubAttributesPageError: false,
  clubAttributesPage: null,
  isInternationalMatchesPageLoading: false,
  isInternationalMatchesPageError: false,
  internationalMatchesPage: null,
  isCountryCupsPageLoading: false,
  isCountryCupsPageError: false,
  countryCupsPage: null,
  isPastPresentFuturePageLoading: false,
  isPastPresentFuturePageError: false,
  pastPresentFuturePage: null,
  isCountryTournamentsPageLoading: false,
  isCountryTournamentsPageError: false,
  countryTournamentsPage: null,
  isAchievementsPageLoading: false,
  isAchievementsPageError: false,
  achievementsPage: null,
  isSymbolismAnthemPageLoading: false,
  isSymbolismAnthemPageError: false,
  symbolismAnthemPage: null,
  isProgramsPageLoading: false,
  isProgramsPageError: false,
  programsPage: null,
  isGoalkeeperSchoolPageLoading: false,
  isGoalkeeperSchoolPageError: false,
  goalkeeperSchoolPage: null,
  isPressPageLoading: false,
  isPressPageError: false,
  pressPage: null,
};

export const clubsData = createSlice({
  name: NameSpace.Clubs,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchClubs.pending, (state) => {
        state.isClubsLoading = true;
      })
      .addCase(fetchClubs.fulfilled, (state, action) => {
        state.clubs = action.payload;
        state.isClubsLoading = false;
      })
      .addCase(fetchClubs.rejected, (state) => {
        state.isClubsError = true;
        state.isClubsLoading = false;
      })
      .addCase(fetchClubAttributesPage.pending, (state) => {
        state.isClubAttributesPageLoading = true;
      })
      .addCase(fetchClubAttributesPage.fulfilled, (state, action) => {
        state.clubAttributesPage = action.payload;
        state.isClubAttributesPageLoading = false;
      })
      .addCase(fetchClubAttributesPage.rejected, (state) => {
        state.isClubAttributesPageError = true;
        state.isClubAttributesPageLoading = false;
      })
      .addCase(fetchInternationalMatchesPage.pending, (state) => {
        state.isInternationalMatchesPageLoading = true;
      })
      .addCase(fetchInternationalMatchesPage.fulfilled, (state, action) => {
        state.internationalMatchesPage = action.payload;
        state.isInternationalMatchesPageLoading = false;
      })
      .addCase(fetchInternationalMatchesPage.rejected, (state) => {
        state.isInternationalMatchesPageError = true;
        state.isInternationalMatchesPageLoading = false;
      })
      .addCase(fetchCountryCupsPage.pending, (state) => {
        state.isCountryCupsPageLoading = true;
      })
      .addCase(fetchCountryCupsPage.fulfilled, (state, action) => {
        state.countryCupsPage = action.payload;
        state.isCountryCupsPageLoading = false;
      })
      .addCase(fetchCountryCupsPage.rejected, (state) => {
        state.isCountryCupsPageError = true;
        state.isCountryCupsPageLoading = false;
      })
      .addCase(fetchPastPresentFuturePage.pending, (state) => {
        state.isPastPresentFuturePageLoading = true;
      })
      .addCase(fetchPastPresentFuturePage.fulfilled, (state, action) => {
        state.pastPresentFuturePage = action.payload;
        state.isPastPresentFuturePageLoading = false;
      })
      .addCase(fetchPastPresentFuturePage.rejected, (state) => {
        state.isPastPresentFuturePageError = true;
        state.isPastPresentFuturePageLoading = false;
      })
      .addCase(fetchCountryTournamentsPage.pending, (state) => {
        state.isCountryTournamentsPageLoading = true;
      })
      .addCase(fetchCountryTournamentsPage.fulfilled, (state, action) => {
        state.countryTournamentsPage = action.payload;
        state.isCountryTournamentsPageLoading = false;
      })
      .addCase(fetchCountryTournamentsPage.rejected, (state) => {
        state.isCountryTournamentsPageError = true;
        state.isCountryTournamentsPageLoading = false;
      })
      .addCase(fetchAchievementsPage.pending, (state) => {
        state.isAchievementsPageLoading = true;
      })
      .addCase(fetchAchievementsPage.fulfilled, (state, action) => {
        state.achievementsPage = action.payload;
        state.isAchievementsPageLoading = false;
      })
      .addCase(fetchAchievementsPage.rejected, (state) => {
        state.isAchievementsPageError = true;
        state.isAchievementsPageLoading = false;
      })
      .addCase(fetchSymbolismAnthemPage.pending, (state) => {
        state.isSymbolismAnthemPageLoading = true;
      })
      .addCase(fetchSymbolismAnthemPage.fulfilled, (state, action) => {
        state.symbolismAnthemPage = action.payload;
        state.isSymbolismAnthemPageLoading = false;
      })
      .addCase(fetchSymbolismAnthemPage.rejected, (state) => {
        state.isSymbolismAnthemPageError = true;
        state.isSymbolismAnthemPageLoading = false;
      })
      .addCase(fetchProgramsPage.pending, (state) => {
        state.isProgramsPageLoading = true;
      })
      .addCase(fetchProgramsPage.fulfilled, (state, action) => {
        state.programsPage = action.payload;
        state.isProgramsPageLoading = false;
      })
      .addCase(fetchProgramsPage.rejected, (state) => {
        state.isProgramsPageError = true;
        state.isProgramsPageLoading = false;
      })
      .addCase(fetchGoalkeeperSchoolPage.pending, (state) => {
        state.isGoalkeeperSchoolPageLoading = true;
      })
      .addCase(fetchGoalkeeperSchoolPage.fulfilled, (state, action) => {
        state.goalkeeperSchoolPage = action.payload;
        state.isGoalkeeperSchoolPageLoading = false;
      })
      .addCase(fetchGoalkeeperSchoolPage.rejected, (state) => {
        state.isGoalkeeperSchoolPageError = true;
        state.isGoalkeeperSchoolPageLoading = false;
      })
      .addCase(fetchPressPage.pending, (state) => {
        state.isPressPageLoading = true;
      })
      .addCase(fetchPressPage.fulfilled, (state, action) => {
        state.pressPage = action.payload;
        state.isPressPageLoading = false;
      })
      .addCase(fetchPressPage.rejected, (state) => {
        state.isPressPageError = true;
        state.isPressPageLoading = false;
      });
  },
});
