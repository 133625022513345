import React, { useEffect } from "react";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import { AppRoute } from "../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { getPosts } from "../../core/store/posts-data/selectors";
import { filterPostsByTag } from "../../core/utils/array-prepare-helpers";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import {
  fetchGoalkeeperSchoolPage,
  fetchPosts,
} from "../../core/store/api-actions";
import {
  getGoalkeeperSchoolPage,
  getGoalkeeperSchoolPageErrorStatus,
  getGoalkeeperSchoolPageLoadingStatus,
} from "../../core/store/clubs-data/selectors";
import Page from "../../components/ordinary/page/page";
import { store } from "../../core/store";
import GoalkeeperSchoolSidebar from "../../components/ordinary/goalkeeper-school-sidebar/goalkeeper-school-sidebar";

store.dispatch(fetchGoalkeeperSchoolPage());

function GoalkeeperSchool() {
  const dispatch = useAppDispatch();
  const goalkeeperSchoolPage = useAppSelector(getGoalkeeperSchoolPage);
  const isGoalkeeperSchoolPageLoading = useAppSelector(
    getGoalkeeperSchoolPageLoadingStatus
  );
  const isGoalkeeperSchoolPageError = useAppSelector(
    getGoalkeeperSchoolPageErrorStatus
  );
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByTag(
    posts,
    AppRoute.GoalkeeperSchool.title
  );
  const currentPosts = filteredPosts;

  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchPosts());
    setBreadcrumbs([
      AppRoute.Main,
      AppRoute.AboutClub,
      AppRoute.GoalkeeperSchool,
    ]);
  }, [dispatch]);

  return (
    <div className="container-center">
      <main>
        <Page
          content={goalkeeperSchoolPage?.text}
          isPageLoading={isGoalkeeperSchoolPageLoading}
          isPageError={isGoalkeeperSchoolPageError}
        />
        <div className="block-press other">
          <div className="container-center">
            <PostsList
              currentPosts={currentPosts}
              tag={AppRoute.GoalkeeperSchool.title}
            />
          </div>
        </div>
      </main>
      <aside>
        <GoalkeeperSchoolSidebar />
      </aside>
    </div>
  );
}

export default GoalkeeperSchool;
