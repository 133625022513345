import React from "react";
import { Link, useParams } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { RowMatches } from "../../../core/types/chess";
import { Team } from "../../../core/types/team";

function TournamentChessRow({
  matches,
  rowIndex,
  positions,
}: {
  matches: RowMatches;
  rowIndex: string;
  positions: Team[];
}) {
  const { id: tournamentId } = useParams();
  const matchesLength = matches ? Object.keys(matches).length + 1 : 1;
  const rowIndexNumber = parseInt(rowIndex, 10);
  const team: Team | undefined = positions[rowIndexNumber];

  const displayScore = (matchGF: number | null, matchGA: number | null) => {
    if (matchGF === null && matchGA === null) {
      return "";
    } else if (matchGF === null) {
      return `- : ${matchGA || 0}`;
    } else if (matchGA === null) {
      return `${matchGF || 0} : -`;
    } else {
      return `${matchGF} : ${matchGA}`;
    }
  };

  return (
    <tr>
      <td width="20">{Number(rowIndex) + 1}</td>
      <td>
        <div className="logo">
          <img src={team?._links.logo.href} alt="" />
        </div>
        {tournamentId && team && (
          <Link
            to={`${AppRoute.Tournaments.url}/${tournamentId}/teams/${team.team_id}`}
            className={
              team?.full_name === "Енисей" || team?.full_name === "Енисей-2"
                ? "red"
                : ""
            }
          >
            {team.full_name}
          </Link>
        )}
      </td>
      {Array.from({ length: matchesLength }).map((_, index) => {
        if (!matches || !matches?.[index.toString()]) {
          return (
            <td key={index} className="sq_sh">
              &nbsp;
            </td>
          );
        } else {
          const roundMatches = matches[index];
          return (
            <td key={index}>
              {roundMatches.map((match) => (
                <Link
                  key={match.match_id}
                  to={`${AppRoute.Tournaments.url}/${match.tournament_id}${AppRoute.Calendar.url}/${match.match_id}`}
                  className="tb_r"
                  style={
                    match.gf !== null && match.ga !== null
                      ? match.gf === match.ga
                        ? { color: "green" }
                        : match.gf > match.ga
                        ? { color: "red" }
                        : { color: "#1A4A77" }
                      : { color: "" }
                  }
                >
                  {displayScore(match.gf, match.ga)}<br/>
                </Link>
              ))}
            </td>
          );
        }
      })}
    </tr>
  );
}

export default TournamentChessRow;
