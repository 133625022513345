import React, { useEffect } from "react";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { Link } from "react-router-dom";
import { AppRoute } from "../../core/constants/routes";
import Page from "../../components/ordinary/page/page";

function Media() {
  const { setBreadcrumbs } = useBreadcrumbsContext();
  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Media]);
  }, [setBreadcrumbs]);

  return (
    <div className="container-center">
      <main className="full">
        <Page
          content={`
          <p>В этом разделе Вы можете ознакомится с медиа-материалами нашего сайта. Видеоотчеты о матчах нашей команды, мероприятиях с участием игроков "Енисея", "Енисея-2" и воспитанников СШОР, другие видеоматериалы, фотогалереи - все это Вы найдете на этой страничке.</p>
          <p>Кроме того, на страничке находится ссылка на он-лайн трансляции матчей со стадиона "Енисей" - посредством веб-камер, установленных на трибунах.</p>
          <p>Рады представить Вашему вниманию следующие подразделы:</p>
        `}
          isPageLoading={false}
          isPageError={false}
        />
        <div className="detail">
          <div className="easy-text">
            <ul>
              <li>
                <Link to={AppRoute.PhotoGalleries.url}>Фотогалерея</Link>
              </li>
              <li>
                <Link to={AppRoute.Videos.url}>Галерея видео материалов</Link>
              </li>
              <li>
                <Link to={AppRoute.Translations.url}>
                  Веб-камеры на стадионе &quot;Енисей&quot;
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Media;
