import {
  createSlice
} from '@reduxjs/toolkit';
import {
  fetchGroupPlayer,
  fetchPlayer,
  fetchPosition,
  fetchMatchPlayers,
  fetchAllPlayers,
  fetchPlayerMatches
} from '../api-actions';
import {PlayersState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: PlayersState = {
  isPlayerLoading: false,
  isPlayerError: false,
  player: null,
  isPositionLoading: false,
  isPositionError: false,
  position: null,
  isGroupPlayerLoading: false,
  isGroupPlayerError: false,
  groupPlayer: [],
  isMatchPlayersLoading: false,
  isMatchPlayersError: false,
  matchPlayers: [],
  isAllPlayersLoading: false,
  isAllPlayersError: false,
  allPlayers: [],
  widgetPlayers: [],
  isPlayerMatchesLoading: false,
  isPlayerMatchesError: false,
  playerMatches: [],
};

export const playersData = createSlice({
  name: NameSpace.Players,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllPlayers.pending, (state) => {
        state.isAllPlayersLoading = true;
      })
      .addCase(fetchAllPlayers.fulfilled, (state, action) => {
        state.allPlayers = action.payload;
        state.isAllPlayersLoading = false;
      })
      .addCase(fetchAllPlayers.rejected, (state) => {
        state.isAllPlayersError = true;
        state.isAllPlayersLoading = false;
      })
      .addCase(fetchPlayer.pending, (state) => {
        state.isPlayerLoading = true;
      })
      .addCase(fetchPlayer.fulfilled, (state, action) => {
        state.player = action.payload;
        state.isPlayerLoading = false;
      })
      .addCase(fetchPlayer.rejected, (state) => {
        state.isPlayerError = true;
        state.isPlayerLoading = false;
      })
      .addCase(fetchPosition.pending, (state) => {
        state.isPositionLoading = true;
      })
      .addCase(fetchPosition.fulfilled, (state, action) => {
        state.position = action.payload;
        state.isPositionLoading = false;
      })
      .addCase(fetchPosition.rejected, (state) => {
        state.isPositionError = true;
        state.isPositionLoading= false;
      })
      .addCase(fetchGroupPlayer.pending, (state) => {
        state.isGroupPlayerLoading = true;
      })
      .addCase(fetchGroupPlayer.fulfilled, (state, action) => {
        const existingPlayers = state.groupPlayer.find(stats => stats.playerId === action.payload.playerId);
        if (!existingPlayers) {
          state.groupPlayer.push(action.payload);
        }
        state.isGroupPlayerLoading = false;
      })
      .addCase(fetchGroupPlayer.rejected, (state) => {
        state.isGroupPlayerError = true;
        state.isGroupPlayerLoading = false;
      })
      .addCase(fetchMatchPlayers.pending, (state) => {
        state.isMatchPlayersLoading = true;
      })
      .addCase(fetchMatchPlayers.fulfilled, (state, action) => {
        state.matchPlayers = action.payload;
        state.isMatchPlayersLoading = false;
      })
      .addCase(fetchMatchPlayers.rejected, (state) => {
        state.isMatchPlayersError = true;
        state.isMatchPlayersLoading = false;
      })
      .addCase(fetchPlayerMatches.pending, (state) => {
        state.isPlayerMatchesLoading = true;
      })
      .addCase(fetchPlayerMatches.fulfilled, (state, action) => {
        state.playerMatches = action.payload;
        state.isPlayerMatchesLoading = false;
      })
      .addCase(fetchPlayerMatches.rejected, (state) => {
        state.isPlayerMatchesError = true;
        state.isPlayerMatchesLoading = false;
      });
  }
});
