import React from 'react';
import {PhotoGalleries} from '../../../core/types/media';
import PhotoGalleryItem from '../photo-gallery-item/photo-gallery-item';
import Message from "../message/message";

function PhotoGalleriesList({currentPhotoGalleries} : {currentPhotoGalleries: PhotoGalleries}) {
  return (
    <>
      <div hidden={currentPhotoGalleries.length === 0}>
        <ul className="photo-list-other">
          {currentPhotoGalleries.map((photoGallery) =>
            <PhotoGalleryItem
              key={photoGallery.album_id}
              photoGallery={photoGallery}/>)
          }
        </ul>
      </div>
      <div hidden={currentPhotoGalleries.length > 0}>
        <Message message="Извините, но у нас пока нет фотоальбомов. Пожалуйста, загляните позже"/>
      </div>
    </>
  );
}

export default PhotoGalleriesList;
