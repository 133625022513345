import React from "react";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchRounds, fetchTournament } from "../../core/store/api-actions";
import Loader from "../../components/ordinary/loader/loader";
import "font-awesome/css/font-awesome.min.css";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { AppRoute } from "../../core/constants/routes";
import {
  getTournament,
  getTournamentLoadingStatus,
} from "../../core/store/tournaments-data/selectors";
import { TournamentsNavigations } from "../../core/constants/navigations";
import TournamentNavigation from "../../components/ordinary/tournament-navigation/tournament-navigation";
import TournamentTable from "../../components/ordinary/tournament-table/tournament-table";
import LinkFull from "../../components/ordinary/link-full/link-full";
import {
  getRounds,
  getRoundsLoadingStatus,
} from "../../core/store/rounds-data/selectors";
import { getTournamentTeamsLoadingStatus } from "../../core/store/teams-data/selectors";
import { getTeamsTablesGroupLoading } from "../../core/store/teams-tables-data/selectors";
import { tournamentTypes } from "../../core/constants/tournament";
import { filterRoundsByHasTable } from "../../core/utils/array-prepare-helpers";

function TournamentTables() {
  const dispatch = useAppDispatch();
  const { id: tournamentId } = useParams();
  const tournament = useAppSelector(getTournament);
  const rounds = useAppSelector(getRounds);
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const tournamentLoading = useAppSelector(getTournamentLoadingStatus);
  const teamsLoading = useAppSelector(getTournamentTeamsLoadingStatus);
  const roundsLoading = useAppSelector(getRoundsLoadingStatus);
  const tableTeamsLoading = useAppSelector(getTeamsTablesGroupLoading);
  const tournamentTablesLoading =
    tournamentLoading || roundsLoading || teamsLoading || tableTeamsLoading;

  const roundsWithTable = filterRoundsByHasTable(rounds);
  useEffect(() => {
    dispatch(fetchTournament(Number(tournamentId)));
    dispatch(fetchRounds(Number(tournamentId)));
  }, [tournamentId, dispatch]);

  useEffect(() => {
    if (tournament) {
      setBreadcrumbs([
        AppRoute.Main,
        {
          title: "Статистика",
          url: AppRoute.Tournaments.url,
        },
        {
          title: `${tournamentTypes[tournament.category]}.${
            tournament.full_name
          }`,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/info`,
        },
        {
          title: AppRoute.TournamentTables.title,
          url: "#",
        },
      ]);
    }
  }, [tournament]);

  return (
    <>
      <div className="container-center" hidden={tournamentLoading}>
        <main>
          <div hidden={!tournamentTablesLoading}>
            <Loader />
          </div>
          <div className="stats other" hidden={tournamentTablesLoading}>
            {roundsWithTable.map(
              (round) =>
                round.has_table && (
                  <TournamentTable round={round} key={round.round_id} />
                )
            )}
            {tournamentId && (
              <LinkFull
                title="Открыть шахматку"
                url={`${AppRoute.Tournaments.url}/${tournamentId}/chess`}
              />
            )}
          </div>
        </main>
        <aside>
          <TournamentNavigation
            navigationArray={TournamentsNavigations}
            id={tournamentId}
          />
        </aside>
      </div>
    </>
  );
}

export default TournamentTables;
