import React, {useEffect} from 'react';
import {Tournament} from "../../../core/types/tournament";
import {fetchGroupSeason} from "../../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";
import {
  getSeasonGroups
} from "../../../core/store/seasons-data/selectors";
import {findById} from "../../../core/utils/array-prepare-helpers";

function TournamentsItem({tournament}: { tournament: Tournament }) {
  const dispatch = useAppDispatch();
  const seasonGroups = useAppSelector(getSeasonGroups);
  const seasonGroup = findById(seasonGroups, tournament.season_id);

  useEffect(() => {
    dispatch(fetchGroupSeason(Number(tournament.season_id)));
  }, [tournament.season_id]);

  return (
    <li>
      <Link to={`${AppRoute.Tournaments.url}/${tournament.tournament_id}/info`}>{tournament.full_name} </Link> <span hidden={seasonGroup === undefined} className="gray_sm">{seasonGroup?.season?.title}  г.</span>
      <div className="stat-desc">
        <Link to={`${AppRoute.Tournaments.url}/${tournament.tournament_id}/tables`}>Турнирные таблицы</Link>/ <Link
        to={`${AppRoute.Tournaments.url}/${tournament.tournament_id}/calendar`}>Календарь и результаты</Link>/ <Link
        to={`${AppRoute.Tournaments.url}/${tournament.tournament_id}/players`}>Игроки Енисея</Link>/ <Link
        to={`${AppRoute.Tournaments.url}/${tournament.tournament_id}/statistics`}>Статистика турнира</Link>
      </div>
    </li>
  );
}

export default TournamentsItem;
