import React from 'react';
import {BounceLoader} from "react-spinners";

function Loader({hidden}: { hidden?: boolean }) {
  return (
    <div className={`loader ${hidden ? 'hidden' : ''}`}>
      <BounceLoader color="#992A21"/>
    </div>
  );
}

export default Loader;