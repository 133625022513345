import {createSlice} from '@reduxjs/toolkit';
import {fetchRounds} from '../api-actions';
import {RoundsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: RoundsState = {
  roundsLoading: false,
  roundsError: false,
  rounds: [],
};

export const roundsData = createSlice({
  name: NameSpace.Rounds,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRounds.pending, (state) => {
        state.roundsLoading = true;
      })
      .addCase(fetchRounds.fulfilled, (state, action) => {
        state.rounds = action.payload;
        state.roundsLoading = false;
      })
      .addCase(fetchRounds.rejected, (state) => {
        state.roundsError = true;
        state.roundsLoading = false;
      })
  }
});
