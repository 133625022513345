import React from "react";
import { Link } from "react-router-dom";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";
import { Post } from "../../../core/types/post";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { AppRoute } from "../../../core/constants/routes";

function PostsSliderItem({ post }: { post: Post }) {
  const postDate = new Date(post.published_dt);

  return (
    <div className="slides__item">
      <Link
        className="slides__link"
        to={`${AppRoute.Posts.url}/${post.post_id}`}
      >
        <div className="slides__relative">
          <img
            className="slides__img"
            src={post._links.image870x490.href || "/images/defaults/news.svg"}
            width="690"
            height="386"
            alt={post.title}
          />
        </div>
        <div className="slides__content">
          <div className="slides__title">{post.title}</div>
          <div className="slides__text">
            <HtmlRenderer htmlString={post.lead} />
          </div>
          <div className="slides__date">
            {simpleDateFormat(postDate, "dd.MM.yyyy")}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default PostsSliderItem;
