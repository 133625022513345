import React, {useState} from 'react';
import {validateEmail} from "../../../core/utils/form-validate-helpers";
import {useAppDispatch} from "../../../core/hooks";
import {sendFeedback} from "../../../core/store/api-actions";
import {INITIAL_FORM_STATE} from "../../../core/constants/common";
import Title from "../../ordinary/title/title";


function FeedbackForm({title}: {title?: string}) {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState<{[key: string]: string }>({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const {name, value} = event.target;
    setFormData({...formData, [name]: value,});
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newErrors: { [key: string]: string } = {};
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Пожалуйста, укажите корректный email';
    }
    if (Object.keys(newErrors).length === 0) {
      dispatch(sendFeedback(formData));
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <>
      <Title title={title}/>
      <form
        method="post"
        action="#"
        className="siteform addfaq"
        name="addingform"
        onSubmit={handleSubmit}
      >
        <div hidden={Object.entries(errors).length === 0} className="error">
          <b>ВНИМАНИЕ!</b><br/><br/>Были обнаружены следующие ошибки:
          <ul>
            {Object.entries(errors).map(([fieldName, errorMessage]) => (<li key={fieldName}>{errorMessage}</li>))}
          </ul>
        </div>
        <ul>
          <li className="title_table">Как Вас зовут?</li>
          <li>
            <input type="text" required id="first_name" name="first_name" value={formData.first_name} onChange={handleChange}
            />
          </li>
          <li className="title_table">Как Ваша фамилия?</li>
          <li>
            <input type="text" required id="last_name" name="last_name" value={formData.last_name} onChange={handleChange}/>
          </li>
          <li className="title_table">Ваш Email</li>
          <li>
            <input type="text" required id="email" name="email" value={formData.email} onChange={handleChange}/>
          </li>
          <li className="title_table">Город</li>
          <li>
            <input required type="text" id="city" name="city" value={formData.city} onChange={handleChange}/>
          </li>
          <li className="title_table">Адресат</li>
          <li>
            <select required id="recipient" name="recipient" value={formData.recipient} onChange={handleChange}>
              <option value="admin">Администрации сайта</option>
              <option value="club_admin">Администрации клуба</option>
              <option value="coach">Главному тренеру</option>
            </select>
          </li>
          <li className="title_table">Сообщение</li>
          <li className="li-textarea">
            <textarea required id="request" name="request" value={formData.request} onChange={handleChange}/>
          </li>
        </ul>
        <div id="send">
          <input type="submit" value="Отправить сообщение" className="send_btn"/>
        </div>
      </form>
    </>
  );
}

export default FeedbackForm;