import {createSlice} from '@reduxjs/toolkit';
import {
  fetchMatch,
  fetchMatches,
  fetchMatchGoalkeepers,
  fetchMatchGoals,
  fetchMatchReferees,
  fetchPlayerMatches,
  fetchTeamMatches,
  fetchTournamentMatches,
  fetchUnrealizedPenalties,
} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {MatchesState} from "../../types/state";

const initialState: MatchesState = {
  isMatchesLoading: false,
  isMatchLoading: false,
  isMatchesError: false,
  isMatchError: false,
  matches: [],
  match: null,
  isTournamentMatchesLoading: false,
  isTournamentMatchesError: false,
  tournamentMatches: [],
  isMatchGoalsLoading: false,
  isMatchGoalsError: false,
  matchGoals: [],
  isUnrealizedPenaltiesLoading: false,
  isUnrealizedPenaltiesError: false,
  unrealizedPenalties: [],
  isMatchGoalkeepersLoading: false,
  isMatchGoalkeepersError: false,
  matchGoalkeepers: [],
  isMatchRefereesLoading: false,
  isMatchRefereesError: false,
  matchReferees: [],
  isPlayerMatchesLoading: false,
  isPlayerMatchesError: false,
  playerMatches: [],
  isTeamMatchesLoading: false,
  isTeamMatchesError: false,
  teamMatches: [],
};

export const matchesData = createSlice({
  name: NameSpace.Matches,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMatches.pending, (state) => {
        state.isMatchesLoading = true;
      })
      .addCase(fetchMatches.fulfilled, (state, action) => {
        state.matches = action.payload;
        state.isMatchesLoading = false;
      })
      .addCase(fetchMatches.rejected, (state) => {
        state.isMatchesError = true;
        state.isMatchesLoading = false;
      })
      .addCase(fetchMatch.pending, (state) => {
        state.isMatchLoading = true;
      })
      .addCase(fetchMatch.fulfilled, (state, action) => {
        state.match = action.payload;
        state.isMatchLoading = false;
      })
      .addCase(fetchMatch.rejected, (state) => {
        state.isMatchError = true;
        state.isMatchLoading = false;
      })
      .addCase(fetchTournamentMatches.pending, (state) => {
        state.isTournamentMatchesLoading = true;
      })
      .addCase(fetchTournamentMatches.fulfilled, (state, action) => {
        state.tournamentMatches = action.payload;
        state.isTournamentMatchesLoading = false;
      })
      .addCase(fetchTournamentMatches.rejected, (state) => {
        state.isTournamentMatchesError = true;
        state.isTournamentMatchesLoading = false;
      })
      .addCase(fetchMatchGoals.pending, (state) => {
        state.isMatchGoalsLoading = true;
      })
      .addCase(fetchMatchGoals.fulfilled, (state, action) => {
        state.matchGoals = action.payload;
        state.isMatchGoalsLoading = false;
      })
      .addCase(fetchMatchGoals.rejected, (state) => {
        state.isMatchGoalsError = true;
        state.isMatchGoalsLoading = false;
      })
      .addCase(fetchUnrealizedPenalties.pending, (state) => {
        state.isUnrealizedPenaltiesLoading = true;
      })
      .addCase(fetchUnrealizedPenalties.fulfilled, (state, action) => {
        state.unrealizedPenalties = action.payload;
        state.isUnrealizedPenaltiesLoading = false;
      })
      .addCase(fetchUnrealizedPenalties.rejected, (state) => {
        state.isUnrealizedPenaltiesError = true;
        state.isUnrealizedPenaltiesLoading = false;
      })
      .addCase(fetchMatchGoalkeepers.pending, (state) => {
        state.isMatchGoalkeepersLoading = true;
      })
      .addCase(fetchMatchGoalkeepers.fulfilled, (state, action) => {
        state.matchGoalkeepers = action.payload;
        state.isMatchGoalkeepersLoading = false;
      })
      .addCase(fetchMatchGoalkeepers.rejected, (state) => {
        state.isMatchGoalkeepersError = true;
        state.isMatchGoalkeepersLoading = false;
      })
      .addCase(fetchMatchReferees.pending, (state) => {
        state.isMatchRefereesLoading = true;
      })
      .addCase(fetchMatchReferees.fulfilled, (state, action) => {
        state.matchReferees = action.payload;
        state.isMatchRefereesLoading = false;
      })
      .addCase(fetchMatchReferees.rejected, (state) => {
        state.isMatchRefereesError = true;
        state.isMatchRefereesLoading = false;
      })
      .addCase(fetchPlayerMatches.pending, (state) => {
        state.isPlayerMatchesLoading = true;
      })
      .addCase(fetchPlayerMatches.fulfilled, (state, action) => {
        state.playerMatches = action.payload;
        state.isPlayerMatchesLoading = false;
      })
      .addCase(fetchPlayerMatches.rejected, (state) => {
        state.isPlayerMatchesError = true;
        state.isPlayerMatchesLoading = false;
      })
      .addCase(fetchTeamMatches.pending, (state) => {
        state.isTeamMatchesLoading = true;
      })
      .addCase(fetchTeamMatches.fulfilled, (state, action) => {
        state.teamMatches = action.payload;
        state.isTeamMatchesLoading = false;
      })
      .addCase(fetchTeamMatches.rejected, (state) => {
        state.isTeamMatchesError = true;
        state.isTeamMatchesLoading = false;
      })
  }
});
