import {PhotosState, State} from '../../types/state';
import {PhotoGalleries, PhotoGallery} from '../../types/media';
import {NameSpace} from "../../constants/api-constants";

export const getPhotoGalleriesLoadingStatus = (state: State): boolean => state[NameSpace.Photos].isPhotoGalleriesLoading;
export const getPhotoGalleryLoadingStatus = (state: State): boolean => state[NameSpace.Photos].isPhotoGalleryLoading;
export const getPhotoGalleriesErrorStatus = (state: State): PhotosState['isPhotoGalleriesError'] => state[NameSpace.Photos].isPhotoGalleriesError;
export const getPhotoGalleryErrorStatus = (state: State): PhotosState['isPhotoGalleryError'] => state[NameSpace.Photos].isPhotoGalleryError;
export const getPhotoGalleries = (state: State): PhotoGalleries => state[NameSpace.Photos].photoGalleries;
export const getCurrentPhotoGallery = (state: State): PhotoGallery | null => state[NameSpace.Photos].currentPhotoGallery;
