import React from 'react';
import {ProcurementsType} from '../../../core/types/procurement';
import ProcurementItem from '../procurement-item/procurement-item';
import Message from "../message/message";
import Loader from "../loader/loader";
import {useAppSelector} from "../../../core/hooks";
import {
  getProcurementsErrorStatus,
  getProcurementsLoadingStatus
} from "../../../core/store/procurements-data/selectors";
import Error from "../../../pages/error/error";

function ProcurementList({currentProcurements}: { currentProcurements: ProcurementsType }) {
  const isProcurementsLoading = useAppSelector(getProcurementsLoadingStatus);
  const isProcurementsError = useAppSelector(getProcurementsErrorStatus);

  return (
    <>
      <div hidden={!isProcurementsLoading}>
        <Loader/>
      </div>
      <div hidden={!isProcurementsError}>
        <Error/>
      </div>
      <div hidden={currentProcurements.length > 0 || isProcurementsLoading}>
        <Message message="Нет доступных данных по закупкам"/>
      </div>
      <div hidden={currentProcurements.length === 0}>
        <ul className="purchases-list">
          {currentProcurements.map((procurement) => <ProcurementItem key={procurement.procurement_id} procurement={procurement}/>)}
        </ul>
      </div>
    </>
  );
}

export default ProcurementList;
