import {createSlice} from '@reduxjs/toolkit';
import {
  fetchGroupTournament,
  fetchTournament,
  fetchTournaments,
  fetchTournamentStats,
} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {TournamentsState} from "../../types/state";

const initialState: TournamentsState = {
  isTournamentsLoading: false,
  isTournamentLoading: false,
  isTournamentsError: false,
  isTournamentError: false,
  tournaments: [],
  tournament: null,
  isTournamentsStatsLoading: false,
  isTournamentsStatsError: false,
  tournamentsStats: [],
  isGroupTournamentLoading: false,
  isGroupTournamentError: false,
  groupTournament: [],
};

export const tournamentsData = createSlice({
  name: NameSpace.Tournaments,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTournaments.pending, (state) => {
        state.isTournamentsLoading = true;
      })
      .addCase(fetchTournaments.fulfilled, (state, action) => {
        state.tournaments = action.payload;
        state.isTournamentsLoading = false;
      })
      .addCase(fetchTournaments.rejected, (state) => {
        state.isTournamentsError = true;
        state.isTournamentsLoading = false;
      })
      .addCase(fetchTournament.pending, (state) => {
        state.isTournamentLoading = true;
      })
      .addCase(fetchTournament.fulfilled, (state, action) => {
        state.tournament = action.payload;
        state.isTournamentLoading = false;
      })
      .addCase(fetchTournament.rejected, (state) => {
        state.isTournamentError = true;
        state.isTournamentLoading = false;
      })
      .addCase(fetchTournamentStats.pending, (state) => {
        state.isTournamentsStatsLoading = true;
      })
      .addCase(fetchTournamentStats.fulfilled, (state, action) => {
        const existingTournaments = state.tournamentsStats.find(stats => stats.tournamentId === action.payload.tournamentId);
        if (!existingTournaments) {
          state.tournamentsStats.push(action.payload);
        }
        state.isTournamentsStatsLoading = false;
      })
      .addCase(fetchTournamentStats.rejected, (state) => {
        state.isTournamentsStatsError = true;
        state.isTournamentsStatsLoading = false;
      })
      .addCase(fetchGroupTournament.pending, (state) => {
        state.isGroupTournamentLoading = true;
      })
      .addCase(fetchGroupTournament.fulfilled, (state, action) => {
        const existingTournaments = state.groupTournament.find(stats => stats.tournamentId === action.payload.tournamentId);
        if (!existingTournaments) {
          state.groupTournament.push(action.payload);
        }
        state.isGroupTournamentLoading = false;
      })
      .addCase(fetchGroupTournament.rejected, (state) => {
        state.isGroupTournamentError = true;
        state.isGroupTournamentLoading = false;
      })
  }
});
