import React from "react";
import {PlayerItems} from "../../../core/types/player";
import Message from "../message/message";
import PagePlayersList from "../page-players-list/page-players-list";

function PagePlayers({players, title}: {players: PlayerItems | undefined, title: string}) {

  return (
    <>
      <div hidden={players?.length === 0}>
        <PagePlayersList players={players} title={title}/>
      </div>
      <div hidden={players === undefined || players.length > 0}>
        <Message message={`${title} отсутствуют.`}/>
      </div>
    </>
  );
}

export default PagePlayers;