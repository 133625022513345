import React from 'react';
import {Photos} from '../../../core/types/media';
import PhotoItem from '../photo-item/photo-item';
import Message from "../message/message";
import Fancybox from "../../smart/fancybox/fancybox";

function PhotosList({currentPhotos}: {currentPhotos: Photos | undefined}) {
  return (
    <>
      <div hidden={currentPhotos === undefined || currentPhotos.length === 0}>
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          <ul className="photo-list-other">
            {currentPhotos?.map((photo) => <PhotoItem key={photo.photo_id} photo={photo}/>)}
          </ul>
        </Fancybox>
      </div>
      <div hidden={currentPhotos !== undefined && currentPhotos.length > 0}>
        <Message message="К сожалению, на данный момент фотогалерея пуста. Пожалуйста, загляните позже!"/>
      </div>
    </>
  );
}

export default PhotosList;
