import React from 'react';
import PostsList from '../../components/ordinary/posts-list/posts-list';
import {
  useEffect,
  useState}
  from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {POSTS_PER_PAGE} from '../../core/constants/common';
import Pagination from '../../components/smart/pagination/pagination';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {
  getPosts,
  getPostsErrorStatus,
  getPostsLoadingStatus
} from '../../core/store/posts-data/selectors';
import {AppRoute} from "../../core/constants/routes";
import {filterPostsByTag} from "../../core/utils/array-prepare-helpers";
import {fetchPosts} from "../../core/store/api-actions";
import Loader from "../../components/ordinary/loader/loader";
import Error from "../error/error";

function School() {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(getPosts);
  const isPostsLoading = useAppSelector(getPostsLoadingStatus);
  const isPostsError = useAppSelector(getPostsErrorStatus);
  const filteredPosts = filterPostsByTag(posts, AppRoute.School.title);
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);
  const start = (currentPage - 1) * POSTS_PER_PAGE;
  const end = start + POSTS_PER_PAGE;
  const currentPosts = filteredPosts.slice(start, end);

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  const goToPage = (page : number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.School]);
  }, [setBreadcrumbs]);

  return (
    <>
      <div hidden={!isPostsLoading}>
        <Loader/>
      </div>
      <div hidden={!isPostsError}>
        <Error/>
      </div>
      <div className="block-press" hidden={isPostsLoading}>
        <div className="container-center">
          <PostsList currentPosts={currentPosts} tag={AppRoute.School.title}/>
          <Pagination currentPage={currentPage} totalPages={totalPages} goToPage={goToPage}/>
        </div>
      </div>
    </>
  );
}

export default School;
