import {combineReducers} from '@reduxjs/toolkit';
import {NameSpace} from "../constants/api-constants";
import {postsData} from './posts-data/posts-data';
import {photosData} from './photos-data/photos-data';
import {mediaData} from "./media-data/media-data";
import {procurementsData} from './procurements-data/procurements-data';
import {feedbacksData} from "./feedbacks-data/feedbacks-data";
import {massSkatingData} from "./mass-skating-data/mass-skating-data";
import {createFeedbackData} from "./create-feedback-data/create-feedback-data";
import {contactsData} from "./contacts-data/contacts-data";
import {videosData} from "./videos-data/videos-data";
import {teamsData} from "./teams-data/teams-data";
import {playersData} from "./players-data/players-data";
import {clubsData} from "./clubs-data/clubs-data";
import {representativesData} from "./representatives-data/representatives-data";
import {tournamentsData} from "./tournaments-data/tournaments-data";
import {matchesData} from "./matches-data/matches-data";
import {bannersData} from "./banners-data/banners-data";
import {partnersData} from "./partners-data/partners-data";
import {trophiesData} from "./trophies-data/trophies-data";
import {socialsData} from "./socials-data/socials-data";
import {stadiumsData} from "./stadiums-data/stadiums-data";
import {teamsTablesData} from "./teams-tables-data/teams-tables-data";
import {seasonsData} from "./seasons-data/seasons-data";
import {roundsData} from "./rounds-data/rounds-data";
import {chessData} from "./chess-data/chess-data";
import {citiesData} from './cities-data/cities-data'
import {searchesData} from './searches-data/searches-data'
import {chaptersData} from './chapters-data/chapters-data'
import {teamRoundsData} from "./team-rounds-data/team-rounds-data";
import {widgetData} from "./widget-data/widget-data";
import {roundTeamsData} from "./round-teams-data/round-teams-data";
import {roundPlayersData} from "./round-players-data/round-players-data";
import {teamPlayersData} from "./team-players-data/team-players-data";
import {playerStatsData} from "./player-stats-data/player-stats-data";

export const rootReducer = combineReducers({
  [NameSpace.Posts]: postsData.reducer,
  [NameSpace.Photos]: photosData.reducer,
  [NameSpace.Videos]: videosData.reducer,
  [NameSpace.Media]: mediaData.reducer,
  [NameSpace.Tournaments]: tournamentsData.reducer,
  [NameSpace.Procurements]: procurementsData.reducer,
  [NameSpace.Feedbacks]: feedbacksData.reducer,
  [NameSpace.MassSkating]: massSkatingData.reducer,
  [NameSpace.CreateFeedback]: createFeedbackData.reducer,
  [NameSpace.Contacts]: contactsData.reducer,
  [NameSpace.Teams]: teamsData.reducer,
  [NameSpace.Players]: playersData.reducer,
  [NameSpace.PlayerStats]: playerStatsData.reducer,
  [NameSpace.TeamPlayers]: teamPlayersData.reducer,
  [NameSpace.Clubs]: clubsData.reducer,
  [NameSpace.Representatives]: representativesData.reducer,
  [NameSpace.Matches]: matchesData.reducer,
  [NameSpace.Banners]: bannersData.reducer,
  [NameSpace.Partners]: partnersData.reducer,
  [NameSpace.Trophies]: trophiesData.reducer,
  [NameSpace.Socials]: socialsData.reducer,
  [NameSpace.Stadiums]: stadiumsData.reducer,
  [NameSpace.TeamsTables]: teamsTablesData.reducer,
  [NameSpace.Seasons]: seasonsData.reducer,
  [NameSpace.Rounds]: roundsData.reducer,
  [NameSpace.Widget]: widgetData.reducer,
  [NameSpace.TeamRounds]: teamRoundsData.reducer,
  [NameSpace.RoundTeams]: roundTeamsData.reducer,
  [NameSpace.RoundPlayers]: roundPlayersData.reducer,
  [NameSpace.Chess]: chessData.reducer,
  [NameSpace.Cities]: citiesData.reducer,
  [NameSpace.Searches]: searchesData.reducer,
  [NameSpace.Chapters]: chaptersData.reducer,
});
