export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
};

export function validateSearchForm(searchString: string): {[key: string]: string } {
  const newErrors: {[key: string]: string} = {};
  const searchStringFilled = searchString !== null && searchString !== '';
  if (!searchStringFilled) {
    newErrors['search'] = 'Поисковой запрос не может быть пустым';
  }
  return newErrors;
}