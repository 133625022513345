import React from "react";
import TinySlider from "tiny-slider-react";
import {matchesSliderConfig} from "../../../core/config/slider-config";
import SliderArrows from "../slider-arrows/slider-arrows";
import PointsItem from "../../simple/points-item/points-item";
import MatchesSliderItem from "../matches-slider-item/matches-slider-item";
import {Matches} from "../../../core/types/match";

function MatchesSlider({matches}: { matches: Matches }) {
  return (
    <div className="matches">
      <TinySlider settings={matchesSliderConfig}>
        {matches.map((match) =>
          <MatchesSliderItem key={match.match_id} match={match}/>
        )}
      </TinySlider>
      <SliderArrows type="matches"/>
      <ul className="points points--dark js-matches-nav">
        {matches.map((match) =>
          <PointsItem key={match.match_id}/>
        )}
      </ul>
    </div>
  );
}

export default MatchesSlider;