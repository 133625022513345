import React from 'react';
import TinySlider from 'tiny-slider-react';
import "tiny-slider/dist/tiny-slider.css";
import {Posts} from '../../../core/types/post';
import SliderArrows from "../slider-arrows/slider-arrows";
import PointsItem from "../../simple/points-item/points-item";
import PostsSliderItem from "../posts-slider-item/posts-slider-item";
import {postsSliderConfig} from "../../../core/config/slider-config";

function PostsSlider({posts}: { posts: Posts }) {
  return (
    <div hidden={posts.length === 0} className="slides slides--dark">
      <TinySlider settings={postsSliderConfig}>
        {posts.map((post) =>
          <PostsSliderItem key={post.post_id} post={post}/>
        )}
      </TinySlider>
      <SliderArrows type="posts"/>
      <ul className="points points--dark js-posts-nav">
        {posts.map((post) =>
          <PointsItem key={post.post_id}/>
        )}
      </ul>
    </div>
  );
}

export default PostsSlider;
