/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { StatusCodes } from "http-status-codes";
import config from "./config/config";

const StatusCodeMapping: Record<number, boolean> = {
  [StatusCodes.BAD_REQUEST]: true,
  [StatusCodes.NOT_FOUND]: true,
};

const shouldDisplayError = (response: AxiosResponse) =>
  StatusCodeMapping[response.status];

export const createAPI = (): AxiosInstance => {
  const api = axios.create({
    baseURL: config.apiBaseUrl,
    timeout: config.requestTimeout,
  });
  api.interceptors.request.use(
    (config: AxiosRequestConfig): Promise<InternalAxiosRequestConfig<any>> =>
      Promise.resolve(config as InternalAxiosRequestConfig<any>)
  );
  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError<{ error: string }>) => {
      if (error.response && shouldDisplayError(error.response)) {
        console.log(error.response.data.error);
      }
      throw error;
    }
  );
  return api;
};
