import React from 'react';
import Layout from './components/ordinary/layout/layout';
import {BreadcrumbsProvider} from './components/smart/breadcrumbs-provider/breadcrumbs-provider';
import RoutesComponent from './core/routes/routes';
import './assets/css/style.css';
import './assets/css/media.css';
import './assets/plugins/bvi/css/bvi.min.css';
import './assets/plugins/bvi/css/bvi-font.min.css';
import './assets/plugins/bvi/js/bvi.min.js';
import './assets/plugins/bvi/js/bvi-init.js';

function App() {
  return (
    <BreadcrumbsProvider>
      <Layout>
        <RoutesComponent/>
      </Layout>
    </BreadcrumbsProvider>
  );
}

export default App;
