import React, { useEffect } from "react";
// import Filters from "../../smart/filters/filters";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { fetchPlayerMatches } from "../../../core/store/api-actions";
import { useParams } from "react-router-dom";
import { PlayerItem } from '../../../core/types/player'
import { format } from 'date-fns'
import { getPlayerMatches } from '../../../core/store/matches-data/selectors'

function PlayerMatches({ player }: { player: PlayerItem | null }) {
  const playerTeamIds = player?.teams.map(team=>team.team_id) || [];
  const dispatch = useAppDispatch();
  const { id: playerId } = useParams();
  const matches = useAppSelector(getPlayerMatches);
  useEffect(() => {
    if (playerId) {
      dispatch(fetchPlayerMatches({ playerId: Number(playerId) }));
    }
  }, [playerId, dispatch]);

  return (
    <div className="tab-content">
      <div className="gap gap--20">
        {/* <Filters /> */}
        {matches.length > 0 && (
          <div className="table-scroll">
            <table className="table">
              <thead className="table__thead">
                <tr className="table__row">
                  <th className="table__th table__th--center">Соперник</th>
                  <th className="table__th">Дата</th>
                </tr>
              </thead>
              <tbody className="table__tbody">
                {matches.map((match, index) => {
                  if (playerTeamIds.includes(match.team1_id)) {
                    return (
                      <tr key={index} className="table__row">
                        <td className="table__cell table__cell--center">
                          {match.team2?.full_name || "Не определено"}
                        </td>
                        <td className="table__cell">
                          {match.start_dt && format(new Date(match.start_dt), 'dd.MM.yyyy')}
                        </td>
                      </tr>
                    );
                  } else if (playerTeamIds.includes(match.team2_id)) {
                    return (
                      <tr key={index} className="table__row">
                        <td className="table__cell table__cell--center">
                          {match.team1?.full_name || "Не определено"}
                        </td>
                        <td className="table__cell">
                          {match.start_dt && format(new Date(match.start_dt), 'dd.MM.yyyy')}
                        </td>
                      </tr>
                    );
                  }
                  return null;
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default PlayerMatches;
