import React, {useEffect} from "react";
import {PlayerItem} from "../../../core/types/player";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {getPosition,} from "../../../core/store/players-data/selectors";
import {
  fetchPlayerStatsGroup,
  fetchPosition,
} from "../../../core/store/api-actions";
import {useParams} from "react-router-dom";
import {findPlayerStatsGroupById} from "../../../core/utils/array-prepare-helpers";
import {getPlayerStatsGroups} from "../../../core/store/player-stats-data/selectors";

function PlayerHeadCard({player}: { player: PlayerItem | null }) {
  const {id: playerId} = useParams();
  const dispatch = useAppDispatch();
  const position = useAppSelector(getPosition);
  const playersStats = useAppSelector(getPlayerStatsGroups);
  const playerStats = findPlayerStatsGroupById(playersStats, Number(playerId));
  useEffect(() => {
    if (player) {
      dispatch(fetchPosition(Number(player?.position_id)));
    }
  }, [player?.position_id]);

  useEffect(() => {
    dispatch(
      fetchPlayerStatsGroup({playerId: Number(playerId), groupBy: "player_id"})
    );
  }, [playerId]);

  const birthDate =
    player && player.birthday ? new Date(player.birthday) : null;
  const birthInfo = birthDate
    ? `${simpleDateFormat(birthDate, "dd MMMM yyyy")}`
    : "-";

  return (
    <div className="phead">
      <div className="phead__container">
        <div className="phead__content">
          <p className="phead__number mobile-hide">{player?.number}</p>
          <img
            className="phead__img"
            src={player?._links.photo.href}
            width={270}
            height={270}
            alt="Игрок"
          />
        </div>
        <div className="phead__content">
          <p className="phead__number mobil-show">{player?.number}</p>
          <p className="phead__text">
            {player?.last_name} {player?.first_name}
          </p>
        </div>
      </div>
      <div className="phead__right">
        <ul className="phead__list">
          <li className="phead__item">
            <p className="phead__subtitle">Дата рождения</p>
            <p className="phead__title">{birthInfo}</p>
          </li>
          <li className="phead__item">
            <p className="phead__subtitle">Амплуа</p>
            <p className="phead__title">{position?.long_title}</p>
          </li>
          <li className="phead__item">
            <p className="phead__subtitle">В клубе с</p>
            <p className="phead__title">{player?.in_club_since}</p>
          </li>
          <li className="phead__item">
            <p className="phead__subtitle">Матчи</p>
            <p className="phead__title">{playerStats?.stats[0]?.games}</p>
          </li>
          <li className="phead__item">
            <p className="phead__subtitle">Голы</p>
            <p className="phead__title">{playerStats?.stats[0]?.goals}</p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PlayerHeadCard;
