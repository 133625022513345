import React, {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {useAppSelector} from "../../core/hooks";
import {store} from "../../core/store";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import {getPosts} from "../../core/store/posts-data/selectors";
import {filterPostsByTag} from "../../core/utils/array-prepare-helpers";
import Page from "../../components/ordinary/page/page";
import {fetchPastPresentFuturePage} from "../../core/store/api-actions";
import {
  getPastPresentFuturePage,
  getPastPresentFuturePageErrorStatus,
  getPastPresentFuturePageLoadingStatus
} from "../../core/store/clubs-data/selectors";
import {CountryCupsNavigations} from "../../core/constants/navigations";

store.dispatch(fetchPastPresentFuturePage());

function PastPresentFuture() {
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByTag(posts, AppRoute.PastPresentFuture.title);
  const pastPresentFuturePage = useAppSelector(getPastPresentFuturePage);
  const isPastPresentFuturePageLoading = useAppSelector(getPastPresentFuturePageLoadingStatus);
  const isPastPresentFuturePageError = useAppSelector(getPastPresentFuturePageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.History, AppRoute.CountryCups, AppRoute.PastPresentFuture]);
  }, [setBreadcrumbs]);

  return (
    <div className="container-center">
      <main>
        <Page content={pastPresentFuturePage?.text} isPageLoading={isPastPresentFuturePageLoading} isPageError={isPastPresentFuturePageError}/>
        <div className="block-press other">
          <div className="container-center">
            <PostsList currentPosts={filteredPosts} tag={AppRoute.PastPresentFuture.title}/>
          </div>
        </div>
      </main>
      <aside>
        <SidebarNavigation navigationArray={CountryCupsNavigations}/>
      </aside>
    </div>
  );
}

export default PastPresentFuture;