import React from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {
  fetchPlayer,
  fetchTournament
} from '../../core/store/api-actions';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import 'font-awesome/css/font-awesome.min.css';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {getTournament} from "../../core/store/tournaments-data/selectors";
import {
  getPlayer,
  getPlayerErrorStatus,
  getPlayerLoadingStatus,
} from "../../core/store/players-data/selectors";
import HtmlRenderer from "../../components/smart/html-renderer/html-renderer";
import PlayerHead from "../../components/ordinary/player-head/player-head";
import PlayerStats from "../../components/ordinary/player-stats/player-stats";
import { TEAM_IDS } from '../../core/constants/common'

function TournamentPlayer() {
  const dispatch = useAppDispatch();
  const {tournamentId: tournamentId, playerId: playerId} = useParams();
  const tournament = useAppSelector(getTournament);
  const player = useAppSelector(getPlayer);
  const isPlayerError = useAppSelector(getPlayerErrorStatus);
  const isPlayerLoading = useAppSelector(getPlayerLoadingStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchTournament(Number(tournamentId)));
  }, [tournamentId]);

  useEffect(() => {
    dispatch(fetchPlayer(Number(playerId)));
  }, [playerId]);

  useEffect(() => {
    if (tournament && player) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.Tournaments,
        {
          title: tournament.full_name,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/info`
        },
        {
          title: `${player.last_name} ${player.first_name} ${player.middle_name}`,
          url: "#"
        },
      ]);
    }
  }, [tournament, player]);

  return (
    <>
      <div hidden={!isPlayerLoading}>
        <Loader/>
      </div>
      <div hidden={!isPlayerError}>
        <Error/>
      </div>
      <div className="container-center" hidden={isPlayerLoading}>
        <main className="full">
          <div className="stats other">
            <PlayerHead player={player}/>
            <br/>
            {(player?.team.team_id === TEAM_IDS.YENISEY || player?.team.team_id === TEAM_IDS.YENISEY_2) && <PlayerStats />}

            <br/>
            <div className="detail">
              <div className="easy-text">
                <HtmlRenderer htmlString={player?.about_me}/>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default TournamentPlayer;
