import React, {useEffect} from 'react';
import PartnersList from "../partners-list/partners-list";
import {fetchPartners} from "../../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getPartners,
  getPartnersLoadingStatus
} from "../../../core/store/partners-data/selectors";
import Loader from "../loader/loader";

function PartnersWidget() {
  const dispatch = useAppDispatch();
  const partners = useAppSelector(getPartners);
  const partnersLoading = useAppSelector(getPartnersLoadingStatus);

  useEffect(() => {
    dispatch(fetchPartners());
  }, [dispatch]);

  return (
    <div className="block-partners" hidden={partners.length === 0}>
      <div className="container-center" hidden={partnersLoading}>
        <div className="title-center">Партнеры команды</div>
        <PartnersList partners={partners}/>
      </div>
      <Loader hidden={!partnersLoading}/>
    </div>
  );
}

export default PartnersWidget;