import {createSlice} from '@reduxjs/toolkit';
import {
  fetchGroupStadium,
  fetchStadium,
} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {StadiumsState} from "../../types/state";

const initialState: StadiumsState = {
  isStadiumLoading: false,
  isStadiumError: false,
  stadium: null,
  isGroupStadiumLoading: false,
  isGroupStadiumError: false,
  groupStadium: [],
};

export const stadiumsData = createSlice({
  name: NameSpace.Stadiums,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStadium.pending, (state) => {
        state.isStadiumLoading = true;
      })
      .addCase(fetchStadium.fulfilled, (state, action) => {
        state.stadium = action.payload;
        state.isStadiumLoading = false;
      })
      .addCase(fetchStadium.rejected, (state) => {
        state.isStadiumError = true;
        state.isStadiumLoading = false;
      })
      .addCase(fetchGroupStadium.pending, (state) => {
        state.isGroupStadiumLoading = true;
      })
      .addCase(fetchGroupStadium.fulfilled, (state, action) => {
        const existingStadiums = state.groupStadium.find(stats => stats.id === action.payload.id);
        if (!existingStadiums) {
          state.groupStadium.push(action.payload);
        }
        state.isGroupStadiumLoading = false;
      })
      .addCase(fetchGroupStadium.rejected, (state) => {
        state.isGroupStadiumError = true;
        state.isGroupStadiumLoading = false;
      })
  }
});
