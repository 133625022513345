import {createSlice} from '@reduxjs/toolkit';
import {fetchMassSkatingPage} from '../api-actions';
import {MassSkatingState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: MassSkatingState = {
  isMassSkatingPageLoading: false,
  isMassSkatingPageError: false,
  massSkatingPage: null
};

export const massSkatingData = createSlice({
  name: NameSpace.MassSkating,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMassSkatingPage.pending, (state) => {
        state.isMassSkatingPageLoading = true;
      })
      .addCase(fetchMassSkatingPage.fulfilled, (state, action) => {
        state.massSkatingPage = action.payload;
        state.isMassSkatingPageLoading = false;
      })
      .addCase(fetchMassSkatingPage.rejected, (state) => {
        state.isMassSkatingPageError = true;
        state.isMassSkatingPageLoading = false;
      })
  }
});
