import React, { useEffect, useState } from "react";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { AppRoute } from "../../core/constants/routes";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import Pagination from "../../components/smart/pagination/pagination";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { POSTS_PER_PAGE } from "../../core/constants/common";
import {
  fetchChapterById,
  fetchChaptersChild,
} from "../../core/store/api-actions";
import GoalkeeperSchoolSidebar from "../../components/ordinary/goalkeeper-school-sidebar/goalkeeper-school-sidebar";
import { useParams } from "react-router-dom";
import {
  getChapter,
  getChapters,
} from "../../core/store/chapters-data/selectors";

function SeasonsPage() {
  const { id: chapterId } = useParams();
  const dispatch = useAppDispatch();
  const chapter = useAppSelector(getChapter);
  const chaptersChild = useAppSelector(getChapters);
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchChapterById(Number(chapterId)));
  }, [dispatch, chapterId]);

  useEffect(() => {
    if (chapter) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.AboutClub,
        AppRoute.GoalkeeperSchool,
        { title: chapter.name, url: "#" },
      ]);
    }
  }, [chapter, setBreadcrumbs]);

  useEffect(() => {
    dispatch(fetchChaptersChild(Number(chapterId)));
  }, [dispatch, chapterId]);

  const totalPages =
    Math.ceil((chapter?.posts?.length || 0) / POSTS_PER_PAGE) || 1;
  const start = (currentPage - 1) * POSTS_PER_PAGE;
  const end = start + POSTS_PER_PAGE;
  const currentPosts = chapter?.posts.slice(start, end);

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="container-center">
      <main>
        <div className="block-press other">
          <div className="container-center">
            {chaptersChild?.length > 0 &&
              chaptersChild.map((chapter) => (
                <PostsList
                  key={chapter.chapter_id}
                  currentPosts={chapter.posts}
                  tag={chapter.name}
                  title={chapter.name}
                  url={`${AppRoute.GoalkeeperSchool.url}/chapters/${chapter.chapter_id}`}
                />
              ))}
            {!chaptersChild?.length && currentPosts && (
              <PostsList currentPosts={currentPosts} tag={chapter?.name} />
            )}
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              goToPage={goToPage}
            />
          </div>
        </div>
      </main>
      <aside>
        <GoalkeeperSchoolSidebar />
      </aside>
    </div>
  );
}

export default SeasonsPage;
