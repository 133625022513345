import React from 'react';
import SidebarFilter from "../sidebar-filter/sidebar-filter";
import {PLAYERS_FILTERS} from "../../../core/constants/common";

function TeamSidebar({activeSection, onSectionClick} : {activeSection: string | null, onSectionClick: (sectionTitle: string) => void}) {
  const playersFiltersArray = Object.values(PLAYERS_FILTERS);

  return (
    <aside>
      <SidebarFilter activeSection={activeSection} onSectionClick={onSectionClick} filterArray={playersFiltersArray}/>
    </aside>
  );
}

export default TeamSidebar;