import React from 'react';
import {Option} from "../../../core/types/common";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {changeWidgetPlayersTypeFilter} from "../../../core/store/widget-data/widget-data";
import {getWidgetPlayersTypeFilter} from "../../../core/store/widget-data/selectors";

function ButtonsItem({option}: { option: Option }) {
  const dispatch = useAppDispatch();
  const widgetPlayersTypeFilter = useAppSelector(getWidgetPlayersTypeFilter);

  const handleClick = () => {
    dispatch(changeWidgetPlayersTypeFilter({widgetPlayersTypeFilter: option}))
  };

  return (
    <li className={`buttons__item ${widgetPlayersTypeFilter === option ? 'buttons__item--active' : ''}`}>
      <a className="buttons__link" onClick={handleClick}>{option.label}</a>
    </li>
  );
}

export default ButtonsItem;