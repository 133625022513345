import React from 'react';
import Title from "../title/title";
import {TournamentsStats} from "../../../core/types/tournament";

function TournamentSpecifications({tournamentStats}: {tournamentStats: TournamentsStats | undefined}) {
  return (
    <>
      <Title title="Матчи"/>
      <table className="stat-table char">
        <tbody>
        <tr>
          <th>Характеристика</th>
          <th>Показатель</th>
        </tr>
        <tr>
          <td>Всего сыграно матчей</td>
          <td>{tournamentStats?.stats?.games}</td>
        </tr>
        <tr>
          <td>Одержано побед хозяевами (поражения гостей)</td>
          <td>{tournamentStats?.stats?.wins_home}</td>
        </tr>
        <tr>
          <td>Одержано побед гостями (поражения хозяев)</td>
          <td>{tournamentStats?.stats?.wins_away}</td>
        </tr>
        <tr>
          <td>Ничейных результатов</td>
          <td>{tournamentStats?.stats?.draws}</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default TournamentSpecifications;
