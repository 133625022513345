import React from "react";
import { InlineShareButtons } from 'sharethis-reactjs';

function Sharing() {
  return (
    <InlineShareButtons
      config={{
        alignment: 'center',
        color: 'social',
        enabled: true,
        font_size: 16,
        labels: null,
        language: 'en',
        networks: [
          'facebook',
          'twitter',
          'vk',
          // eslint-disable-next-line
          // @ts-ignore
          'odnoklassniki',
        ],
        padding: 10,
        show_total: false,
        size: 30,
        spacing: 5,
      }}
    />
  );
}

export default Sharing;
