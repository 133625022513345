import React from 'react';
import {useEffect, useState} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {useAppSelector} from '../../core/hooks';
import {store} from '../../core/store';
import {fetchFeedbacks, fetchFeedbacksPage} from '../../core/store/api-actions';
import {
  getFeedbacks, getFeedbacksPage, getFeedbacksPageErrorStatus, getFeedbacksPageLoadingStatus,
} from "../../core/store/feedbacks-data/selectors";
import FeedbackList from "../../components/ordinary/feedback-list/feedback-list";
import Pagination from "../../components/smart/pagination/pagination";
import {FEEDBACKS_PER_PAGE} from "../../core/constants/common";
import {AppRoute} from "../../core/constants/routes";
import Page from "../../components/ordinary/page/page";
import {Link} from "react-router-dom";

store.dispatch(fetchFeedbacks());
store.dispatch(fetchFeedbacksPage());

function Feedbacks() {
  const feedbacks = useAppSelector(getFeedbacks);
  const feedbacksPage = useAppSelector(getFeedbacksPage);
  const isFeedbacksPageLoading = useAppSelector(getFeedbacksPageLoadingStatus);
  const isFeedbacksPageError = useAppSelector(getFeedbacksPageErrorStatus);
  const publicFeedbacks = feedbacks.filter((feedback) => feedback.is_public);
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(publicFeedbacks.length / FEEDBACKS_PER_PAGE);
  const start = (currentPage - 1) * FEEDBACKS_PER_PAGE;
  const end = start + FEEDBACKS_PER_PAGE;
  const currentFeedbacks = publicFeedbacks.slice(start, end);

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Feedbacks]);
  }, [setBreadcrumbs]);

  return (
    <div className="container-center">
      <main>
        <div className="detail">
          <Page content={feedbacksPage?.text} isPageLoading={isFeedbacksPageLoading} isPageError={isFeedbacksPageError}/>
          <FeedbackList currentFeedbacks={currentFeedbacks}/>
          <Pagination currentPage={currentPage} totalPages={totalPages} goToPage={goToPage}/>
        </div>
      </main>
      <aside>
        <h2>
          <Link to={AppRoute.AddFaq.url} className="send_link">Задать вопрос</Link>
        </h2>
      </aside>
    </div>
  );
}

export default Feedbacks;
