import React from "react";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import MatchTeamItem from "../match-team-item/match-team-item";
import { Match } from "../../../core/types/match";
import { formatValue } from '../../../core/utils/common-helpers'

function TournamentMatchesItem({ match,tournament }: { match: Match, tournament:number }) {

  return (
    <li>
      <MatchTeamItem team={match.team1} />
      <MatchTeamItem team={match.team2} />
      <div className="count">
        <Link
          to={`${AppRoute.Tournaments.url}/${tournament}/calendar/${match.match_id}`}
        >
          {formatValue(match.gf)}:{formatValue(match.ga)}
        </Link>
      </div>
    </li>
  );
}

export default TournamentMatchesItem;
