import {RoutesType} from "../types/app-route";

export const AppRoute: RoutesType = {
  Main: {
    title: 'Главная',
    url: '/'
  },
  Tournaments: {
    title: 'Соревнования',
    url: '/tournaments'
  },
  TournamentInfo: {
    title: 'Общая информация',
    url: '/tournaments/:id/info',
  },
  TournamentStatistics: {
    title: 'Статистика турнира',
    url: '/tournaments/:id/statistics',
  },
  TournamentTables: {
    title: 'Турнирные таблицы',
    url: '/tournaments/:id/tables',
  },
  TournamentCalendar: {
    title: 'Календарь и результаты',
    url: '/tournaments/:id/calendar',
  },
  TournamentMatch: {
    title: 'Подробности матча',
    url: '/tournaments/:tournamentId/calendar/:matchId',
  },
  TournamentTeams: {
    title: 'Енисей в турнире',
    url: '/tournaments/:id/teams',
  },
  TournamentTeam: {
    title: 'Статистика команды',
    url: '/tournaments/:tournamentId/teams/:teamId',
  },
  TournamentPlayers: {
    title: 'Игроки Енисея',
    url: '/tournaments/:id/players',
  },
  TournamentPlayer: {
    title: 'Страница игрока',
    url: '/tournaments/:tournamentId/players/:playerId',
  },
  TournamentChess: {
    title: 'Шахматка',
    url: '/tournaments/:id/chess',
  },
  CompletedTournaments: {
    title: 'Завершившиеся соревнования',
    url: '/tournaments/completed/:type'
  },
  Posts: {
    title: 'Новости',
    url: '/posts'
  },
  Post: {
    title: 'Новость',
    url: '/posts/:id'
  },
  Error: {
    title: 'Страница ошибки',
    url: '*'
  },
  Media: {
    title: 'Медиа',
    url: '/media'
  },
  Tables: {
    title: 'Турнирные таблицы',
    url: '/tables',
  },
  PhotoGalleries: {
    title: 'Фотогалерея',
    url: '/media/albums'
  },
  PhotoGallery: {
    title: 'Альбом',
    url: '/media/albums/:id'
  },
  Videos: {
    title: 'Видео',
    url: '/media/videos'
  },
  Translations: {
    title: 'Камеры на стадионе "ЕНИСЕЙ"',
    url: '/media/translations'
  },
  Procurements: {
    title: 'Закупки',
    url: '/procurements'
  },
  Feedbacks: {
    title: 'Вопрос-Ответ',
    url: '/feedbacks'
  },
  MassSkating: {
    title: 'Массовые катания',
    url: 'https://www.хсмшколаенисей.рф/massovye-katanija/'
  },
  School: {
    title: 'Школа',
    url: 'https://www.хсмшколаенисей.рф'
  },
  Contacts: {
    title: 'Контакты',
    url: '/contacts'
  },
  AddFaq: {
    title: 'Добавление вопроса',
    url: '/add-faq'
  },
  AboutClub: {
    title: 'О клубе',
    url: '/club'
  },
  Teams: {
    title: 'Команды',
    url: '/teams'
  },
  Team: {
    title: 'Команда',
    url: '/teams/:id'
  },
  Attributes: {
    title: 'Атрибутика клуба',
    url: '/club/attributes'
  },
  History: {
    title: 'История',
    url: '/club/history'
  },
  Achievements: {
    title: 'Достижения',
    url: '/club/achievements'
  },
  Veterans: {
    title: 'Ветераны',
    url: '/club/veterans'
  },
  SymbolismAnthem: {
    title: 'Символика и гимн',
    url: '/club/symbolism-anthem'
  },
  GoalkeeperSchool: {
    title: 'Школа вратарей',
    url: '/club/goalkeepers-school'
  },
  ClubProcurements: {
    title: 'Закупки',
    url: '/club/club-procurements'
  },
  InternationalMatches: {
    title: 'Международные матчи',
    url: '/club/history/international-matches'
  },
  CountryCups: {
    title: 'В Кубках страны',
    url: '/club/history/country-cups'
  },
  PastPresentFuture: {
    title: 'О прошлом, настоящем и будущем',
    url: '/club/history/country-cups/pastpresentfuture'
  },
  CountryTournaments: {
    title: 'В Чемпионатах страны',
    url: '/club/history/country-tournaments'
  },
  Players: {
    title: 'Игроки',
    url: '/players'
  },
  Player: {
    title: 'Игрок',
    url: '/players/:id'
  },
  Representatives: {
    title: 'Представители',
    url: '/representatives'
  },
  Representative: {
    title: 'Представитель',
    url: '/representatives/:id'
  },
  Calendar: {
    title: 'Календарь и результаты матчей',
    url: '/calendar'
  },
  Programs:{
    title:'Программки к матчам',
    url:'/club/programs'
  },
  SearchPage:{
    title:'Поиск',
    url:'/search-page'
  },
  SeasonsPage:{
    title: 'Страница сезонов',
    url: '/club/goalkeepers-school/chapters/:id',
  },
  PressPage:{
    title: 'Пресса',
    url: '/press',
  },
  PressChaptersPage:{
    title: 'Пресса',
    url: '/press/chapters/:id',
  },
};
