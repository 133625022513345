import React from "react";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchRounds, fetchTournament } from "../../core/store/api-actions";
import "font-awesome/css/font-awesome.min.css";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { AppRoute } from "../../core/constants/routes";
import {
  getTournament,
  getTournamentErrorStatus,
} from "../../core/store/tournaments-data/selectors";
import TournamentChessTable from "../../components/ordinary/tournament-chess-table/tournament-chess-table";
import { getRounds } from "../../core/store/rounds-data/selectors";
import { filterRoundsByHasTable } from '../../core/utils/array-prepare-helpers'

function TournamentChess() {
  const dispatch = useAppDispatch();
  const { id: tournamentId } = useParams();
  const tournament = useAppSelector(getTournament);
  const isTournamentError = useAppSelector(getTournamentErrorStatus);
  const rounds = useAppSelector(getRounds);
  const roundsWithTable = filterRoundsByHasTable(rounds)
  const firstRound = roundsWithTable[0];
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    if (tournamentId) {
      dispatch(fetchTournament(Number(tournamentId)));
      dispatch(fetchRounds(Number(tournamentId)));
    }
  }, [tournamentId, dispatch]);

  useEffect(() => {
    if (tournament) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.Tournaments,
        {
          title: tournament.full_name,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/info`,
        },
        {
          title: AppRoute.TournamentTables.title,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/tables`,
        },
        {
          title: AppRoute.TournamentChess.title,
          url: "#",
        },
      ]);
    }
  }, [tournament]);

  return (
      <div className="container-center" hidden={isTournamentError}>
        <main className="full">
          <div className="stats other">
              <TournamentChessTable round={firstRound} />
          </div>
        </main>
      </div>
  );
}

export default TournamentChess;
