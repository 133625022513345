export enum NameSpace {
  Tournaments = 'TOURNAMENTS',
  Posts = 'POSTS',
  Photos = 'PHOTOS',
  Media = 'MEDIA',
  Videos = 'VIDEOS',
  Procurements = 'PROCUREMENTS',
  Feedbacks = 'FEEDBACKS',
  MassSkating = 'MASS_SKATING',
  CreateFeedback = 'CREATE_FEEDBACK',
  Contacts = 'CONTACTS',
  Teams = 'TEAMS',
  Players = 'PLAYERS',
  PlayerStats = 'PLAYER_STATS',
  TeamPlayers = 'TEAM_PLAYERS',
  Clubs = 'CLUBS',
  Representatives = 'REPRESENTATIVES',
  Matches = 'MATCHES',
  Banners = 'BANNERS',
  Partners = 'PARTNERS',
  Trophies = 'TROPHIES',
  Socials = 'SOCIALS',
  Stadiums = 'STADIUMS',
  TeamsTables = 'TEAMS_TABLES',
  Seasons = 'SEASONS',
  Rounds = 'ROUNDS',
  TeamRounds = 'TEAM_ROUNDS',
  RoundTeams = 'ROUND_TEAMS',
  RoundPlayers = 'ROUND_PLAYERS',
  Chess = 'CHESS',
  Cities = 'CITIES',
  Searches = 'SEARCHES',
  Chapters = 'CHAPTERS',
  Widget = 'WIDGET',
  TeamMatches = 'TEAM_MATCHES',
}

export enum APIRoute {
  Tournaments = '/tournaments',
  Matches = '/matches',
  MatchGoals = '/events/success-goals',
  UnrealizedPenalties = '/events/unrealized-penalties',
  MatchGoalkeepers = '/players/goalkeepers',
  Referees = '/referees',
  Stadiums = '/stadiums',
  Seasons = 'seasons',
  Posts = '/posts',
  PhotoGalleries = '/albums',
  Videos = '/videos',
  MediaPage = '/pages/media',
  Procurements = '/procurements',
  ProcurementsPage = '/pages/procurements',
  Feedbacks = '/feedbacks',
  FeedbacksPage = '/pages/feedbacks',
  MassSkatingPage = '/pages/massskating',
  CreateFeedback = '/feedback/create',
  ContactsPage = '/pages/contacts',
  Teams = '/teams',
  Players = '/players',
  Positions = '/player-positions',
  Clubs = '/clubs',
  Representatives = '/staff',
  ClubAttributesPage = '/pages/clubattributes',
  InternationalMatchesPage = '/pages/internationalmatches',
  CountryCupsPage = '/pages/countrycups',
  PastPresentFuturePage = '/pages/pastpresentfuture',
  CountryTournamentsPage = '/pages/countrytournaments',
  AchievementsPage = '/pages/achievements',
  SymbolismAnthemPage = '/pages/symbolismanthem',
  TeamStats = '/stats/team',
  PlayerStats = '/stats/player',
  TournamentStats = '/stats/tournament',
  Banners = '/banners',
  Partners = '/partners',
  Trophies = '/trophies',
  Socials = '/social',
  Rounds = '/rounds',
  Tables = '/tables',
  Chess = '/chess',
  Cities = '/cities',
  ProgramsPage = 'pages/matchprograms',
  SearchPost = 'search/post',
  GoalkeeperSchoolPage = 'pages/goalkeeperschool',
  Chapter = '/chapter',
  PressPage = '/pages/press'
}
