import React from 'react';
import {MAX_PAGINATION_ITEMS} from '../../../core/constants/common';

function Pagination({currentPage, totalPages, goToPage}: {currentPage: number, totalPages: number, goToPage: (page: number) => void}) {
  const pages = [];
  const middleIndex = Math.floor(MAX_PAGINATION_ITEMS / 2);
  let startIndex = Math.max(1, currentPage - middleIndex);
  const endIndex = Math.min(totalPages, startIndex + MAX_PAGINATION_ITEMS - 1);

  if (endIndex - startIndex + 1 < MAX_PAGINATION_ITEMS) {
    startIndex = Math.max(1, endIndex - MAX_PAGINATION_ITEMS + 1);
  }

  for (let i = startIndex; i <= endIndex; i++) {
    pages.push(i);
  }

  return (
    <div hidden={totalPages < 2} className="navigation">
      <div className="pages-info">Страница {currentPage} из {totalPages}</div>
      <div className="pages-navigation">&nbsp;
        {currentPage > 1 && (
          <a onClick={() => goToPage(currentPage - 1)}>←&nbsp;предыдущая&nbsp;</a>
        )}
        {pages.map((page) => (
          <a
            key={page}
            onClick={() => goToPage(page)}
            className={currentPage === page ? 'active' : ''}
          >
            {page}
          </a>
        ))}
        {currentPage < totalPages && (
          <a onClick={() => goToPage(currentPage + 1)}>следующая&nbsp;→&nbsp;</a>
        )}
      </div>
    </div>
  );
}

export default Pagination;
