import {State} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {ProcurementsType} from '../../types/procurement';
import {PageType} from "../../types/common";

export const getProcurementsLoadingStatus = (state: State): boolean => state[NameSpace.Procurements].isProcurementsLoading;
export const getProcurementsErrorStatus = (state: State): boolean => state[NameSpace.Procurements].isProcurementsError;
export const getProcurements = (state: State): ProcurementsType => state[NameSpace.Procurements].procurements;
export const getProcurementsPageLoadingStatus = (state: State): boolean => state[NameSpace.Procurements].isProcurementsPageLoading;
export const getProcurementsPageErrorStatus = (state: State): boolean => state[NameSpace.Procurements].isProcurementsPageError;
export const getProcurementsPage = (state: State): PageType | null => state[NameSpace.Procurements].procurementsPage;
