import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "../../pages/main/main";
import { RouteType } from "../types/app-route";
import Posts from "../../pages/posts/posts";
import Post from "../../pages/post/post";
import Media from "../../pages/media/media";
import PhotoGalleries from "../../pages/photo-galleries/photo-galleries";
import Videos from "../../pages/videos/videos";
import Error from "../../pages/error/error";
import LiveStreams from "../../pages/live-streams/live-streams";
import PhotoGallery from "../../pages/photo-gallery/photo-gallery";
import { AppRoute } from "../constants/routes";
import Procurements from "../../pages/procurements/procurements";
import Feedbacks from "../../pages/feedbacks/feedbacks";
import MassSkating from "../../pages/mass-skating/mass-skating";
import School from "../../pages/school/school";
import Contacts from "../../pages/contacts/contacts";
import AddFaq from "../../pages/add-faq/add-faq";
import AboutClub from "../../pages/about-club/about-club";
import Team from "../../pages/team/team";
import Attributes from "../../pages/attributes/attributes";
import History from "../../pages/history/history";
import Achievements from "../../pages/achievements/achievements";
import Veterans from "../../pages/veterans/veterans";
import SymbolismAnthem from "../../pages/symbolism-anthem/symbolism-anthem";
import GoalkeeperSchool from "../../pages/goalkeepers-school/goalkeepers-school";
import InternationalMatches from "../../pages/international-matches/international-matches";
import CountryCups from "../../pages/country-cups/country-cups";
import PastPresentFuture from "../../pages/past-present-future/past-present-future";
import CountryTournaments from "../../pages/country-tournaments/country-tournaments";
import ClubProcurements from "../../pages/club-procurements/club-procurements";
import Tournaments from "../../pages/tournaments/tournaments";
import TournamentsCompleted from "../../pages/tournaments-completed/tournaments-completed";
import TournamentInfo from "../../pages/tournament-info/tournament-info";
import TournamentStatistics from "../../pages/tournament-statistics/tournament-statistics";
import TournamentTables from "../../pages/tournament-tables/tournament-tables";
import TournamentCalendar from "../../pages/tournament-calendar/tournament-calendar";
import TournamentTeams from "../../pages/tournament-teams/tournament-teams";
import TournamentPlayers from "../../pages/tournament-players/tournament-players";
import TournamentChess from "../../pages/tournament-chess/tournament-chess";
import TournamentMatch from "../../pages/tournament-match/tournament-match";
import TournamentTeam from "../../pages/tournament-team/tournament-team";
import TournamentPlayer from "../../pages/tournament-player/tournament-player";
import Player from "../../pages/player/player";
import Programs from "../../pages/programs/programs";
import SearchPage from "../../pages/search-page/search-page";
import PressPage from "../../pages/press-page/press-page";
import SeasonsPage from "../../pages/seasons-page/seasons-page";
import PressChaptersPage from '../../pages/press-chapters-page/press-chapters-page'
import StaffPage from '../../pages/staff/staff-page'

function RoutesComponent() {
  const createElementForRoute = (route: RouteType) => {
    switch (route) {
      case AppRoute.Main:
        return <Main />;
      case AppRoute.Tournaments:
        return <Tournaments />;
      case AppRoute.TournamentInfo:
        return <TournamentInfo />;
      case AppRoute.TournamentStatistics:
        return <TournamentStatistics />;
      case AppRoute.TournamentTables:
        return <TournamentTables />;
      case AppRoute.TournamentChess:
        return <TournamentChess />;
      case AppRoute.TournamentCalendar:
        return <TournamentCalendar />;
      case AppRoute.TournamentMatch:
        return <TournamentMatch />;
      case AppRoute.TournamentTeams:
        return <TournamentTeams />;
      case AppRoute.TournamentTeam:
        return <TournamentTeam />;
      case AppRoute.TournamentPlayers:
        return <TournamentPlayers />;
      case AppRoute.TournamentPlayer:
        return <TournamentPlayer />;
      case AppRoute.CompletedTournaments:
        return <TournamentsCompleted />;
      case AppRoute.Posts:
        return <Posts />;
      case AppRoute.Post:
        return <Post />;
      case AppRoute.Error:
        return <Error />;
      case AppRoute.Media:
        return <Media />;
      case AppRoute.PhotoGalleries:
        return <PhotoGalleries />;
      case AppRoute.PhotoGallery:
        return <PhotoGallery />;
      case AppRoute.Videos:
        return <Videos />;
      case AppRoute.Translations:
        return <LiveStreams />;
      case AppRoute.Procurements:
        return <Procurements />;
      case AppRoute.Feedbacks:
        return <Feedbacks />;
      case AppRoute.MassSkating:
        return <MassSkating />;
      case AppRoute.School:
        return <School />;
      case AppRoute.Contacts:
        return <Contacts />;
      case AppRoute.AddFaq:
        return <AddFaq />;
      case AppRoute.AboutClub:
        return <AboutClub />;
      case AppRoute.Team:
        return <Team />;
      case AppRoute.Attributes:
        return <Attributes />;
      case AppRoute.History:
        return <History />;
      case AppRoute.Achievements:
        return <Achievements />;
      case AppRoute.Veterans:
        return <Veterans />;
      case AppRoute.SymbolismAnthem:
        return <SymbolismAnthem />;
      case AppRoute.GoalkeeperSchool:
        return <GoalkeeperSchool />;
      case AppRoute.ClubProcurements:
        return <ClubProcurements />;
      case AppRoute.InternationalMatches:
        return <InternationalMatches />;
      case AppRoute.CountryCups:
        return <CountryCups />;
      case AppRoute.PastPresentFuture:
        return <PastPresentFuture />;
      case AppRoute.CountryTournaments:
        return <CountryTournaments />;
      case AppRoute.Player:
        return <Player />;
      case AppRoute.Programs:
        return <Programs />;
      case AppRoute.SearchPage:
        return <SearchPage />;
      case AppRoute.SeasonsPage:
        return <SeasonsPage />;
      case AppRoute.PressPage:
        return <PressPage />;
      case AppRoute.PressChaptersPage:
        return <PressChaptersPage />;
      case AppRoute.Representative:
        return <StaffPage />;
      default:
        return <Error />;
    }
  };
  return (
    <Routes>
      {Object.values(AppRoute).map((route) => (
        <Route
          key={route.url}
          path={route.url}
          element={createElementForRoute(route)}
        />
      ))}
    </Routes>
  );
}

export default RoutesComponent;
