import React, {
  useEffect,
  useState
} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import VideosList from "../../components/ordinary/videos-list/videos-list";
import Pagination from "../../components/smart/pagination/pagination";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {VIDEOS_PER_PAGE} from "../../core/constants/common";
import Loader from "../../components/ordinary/loader/loader";
import Error from "../error/error";
import {
  getVideos,
  getVideosErrorStatus,
  getVideosLoadingStatus
} from "../../core/store/videos-data/selectors";
import {fetchVideos} from "../../core/store/api-actions";
import { sortItemsByPublicationDate } from '../../core/utils/array-prepare-helpers'

function Videos() {
  const dispatch = useAppDispatch();
  const videos = useAppSelector(getVideos);
  const sortedVideos = sortItemsByPublicationDate(videos)
  const isVideosLoading = useAppSelector(getVideosLoadingStatus);
  const isVideosError = useAppSelector(getVideosErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(videos.length / VIDEOS_PER_PAGE);
  const start = (currentPage - 1) * VIDEOS_PER_PAGE;
  const end = start + VIDEOS_PER_PAGE;
  const currentVideos = sortedVideos.slice(start, end);
  useEffect(() => {
    dispatch(fetchVideos());
  }, [dispatch]);

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Media, AppRoute.Videos]);
  }, [setBreadcrumbs]);

  return (
    <>
      <div hidden={!isVideosLoading}>
        <Loader/>
      </div>
      <div hidden={!isVideosError}>
        <Error/>
      </div>
      <div className="container-center" hidden={isVideosLoading}>
        <main className="full">
          <VideosList currentVideos={currentVideos}/>
          <Pagination currentPage={currentPage} totalPages={totalPages} goToPage={goToPage}/>
        </main>
      </div>
    </>
  );
}

export default Videos;
