import React from 'react';
import BirthDatesItem from "../birthdates-item/birthdates-item";
import {PlayerItems} from "../../../core/types/player";


function BirthDatesList({filteredPlayers}: {filteredPlayers: PlayerItems}) {
  return (
    <ul className="birthdate">
      {filteredPlayers.map((player) => <BirthDatesItem key={player.player_id} player={player}/>)}
    </ul>
  );
}

export default BirthDatesList;