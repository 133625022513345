import {createSlice} from '@reduxjs/toolkit';
import {NameSpace} from "../../constants/api-constants";
import {fetchSocials} from "../api-actions";
import {SocialsState} from "../../types/state";

const initialState: SocialsState = {
  isSocialsLoading: false,
  isSocialsError: false,
  socials: [],
};

export const socialsData = createSlice({
  name: NameSpace.Socials,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSocials.pending, (state) => {
        state.isSocialsLoading = true;
      })
      .addCase(fetchSocials.fulfilled, (state, action) => {
        state.socials = action.payload;
        state.isSocialsLoading = false;
      })
      .addCase(fetchSocials.rejected, (state) => {
        state.isSocialsError = true;
        state.isSocialsLoading = false;
      })
  }
});
