import React from 'react';
import PostItem from '../post-item/post-item';
import {Posts} from '../../../core/types/post';
import Message from "../message/message";
import Title from "../title/title";

function PostsList({currentPosts, tag, title, url}: {currentPosts: Posts, tag?: string | undefined, title?: string | undefined, url?: string | undefined}) {
  return (
    <>
      <Title title={title} url={url}/>
      <div hidden={currentPosts.length === 0}>
        <ul className="articles-list">
          {currentPosts.map((post) => <PostItem key={post.post_id} post={post} tag={tag}/>)}
        </ul>
      </div>
      <div hidden={currentPosts.length > 0}>
        <Message message="На данный момент у нас нет никаких новостей для вас. Пожалуйста, следите за обновлениями!"/>
      </div>
    </>
  );
}

export default PostsList;
