import React, {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {useAppSelector} from "../../core/hooks";
import {fetchCountryTournamentsPage} from "../../core/store/api-actions";
import {store} from "../../core/store";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import {ClubHistoryNavigations} from "../../core/constants/navigations";
import {getPosts} from "../../core/store/posts-data/selectors";
import {filterPostsByTag} from "../../core/utils/array-prepare-helpers";
import {
  getCountryTournamentsPage,
  getCountryTournamentsPageErrorStatus,
  getCountryTournamentsPageLoadingStatus
} from "../../core/store/clubs-data/selectors";
import Page from "../../components/ordinary/page/page";

store.dispatch(fetchCountryTournamentsPage());

function CountryTournaments() {
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByTag(posts, AppRoute.CountryTournaments.title);
  const countryTournamentsPage = useAppSelector(getCountryTournamentsPage);
  const isCountryTournamentsPageLoading = useAppSelector(getCountryTournamentsPageLoadingStatus);
  const isCountryTournamentsPageError = useAppSelector(getCountryTournamentsPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.History, AppRoute.CountryTournaments]);
  }, [setBreadcrumbs]);

  return (
    <div className="container-center">
      <main>
        <Page content={countryTournamentsPage?.text} isPageLoading={isCountryTournamentsPageLoading} isPageError={isCountryTournamentsPageError}/>
        <div className="block-press other">
          <div className="container-center">
            <PostsList currentPosts={filteredPosts} tag={AppRoute.CountryTournaments.title}/>
          </div>
        </div>
      </main>
      <aside>
        <SidebarNavigation navigationArray={ClubHistoryNavigations}/>
      </aside>
    </div>
  );
}

export default CountryTournaments;