import { Teams } from "../types/team";
import { HeaderNavigationsType, NavigationsType } from "../types/navigation";
import { Clubs } from "../types/club";
import { AppRoute } from "../constants/routes";

export function addTeamsToNavigation(
  teams: Teams,
  navigation: NavigationsType
) {
  const sortedTeams = teams.slice();
  sortedTeams
    .sort((a, b) => b.team_id - a.team_id)
    .forEach((team) => {
      const exists = navigation.some(
        (nav) =>
          nav.title === team.full_name &&
          nav.url === `${AppRoute.Teams.url}/${team.team_id}`
      );
      if (!exists) {
        navigation.unshift({
          title: team.full_name,
          url: `${AppRoute.Teams.url}/${team.team_id}`,
        });
      }
    });
}

export function addTeamsToSubMenu(
  teams: Teams,
  headerNavigations: HeaderNavigationsType
): HeaderNavigationsType {
  const clubItem = headerNavigations.find((item) => item.title === "Клуб");

  if (clubItem && clubItem.submenu) {
    const existingTeamTitles = clubItem.submenu.map(
      (submenuItem) => submenuItem.title
    );

    const newSubMenuItems: HeaderNavigationsType = teams
      .filter((team) => !existingTeamTitles.includes(team.full_name))
      .map((team) => ({
        title: team.full_name,
        url: `${AppRoute.Teams.url}/${team.team_id}`,
        submenu: null,
      }));

    if (newSubMenuItems.length > 0) {
      clubItem.submenu.unshift(...newSubMenuItems);
    }
  }

  return headerNavigations;
}

export function filterAndFindClub(clubs: Clubs, fullName: string) {
  return clubs.find((club) => club.full_name === fullName);
}

export function formatValue(value: number | null | undefined): number | string {
  return value != null ? value : "-";
}
