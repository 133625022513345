import React from "react";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { Match } from "../../../core/types/match";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import MatchesSliderTeam from "../../smart/matches-slider-team/matches-slider-team";
import { formatValue } from "../../../core/utils/common-helpers";

function MatchesSliderItem({ match }: { match: Match }) {
  const startDate = match && match.start_dt ? new Date(match.start_dt) : null;

  return (
    <li className="matches__item">
      <Link
        className="matches__link"
        to={`${AppRoute.Tournaments.url}/${match?.tournament_id}/calendar/${match?.match_id}`}
      >
        <p className="matches__top">
          {simpleDateFormat(startDate, "dd MMMM, EEEE")}
        </p>
        <div className="matches__content">
          <div className="matches__teams">
            <MatchesSliderTeam teamId={match.team1_id} />
            <MatchesSliderTeam teamId={match.team2_id} />
          </div>
          <div className="matches__points">
            <p className="matches__point">{formatValue(match?.gf)}</p>
            <p className="matches__point">{formatValue(match?.ga)}</p>
          </div>
        </div>
      </Link>
    </li>
  );
}

export default MatchesSliderItem;
