import React from 'react';

function AccessibleToggle() {
  return (
    <a className="bvi-open">
      <i className="fa fa-eye" aria-hidden="true"/>
    </a>
  );
}

export default AccessibleToggle;