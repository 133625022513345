import {
  StadiumsState,
  State
} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {
  Stadium,
  StadiumGroups
} from "../../types/stadium";

export const getStadiumLoadingStatus = (state: State): boolean => state[NameSpace.Stadiums].isStadiumLoading;
export const getStadiumErrorStatus = (state: State): StadiumsState['isStadiumError'] => state[NameSpace.Stadiums].isStadiumError;
export const getStadium = (state: State): Stadium | null => state[NameSpace.Stadiums].stadium;
export const getGroupStadiumLoadingStatus = (state: State): boolean => state[NameSpace.Stadiums].isGroupStadiumLoading;
export const getGroupStadiumErrorStatus = (state: State): StadiumsState['isGroupStadiumError'] => state[NameSpace.Stadiums].isGroupStadiumError;
export const getGroupStadium = (state: State): StadiumGroups => state[NameSpace.Stadiums].groupStadium;
