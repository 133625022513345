import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {
  TeamsTables,
  TeamsTablesGroups
} from "../../types/teams-tables";

export const getTeamsTablesLoadingStatus = (state: State): boolean => state[NameSpace.TeamsTables].isTeamsTablesLoading;
export const getTeamsTablesErrorStatus = (state: State): boolean => state[NameSpace.TeamsTables].isTeamsTablesError;
export const getTeamsTables = (state: State): TeamsTables => state[NameSpace.TeamsTables].teamsTables;
export const getTeamsTablesGroupLoading = (state: State): boolean => state[NameSpace.TeamsTables].isTeamsTablesGroupLoading;
export const getTeamsTablesGroupsErrorStatus = (state: State): boolean => state[NameSpace.TeamsTables].isTeamsTablesGroupError;
export const getTeamsTablesGroups = (state: State): TeamsTablesGroups => state[NameSpace.TeamsTables].teamsTablesGroups;