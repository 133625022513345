import React, {useEffect} from "react";
import {PlayerItem} from "../../../core/types/player";
import {fetchPlayerStatsGroup} from "../../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {AppRoute} from "../../../core/constants/routes";
import {
  Link,
  useParams
} from "react-router-dom";
import {findPlayerStatsGroupById} from "../../../core/utils/array-prepare-helpers";
import {getPlayerStatsGroups} from "../../../core/store/player-stats-data/selectors";

function GoalkeepersRow({player}: { player: PlayerItem }) {
  const dispatch = useAppDispatch();
  const {id: tournamentId} = useParams();
  const playersStats = useAppSelector(getPlayerStatsGroups);
  const playerStats =
    playersStats?.length > 0
      ? findPlayerStatsGroupById(playersStats, player.player_id)
      : undefined;

  const playerBirthdate = player.birthday
    ? simpleDateFormat(new Date(player.birthday), "dd.MM.yyyy")
    : "Не указана";
  let missedAverage;

  if (
    playerStats?.stats[0]?.missed_goals !== undefined &&
    playerStats?.stats[0]?.games !== undefined
  ) {
    missedAverage = parseFloat(
      (playerStats.stats[0].missed_goals / playerStats.stats[0].games).toFixed(
        1
      )
    );
  }

  useEffect(() => {
    dispatch(
      fetchPlayerStatsGroup({
        playerId: player.player_id,
        groupBy: "tournament_id",
        tournamentId: Number(tournamentId),
      })
    );
  }, [player.player_id, tournamentId]);

  if (!playersStats) {
    return null;
  }

  return (
    <tr>
      <td>{player.number}</td>
      <td>
        <div style={{textAlign: "left"}}>
          <Link
            to={`${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Players.url}/${player.player_id}`}
          >
            {player.last_name} {player.first_name}
          </Link>
        </div>
      </td>
      <td>{playerBirthdate}</td>
      <td>{playerStats?.stats[0]?.games ?? 0}</td>
      <td>{playerStats?.stats[0]?.missed_goals ?? 0}</td>
      <td>{missedAverage ?? 0}</td>
    </tr>
  );
}

export default GoalkeepersRow;
