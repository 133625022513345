import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import React, {useEffect} from "react";
import {fetchGroupStadium} from "../../../core/store/api-actions";
import {getGroupStadium} from "../../../core/store/stadiums-data/selectors";
import {findById} from "../../../core/utils/array-prepare-helpers";

function MatchTabsStadium({stadiumId}: { stadiumId?: number }) {
  const dispatch = useAppDispatch();
  const stadiumGroups = useAppSelector(getGroupStadium);
  const stadiumGroup = findById(stadiumGroups, stadiumId);
  const address = stadiumGroup?.stadium.address;
  const region = address?.split(',')[0];

  useEffect(() => {
    if (stadiumId) {
      dispatch(fetchGroupStadium(stadiumId));
    }
  }, [stadiumId, dispatch])

  if (stadiumGroup === undefined) {
    return null;
  }

  return (
    <>{`${region}. ${stadiumGroup?.stadium.name}`}</>
  );
}

export default MatchTabsStadium;