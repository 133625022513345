import React from "react";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchRepresentative } from "../../core/store/api-actions";
import Loader from "../../components/ordinary/loader/loader";
import Error from "../error/error";
import "font-awesome/css/font-awesome.min.css";
import HtmlRenderer from "../../components/smart/html-renderer/html-renderer";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { AppRoute } from "../../core/constants/routes";
import Sharing from "../../components/smart/sharing/sharing";
import {
  getRepresentative,
  getRepresentativeErrorStatus,
  getRepresentativeLoadingStatus,
} from "../../core/store/representatives-data/selectors";
import Fancybox from "../../components/smart/fancybox/fancybox";

function StaffPage() {
  const dispatch = useAppDispatch();
  const { id: staffId } = useParams();
  const staff = useAppSelector(getRepresentative);
  const isRepresentativeError = useAppSelector(getRepresentativeErrorStatus);
  const isRepresentativeLoading = useAppSelector(
    getRepresentativeLoadingStatus
  );
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchRepresentative(Number(staffId)));
  }, [staffId]);

  useEffect(() => {
    if (staff) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.AboutClub,
        {
          title: `${staff.first_name} ${staff.middle_name} ${staff.last_name}`,
          url: "#",
        },
      ]);
    }
  }, [staff]);

  return (
    <>
      <div hidden={!isRepresentativeLoading}>
        <Loader />
      </div>
      <div hidden={!isRepresentativeError}>
        <Error />
      </div>
      <div className="container-center" hidden={isRepresentativeLoading}>
        <div className="player" style={{alignItems:"center", gap:"0px"}}>
          <div className="img">
            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
              }}
            >
              <a data-fancybox="gallery" href={staff?._links.photo.href}>
                <img
                  src={staff?._links.photo.href}
                  width="560"
                  height="560"
                  alt={staff?.position}
                />
              </a>
            </Fancybox>
          </div>
          <div className="detail">
            <div className="easy-text">
              <HtmlRenderer htmlString={staff?.biography || ""} />
              <p hidden={staff?.biography !== null}>Информации нет</p>
            </div>
            <Sharing />
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffPage;
