import React, {useState} from 'react';
import {Video} from '../../../core/types/media';
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";
import Modal from "react-modal";

function VideoItem({video} : {video: Video}) {
  const videoDate = new  Date(video.published_dt)
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <li>
      <a onClick={openModal}>
        <div className="preview">
          <img src={video.previewUrl} alt={video.title}/>
          <i className="fa fa-play-circle-o" aria-hidden="true"/>
        </div>
        <div className="title">{video.title}</div>
        <div className="addit">{simpleDateFormat(videoDate, 'dd.MM.yyyy')}</div>
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Видео"
      >
        <div className="video-modal">
          <a className="video-modal__closer" onClick={closeModal}>
            <i className="video-modal__icon fa fa-times" aria-hidden="true"/>
          </a>
          <HtmlRenderer htmlString={video?.code || ''}/>
        </div>
      </Modal>
    </li>
  );
}

export default VideoItem;