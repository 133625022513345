import React from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {fetchPost} from '../../core/store/api-actions';
import {
  getCurrentPost,
  getPostErrorStatus,
  getPostLoadingStatus
} from '../../core/store/posts-data/selectors';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import 'font-awesome/css/font-awesome.min.css';
import HtmlRenderer from '../../components/smart/html-renderer/html-renderer';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import Fancybox from '../../components/smart/fancybox/fancybox';
import {AppRoute} from "../../core/constants/routes";
import {simpleDateFormat} from "../../core/utils/date-time-helpers";
import OtherNewsSidebar from "../../components/ordinary/other-news-sidebar/other-news-sidebar";
import Sharing from "../../components/smart/sharing/sharing";

function Post() {
  const dispatch = useAppDispatch();
  const {id: postId} = useParams();
  const currentPost = useAppSelector(getCurrentPost);
  const isPostError = useAppSelector(getPostErrorStatus);
  const isPostDataLoading = useAppSelector(getPostLoadingStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const postDate = currentPost && currentPost.published_dt ? new Date(currentPost.published_dt) : null;

  useEffect(() => {
    dispatch(fetchPost(Number(postId)));
  }, [postId]);

  useEffect(() => {
    if (currentPost) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.Posts,
        {title: currentPost.title, url: AppRoute.Posts.url + String(currentPost.post_id)}
      ]);
    }
  }, [currentPost]);

  return (
    <>
      <div hidden={!isPostDataLoading}>
        <Loader/>
      </div>
      <div hidden={!isPostError}>
        <Error/>
      </div>
      <div className="container-center" hidden={isPostDataLoading}>
        <main>
          <div className="detail">
            <div className="img">
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <a data-fancybox="gallery" href={currentPost?._links.image870x490.href}>
                  <img src={currentPost?._links.image870x490.href} width="420" height="420" alt={currentPost?.title}/>
                </a>
              </Fancybox>
            </div>
            <div className="addit-info">
              <span><i className="fa fa-clock-o" aria-hidden="true"/> {simpleDateFormat(postDate, 'd MMMM yyyy года')}</span>
            </div>
            <div className="easy-text">
              <HtmlRenderer htmlString={currentPost?.text || ''}/>
              <p hidden={currentPost?.text !== null}>Информации нет</p>
            </div>
            <Sharing/>
          </div>
        </main>
        <OtherNewsSidebar postId={postId}/>
      </div>
    </>
  );
}

export default Post;
