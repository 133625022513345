import {createSlice} from '@reduxjs/toolkit';
import {fetchPost, fetchPosts} from '../api-actions';
import {PostsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: PostsState = {
  isPostsLoading: false,
  isPostLoading: false,
  isPostsError: false,
  isPostError: false,
  posts: [],
  currentPost: null,
};

export const postsData = createSlice({
  name: NameSpace.Posts,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.isPostsLoading = true;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.posts = action.payload;
        state.isPostsLoading = false;
      })
      .addCase(fetchPosts.rejected, (state) => {
        state.isPostsError = true;
        state.isPostsLoading = false;
      })
      .addCase(fetchPost.pending, (state) => {
        state.isPostLoading = true;
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        state.currentPost = action.payload;
        state.isPostLoading = false;
      })
      .addCase(fetchPost.rejected, (state) => {
        state.isPostError = true;
        state.isPostLoading = false;
      });
  }
});
