import React, {useEffect} from "react";
import Title from "../../ordinary/title/title";
import Filters from "../../smart/filters/filters";
import TablesWidgetHead from "../tables-widget-head/tables-widget-head";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getTeamsTables,
  getTeamsTablesLoadingStatus
} from "../../../core/store/teams-tables-data/selectors";
import {
  findTeamById,
  sortItemsByPoints
} from "../../../core/utils/array-prepare-helpers";
import {
  getTournamentTeams,
  getTournamentTeamsLoadingStatus} from "../../../core/store/teams-data/selectors";
import TablesWidgetRow from "../../ordinary/tables-widget-row/tables-widget-row";
import LinkFull from "../../ordinary/link-full/link-full";
import {getWidgetRoundFilter} from "../../../core/store/widget-data/selectors";
import {
  fetchTeamsTables,
  fetchTournamentTeams
} from "../../../core/store/api-actions";
import {resetTeamsTables} from "../../../core/store/teams-tables-data/teams-tables-data";
import {AppRoute} from "../../../core/constants/routes";
import Loader from "../../ordinary/loader/loader";
import {getTeamRoundsLoadingStatus} from "../../../core/store/team-rounds-data/selectors";

function TablesWidget() {
  const dispatch = useAppDispatch();
  const teamsTables = useAppSelector(getTeamsTables);
  const sortedTeamTables = sortItemsByPoints(teamsTables);
  const teamsTablesLoading = useAppSelector(getTeamsTablesLoadingStatus);
  const roundTeams = useAppSelector(getTournamentTeams);
  const roundTeamsLoading = useAppSelector(getTournamentTeamsLoadingStatus);
  const widgetRoundFilter = useAppSelector(getWidgetRoundFilter);
  const teamRoundsLoading = useAppSelector(getTeamRoundsLoadingStatus);
  const tablesWidgetLoading = teamsTablesLoading || roundTeamsLoading || teamRoundsLoading;

  useEffect(() => {
    if (widgetRoundFilter !== null) {
      dispatch(fetchTournamentTeams(widgetRoundFilter.value.tournament_id));
      dispatch(fetchTeamsTables({
        tournamentId: widgetRoundFilter.value.tournament_id,
        roundId: widgetRoundFilter.value.round_id
      }))
    } else {
      dispatch(resetTeamsTables());
    }
  }, [dispatch, widgetRoundFilter])

  return (
    <div className="tables__item">
      <Title title="Турнирные таблицы"/>
      <Filters/>
      <Loader hidden={!tablesWidgetLoading}/>
      <table className="stat-table" hidden={tablesWidgetLoading || teamsTables.length === 0}>
        <TablesWidgetHead/>
        <tbody>
        {sortedTeamTables.slice(0, 6).sort((a, b) => b.points - a.points).map((tableTeam, index) => (
          <TablesWidgetRow
            key={tableTeam.team_id}
            team={findTeamById(roundTeams, tableTeam.team_id)}
            tableTeam={tableTeam}
            index={index}
          />
        ))}
        </tbody>
      </table>
      <div className="easy-text" hidden={tablesWidgetLoading || teamsTables.length > 0}>
        <br/>
        <br/>
        <p>По заданным параметрам данные отсутствуют</p>
      </div>
      <LinkFull
        title="Показать полностью"
        url={`${AppRoute.Tournaments.url}/${widgetRoundFilter?.value.tournament_id}${AppRoute.Tables.url}`}
        hidden={widgetRoundFilter === null || teamsTables.length < 6}
      />
    </div>
  );
}

export default TablesWidget;
