import {
  createSlice
} from '@reduxjs/toolkit';
import {
  fetchPlayerStatsGroup,
} from '../api-actions';
import {PlayerStatsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: PlayerStatsState = {
  playerStatsGroupLoading: false,
  playerStatsGroupError: false,
  playerStatsGroups: [],
};

export const playerStatsData = createSlice({
  name: NameSpace.PlayerStats,
  initialState,
  reducers: {
    resetPlayerStatsGroups: (
      state
    ) => {
      state.playerStatsGroups = initialState.playerStatsGroups;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPlayerStatsGroup.pending, (state) => {
        state.playerStatsGroupLoading = true;
      })
      .addCase(fetchPlayerStatsGroup.fulfilled, (state, action) => {
        const existingPlayerStatsGroups = state.playerStatsGroups.find(playerStatsGroup => playerStatsGroup.playerId === action.payload.playerId);
        if (!existingPlayerStatsGroups) {
          state.playerStatsGroups.push(action.payload);
        }
        state.playerStatsGroupLoading = false;
      })
      .addCase(fetchPlayerStatsGroup.rejected, (state) => {
        state.playerStatsGroupError = true;
        state.playerStatsGroupLoading = false;
      })
  }
});

export const {resetPlayerStatsGroups} = playerStatsData.actions