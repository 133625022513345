import React, { useEffect, useState } from "react";
import MatchTabs from "../../ordinary/match-tabs/match-tabs";
import MatchesSlider from "../../ordinary/matches-slider/matches-slider";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { fetchTeamMatches } from "../../../core/store/api-actions";
import { filterMatchesForSlider, getLastMatches } from "../../../core/utils/array-prepare-helpers";
import {
  getTeamMatchesErrorStatus,
  getTeamMatchesLoadingStatus,
} from "../../../core/store/matches-data/selectors";
import Loader from "../../ordinary/loader/loader";
import { Matches } from "../../../core/types/match";
import { TEAM_IDS } from "../../../core/constants/common";
import { TabType } from "../../../core/types/common";

function MatchWidget() {
  const dispatch = useAppDispatch();
  const isTeamMatchesLoading = useAppSelector(getTeamMatchesLoadingStatus);
  const isTeamMatchesError = useAppSelector(getTeamMatchesErrorStatus);
  const matchWidgetLoading = isTeamMatchesLoading || isTeamMatchesError;

  const [matchesTeam1, setMatchesTeam1] = useState<Matches>([]);
  const [matchesTeam2, setMatchesTeam2] = useState<Matches>([]);
  const [selectedTab, setSelectedTab] = useState<TabType>("yenisey");
  const [lastFiveMatches, setLastFiveMatches] = useState<Matches>([]);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await dispatch(fetchTeamMatches(TEAM_IDS.YENISEY));
        setMatchesTeam1(response.payload as Matches);
      } catch (error) {
        console.error("Ошибка получения матчей Енисея:", error);
      }

      try {
        const response = await dispatch(fetchTeamMatches(TEAM_IDS.YENISEY_2));
        setMatchesTeam2(response.payload as Matches);
      } catch (error) {
        console.error("Ошибка получения матчей Енисея-2:", error);
      }
    };

    fetchMatches();
  }, [dispatch]);

  useEffect(() => {
    const updateLastFiveMatches = () => {
      const teamMatches =
        selectedTab === "yenisey" ? matchesTeam1 : matchesTeam2;
        if (teamMatches && teamMatches.length > 0) {
          const filteredMatches = filterMatchesForSlider(teamMatches, teamMatches.slice(-3));
          setLastFiveMatches(getLastMatches(filteredMatches, 5));
        }
    };

    updateLastFiveMatches();
  }, [selectedTab, matchesTeam1, matchesTeam2]);

  const handleTabChange = (newTab: TabType) => {
    setSelectedTab(newTab);
  };
  return (
    <>
      <Loader hidden={!matchWidgetLoading} />
      <div
        hidden={
          matchesTeam1?.length === 0 ||
          matchesTeam2?.length === 0 ||
          isTeamMatchesError
        }
      >
        <MatchTabs
          matchesTeam1={matchesTeam1}
          matchesTeam2={matchesTeam2}
          selectedTab={selectedTab}
          onTabChange={handleTabChange}
        />
        <MatchesSlider matches={lastFiveMatches} />
      </div>
    </>
  );
}

export default MatchWidget;
