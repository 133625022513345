import React, {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import Message from "../../components/ordinary/message/message";

function LiveStreams() {
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Media, AppRoute.Translations]);
  }, [setBreadcrumbs]);

  return (
    <main className="full">
      <div className="container-center">
        <Message message="Страница Камеры"/>
      </div>
    </main>
  );
}

export default LiveStreams;
