import {
  PlayersState,
  State
} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {
  PlayerGroups,
  PlayerItem,
  PlayerItems,
  Players,
  Position,
} from "../../types/player";
import {Matches} from "../../types/match";

export const getAllPlayersLoadingStatus = (state: State): boolean => state[NameSpace.Players].isAllPlayersLoading;
export const getAllPlayersErrorStatus = (state: State): PlayersState['isAllPlayersError'] => state[NameSpace.Players].isAllPlayersError;
export const getAllPlayers = (state: State): Players => state[NameSpace.Players].allPlayers;
export const getWidgetPlayers = (state: State): PlayerItems => state[NameSpace.Players].widgetPlayers;
export const getPlayerLoadingStatus = (state: State): boolean => state[NameSpace.Players].isPlayerLoading;
export const getPlayerErrorStatus = (state: State): PlayersState['isPlayerError'] => state[NameSpace.Players].isPlayerError;
export const getPlayer = (state: State): PlayerItem | null => state[NameSpace.Players].player;
export const getPositionLoadingStatus = (state: State): boolean => state[NameSpace.Players].isPositionLoading;
export const getPositionErrorStatus = (state: State): PlayersState['isPositionError'] => state[NameSpace.Players].isPositionError;
export const getPosition = (state: State): Position | null => state[NameSpace.Players].position;
export const getGroupPlayerLoadingStatus = (state: State): boolean => state[NameSpace.Players].isGroupPlayerLoading;
export const getGroupPlayerErrorStatus = (state: State): PlayersState['isGroupPlayerError'] => state[NameSpace.Players].isGroupPlayerError;
export const getGroupPlayer = (state: State): PlayerGroups => state[NameSpace.Players].groupPlayer;
export const getMatchPlayersLoadingStatus = (state: State): boolean => state[NameSpace.Players].isMatchPlayersLoading;
export const getMatchPlayersErrorStatus = (state: State): PlayersState['isMatchPlayersError'] => state[NameSpace.Players].isMatchPlayersError;
export const getMatchPlayers = (state: State): Players => state[NameSpace.Players].matchPlayers;
export const getPlayerMatchesLoadingStatus = (state: State): boolean => state[NameSpace.Players].isPlayerMatchesLoading;
export const getPlayerMatchesErrorStatus = (state: State): PlayersState['isPlayerMatchesError'] => state[NameSpace.Players].isPlayerMatchesError;
export const getPlayerMatches = (state: State): Matches => state[NameSpace.Players].playerMatches;