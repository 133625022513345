import {createSlice} from '@reduxjs/toolkit';
import {fetchProcurements, fetchProcurementsPage} from '../api-actions';
import {ProcurementsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: ProcurementsState = {
  isProcurementsLoading: false,
  isProcurementsError: false,
  procurements: [],
  isProcurementsPageLoading: false,
  isProcurementsPageError: false,
  procurementsPage: null
};

export const procurementsData = createSlice({
  name: NameSpace.Procurements,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProcurements.pending, (state) => {
        state.isProcurementsLoading = true;
      })
      .addCase(fetchProcurements.fulfilled, (state, action) => {
        state.procurements = action.payload;
        state.isProcurementsLoading = false;
      })
      .addCase(fetchProcurements.rejected, (state) => {
        state.isProcurementsError = true;
        state.isProcurementsLoading = false;
      })
      .addCase(fetchProcurementsPage.pending, (state) => {
        state.isProcurementsPageLoading = true;
      })
      .addCase(fetchProcurementsPage.fulfilled, (state, action) => {
        state.procurementsPage = action.payload;
        state.isProcurementsPageLoading = false;
      })
      .addCase(fetchProcurementsPage.rejected, (state) => {
        state.isProcurementsPageError = true;
        state.isProcurementsPageLoading = false;
      })
  }
});
