import React from 'react';
import {AppRoute} from "../../../core/constants/routes";
import {
  filterPostsByAchievements,
  filterPostsByTag,
  filterTeamsByClubId
} from "../../../core/utils/array-prepare-helpers";
import PostsList from "../posts-list/posts-list";
import TeamsList from "../teams-list/teams-list";
import {useAppSelector} from "../../../core/hooks";
import {getTeams, getTeamsErrorStatus, getTeamsLoadingStatus} from "../../../core/store/teams-data/selectors";
import {getPosts} from "../../../core/store/posts-data/selectors";
import {getClubs} from "../../../core/store/clubs-data/selectors";
import {filterAndFindClub} from "../../../core/utils/common-helpers";

function ClubContent() {
  const clubs = useAppSelector(getClubs);
  const allTeams = useAppSelector(getTeams);
  const mainClub = filterAndFindClub(clubs, 'Енисей');
  const teams = filterTeamsByClubId(allTeams, mainClub?.club_id)
  const isTeamsLoading = useAppSelector(getTeamsLoadingStatus);
  const isTeamsError = useAppSelector(getTeamsErrorStatus);
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByAchievements(posts);

  return (
    <>
      <TeamsList teams={teams} isTeamsLoading={isTeamsLoading} isTeamsError={isTeamsError}/>
      <PostsList currentPosts={filterPostsByTag(filteredPosts, AppRoute.History.title)} title={AppRoute.History.title} url={AppRoute.History.url}/>
      <PostsList currentPosts={filterPostsByTag(filteredPosts, AppRoute.Achievements.title)} title={AppRoute.Achievements.title} url={AppRoute.Achievements.url}/>
      <PostsList currentPosts={filterPostsByTag(filteredPosts, AppRoute.Veterans.title)} title={AppRoute.Veterans.title} url={AppRoute.Veterans.url}/>
      <PostsList currentPosts={filterPostsByTag(filteredPosts, AppRoute.SymbolismAnthem.title)} title={AppRoute.SymbolismAnthem.title} url={AppRoute.SymbolismAnthem.url}/>
      <PostsList currentPosts={filterPostsByTag(filteredPosts, AppRoute.GoalkeeperSchool.title)} title={AppRoute.GoalkeeperSchool.title} url={AppRoute.GoalkeeperSchool.url}/>
      <PostsList currentPosts={filterPostsByTag(filteredPosts, AppRoute.ClubProcurements.title)} title={AppRoute.ClubProcurements.title} url={AppRoute.ClubProcurements.url}/></>
  );
}

export default ClubContent;
