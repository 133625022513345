import React, {useEffect} from 'react';
import BirthDatesList from "../birthdates-list/birthdates-list";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {PlayerItems} from "../../../core/types/player";
import {fetchTeamPlayersGroup} from "../../../core/store/api-actions";
import {filterPlayersWithBirthdayInCurrentWeek} from "../../../core/utils/array-prepare-helpers";
import {
  getTeamPlayersGroupLoading,
  getTeamPlayersGroups
} from "../../../core/store/team-players-data/selectors";
import Loader from "../loader/loader";

function BirthDatesWidget() {
  const teamPlayersGroups = useAppSelector(getTeamPlayersGroups);
  const teamPlayersGroupsLoading = useAppSelector(getTeamPlayersGroupLoading);

  const allPlayers: PlayerItems = [];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTeamPlayersGroup(1));
    dispatch(fetchTeamPlayersGroup(2));
  }, [dispatch]);

  for (const teamPlayer of teamPlayersGroups) {
    if (teamPlayer.players) {
      for (const player of teamPlayer.players) {
        allPlayers.push(player);
      }
    }
  }

  const filteredPlayers = filterPlayersWithBirthdayInCurrentWeek(allPlayers);

  return (
    <div className="block-video main" hidden={filteredPlayers.length === 0}>
      <Loader hidden={!teamPlayersGroupsLoading}/>
      <div className="container-center" hidden={teamPlayersGroupsLoading}>
        <h2 className="title-h white">Дни рождения</h2>
        <BirthDatesList filteredPlayers={filteredPlayers}/>
      </div>
    </div>
  );
}

export default BirthDatesWidget;