import {BannersState, State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {Banner, Banners} from "../../types/banner";

export const getBannersLoadingStatus = (state: State): boolean => state[NameSpace.Banners].isBannersLoading;
export const getBannersErrorStatus = (state: State): BannersState['isBannersError'] => state[NameSpace.Banners].isBannersError;
export const getBanners = (state: State): Banners => state[NameSpace.Banners].banners;
export const getBannerLoadingStatus = (state: State): boolean => state[NameSpace.Banners].isBannerLoading;
export const getBannerErrorStatus = (state: State): BannersState['isBannerError'] => state[NameSpace.Banners].isBannerError;
export const getBanner = (state: State): Banner | null => state[NameSpace.Banners].banner;
