import {createSlice} from '@reduxjs/toolkit';
import {fetchChess} from '../api-actions';
import {ChessState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: ChessState = {
  chessLoading: false,
  chessError: false,
  chess: null,
};

export const chessData = createSlice({
  name: NameSpace.Chess,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchChess.pending, (state) => {
        state.chessLoading = true;
      })
      .addCase(fetchChess.fulfilled, (state, action) => {
        state.chess = action.payload;
        state.chessLoading = false;
      })
      .addCase(fetchChess.rejected, (state) => {
        state.chessError = true;
        state.chessLoading = false;
      })
  }
});
