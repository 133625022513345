import React, {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {useAppSelector} from "../../core/hooks";
import {fetchInternationalMatchesPage} from "../../core/store/api-actions";
import {store} from "../../core/store";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import {ClubHistoryNavigations} from "../../core/constants/navigations";
import {getPosts} from "../../core/store/posts-data/selectors";
import {filterPostsByTag} from "../../core/utils/array-prepare-helpers";
import {
  getInternationalMatchesPage,
  getInternationalMatchesPageErrorStatus,
  getInternationalMatchesPageLoadingStatus
} from "../../core/store/clubs-data/selectors";
import Page from "../../components/ordinary/page/page";

store.dispatch(fetchInternationalMatchesPage());

function InternationalMatches() {
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByTag(posts, AppRoute.InternationalMatches.title);
  const internationalMatchesPage = useAppSelector(getInternationalMatchesPage);
  const isInternationalMatchesPageLoading = useAppSelector(getInternationalMatchesPageLoadingStatus);
  const isInternationalMatchesPageError = useAppSelector(getInternationalMatchesPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.History, AppRoute.InternationalMatches]);
  }, [setBreadcrumbs]);

  return (
    <div className="container-center">
      <main>
        <Page content={internationalMatchesPage?.text} isPageLoading={isInternationalMatchesPageLoading} isPageError={isInternationalMatchesPageError}/>
        <div className="block-press other">
          <div className="container-center">
            <PostsList currentPosts={filteredPosts} tag={AppRoute.InternationalMatches.title}/>
          </div>
        </div>
      </main>
      <aside>
        <SidebarNavigation navigationArray={ClubHistoryNavigations}/>
      </aside>
    </div>
  );
}

export default InternationalMatches;