import React, {useEffect} from 'react';
import Page from "../../components/ordinary/page/page";
import {AppRoute} from "../../core/constants/routes";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import {ClubNavigations} from "../../core/constants/navigations";
import {useAppSelector} from "../../core/hooks";
import {useBreadcrumbsContext} from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import {
  getSymbolismAnthemPage,
  getSymbolismAnthemPageErrorStatus,
  getSymbolismAnthemPageLoadingStatus
} from "../../core/store/clubs-data/selectors";
import {store} from "../../core/store";
import {fetchSymbolismAnthemPage} from "../../core/store/api-actions";
import ClubContent from "../../components/ordinary/club-content/club-content";

store.dispatch(fetchSymbolismAnthemPage());

function SymbolismAnthem() {
  const symbolismAnthemPage = useAppSelector(getSymbolismAnthemPage);
  const isSymbolismAnthemPageLoading = useAppSelector(getSymbolismAnthemPageLoadingStatus);
  const isSymbolismAnthemPageError = useAppSelector(getSymbolismAnthemPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.SymbolismAnthem]);
  }, []);

  return (
    <div className="container-center">
      <main>
        <Page content={symbolismAnthemPage?.text} isPageLoading={isSymbolismAnthemPageLoading} isPageError={isSymbolismAnthemPageError}/>
        <ClubContent/>
      </main>
      <aside>
        <SidebarNavigation navigationArray={ClubNavigations}/>
      </aside>
    </div>
  );
}

export default SymbolismAnthem;