import {
  MatchesState,
  State
} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {
  Match,
  Matches,
  MatchGoals,
  Referees
} from "../../types/match";
import {PlayerItems} from "../../types/player";
import { createSelector } from '@reduxjs/toolkit'

export const getMatchesLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isMatchesLoading;
export const getMatchLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isMatchLoading;
export const getMatchesErrorStatus = (state: State): MatchesState['isMatchesError'] => state[NameSpace.Matches].isMatchesError;
export const getMatchErrorStatus = (state: State): MatchesState['isMatchError'] => state[NameSpace.Matches].isMatchError;
export const getMatches = (state: State): Matches => state[NameSpace.Matches].matches;
export const getMatch = (state: State): Match | null => state[NameSpace.Matches].match;
export const getTournamentMatchesLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isTournamentMatchesLoading;
export const getTournamentMatchesErrorStatus = (state: State): MatchesState['isTournamentMatchesError'] => state[NameSpace.Matches].isTournamentMatchesError;
export const getTournamentMatches = (state: State): Matches => state[NameSpace.Matches].tournamentMatches;
export const getMatchGoalsLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isMatchGoalsLoading;
export const getMatchGoalsErrorStatus = (state: State): MatchesState['isMatchGoalsError'] => state[NameSpace.Matches].isMatchGoalsError;
export const getMatchGoals = (state: State): MatchGoals => state[NameSpace.Matches].matchGoals;
export const getMatchGoalkeepersLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isMatchGoalkeepersLoading;
export const getMatchGoalkeepersErrorStatus = (state: State): MatchesState['isMatchGoalkeepersError'] => state[NameSpace.Matches].isMatchGoalkeepersError;
export const getMatchGoalkeepers = (state: State): PlayerItems => state[NameSpace.Matches].matchGoalkeepers;
export const getMatchRefereesLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isMatchRefereesLoading;
export const getMatchRefereesErrorStatus = (state: State): MatchesState['isMatchRefereesError'] => state[NameSpace.Matches].isMatchRefereesError;
export const getMatchReferees = (state: State): Referees => state[NameSpace.Matches].matchReferees;
export const getUnrealizedPenaltiesLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isUnrealizedPenaltiesLoading;
export const getUnrealizedPenaltiesErrorStatus = (state: State): MatchesState['isUnrealizedPenaltiesError'] => state[NameSpace.Matches].isUnrealizedPenaltiesError;
export const getUnrealizedPenalties = (state: State): MatchGoals => state[NameSpace.Matches].unrealizedPenalties;
export const getPlayerMatchesLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isPlayerMatchesLoading;
export const getPlayerMatchesErrorStatus = (state: State): MatchesState['isPlayerMatchesError'] => state[NameSpace.Matches].isPlayerMatchesError;
export const getPlayerMatches = (state: State): Matches => state[NameSpace.Matches].playerMatches;
export const getTeamMatchesLoadingStatus = (state: State): boolean => state[NameSpace.Matches].isTeamMatchesLoading;
export const getTeamMatchesErrorStatus = (state: State): MatchesState['isTeamMatchesError'] => state[NameSpace.Matches].isTeamMatchesError;
export const getTeamMatches = (state: State): Matches => state[NameSpace.Matches].teamMatches;
export const getTeamMatchesForTeamId = (teamId: number) =>
  createSelector(
    (state: State) => state[NameSpace.Matches].teamMatches[teamId] || [],
    teamMatches => teamMatches
  );
