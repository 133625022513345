import React, {useState} from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {
  fetchTeamPlayersGroup,
  fetchRepresentatives,
  fetchTeam
} from '../../core/store/api-actions';
import 'font-awesome/css/font-awesome.min.css';
import Loader from "../../components/ordinary/loader/loader";
import Error from "../error/error";
import PagePlayers from "../../components/ordinary/page-players/page-players";
import {
  excludePlayersByPositionId,
  filterPlayersByPositionId,
  filterTeamPlayersByTeamId,
  filterPostsByTag
} from "../../core/utils/array-prepare-helpers";
import {getTeam} from "../../core/store/teams-data/selectors";
import {AppRoute} from "../../core/constants/routes";
import {useBreadcrumbsContext} from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import {
  getRepresentatives,
  getRepresentativesErrorStatus,
  getRepresentativesLoadingStatus
} from "../../core/store/representatives-data/selectors";
import Representatives from "../../components/ordinary/representatives/representatives";
import TeamSidebar from "../../components/smart/team-sidebar/team-sidebar";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import {getPosts} from "../../core/store/posts-data/selectors";
import {PLAYERS_FILTERS} from "../../core/constants/common";
import {
  getTeamPlayersGroupError,
  getTeamPlayersGroupLoading,
  getTeamPlayersGroups
} from "../../core/store/team-players-data/selectors";

function Team() {
  const {id: teamId} = useParams();
  const dispatch = useAppDispatch();
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const players = useAppSelector(getTeamPlayersGroups);
  const isPlayersError = useAppSelector(getTeamPlayersGroupError);
  const isPlayersLoading = useAppSelector(getTeamPlayersGroupLoading);
  const teamPlayersObject = filterTeamPlayersByTeamId(players, Number(teamId));
  const goalKeepers = filterPlayersByPositionId(teamPlayersObject?.players, 1);
  const otherPlayers = excludePlayersByPositionId(teamPlayersObject?.players, 1);
  const team = useAppSelector(getTeam);
  const representatives = useAppSelector(getRepresentatives);
  const isRepresentativesLoading = useAppSelector(getRepresentativesLoadingStatus);
  const isRepresentativesError = useAppSelector(getRepresentativesErrorStatus);
  const posts = useAppSelector(getPosts);
  const [showGoalKeepers, setShowGoalKeepers] = useState(true);
  const [showOtherPlayers, setShowOtherPlayers] = useState(true);
  const [showRepresentatives, setShowRepresentatives] = useState(true);
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const handleSidebarItemClick = (sectionTitle: string) => {
    setActiveSection(sectionTitle);
    if (sectionTitle === PLAYERS_FILTERS.Goalkeepers) {
      setShowGoalKeepers(true);
      setShowOtherPlayers(false);
      setShowRepresentatives(false);
    } else if (sectionTitle === PLAYERS_FILTERS.Players) {
      setShowGoalKeepers(false);
      setShowOtherPlayers(true);
      setShowRepresentatives(false);
    } else if (sectionTitle === PLAYERS_FILTERS.Staff) {
      setShowGoalKeepers(false);
      setShowOtherPlayers(false);
      setShowRepresentatives(true);
    }
  };

  useEffect(() => {
    dispatch(fetchTeamPlayersGroup(Number(teamId)));
    dispatch(fetchTeam(Number(teamId)));
    dispatch(fetchRepresentatives(Number(teamId)));
  }, [teamId]);

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, {
      title: team?.full_name,
      url: `${AppRoute.Teams.url}/${team?.team_id}`
    }]);
  }, [setBreadcrumbs, team]);

  return (
    <>
      <div hidden={!isPlayersLoading || !isRepresentativesLoading}>
        <Loader/>
      </div>
      <div hidden={!isPlayersError || !isRepresentativesError}>
        <Error/>
      </div>
      <div className="container-center" hidden={isPlayersLoading || isRepresentativesLoading}>
        <main>
          {showGoalKeepers && <PagePlayers players={goalKeepers} title={PLAYERS_FILTERS.Goalkeepers}/>}
          {showOtherPlayers && <PagePlayers players={otherPlayers} title={PLAYERS_FILTERS.Players}/>}
          {showRepresentatives && <Representatives representatives={representatives} title={PLAYERS_FILTERS.Staff}/>}
          <PostsList currentPosts={filterPostsByTag(posts, team?.full_name)}/>
        </main>
        <TeamSidebar activeSection={activeSection} onSectionClick={handleSidebarItemClick}/>
      </div>
    </>
  );
}

export default Team;
