import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PhotosSlider from "../photos-slider/photos-slider";
import {AppRoute} from "../../../core/constants/routes";
import Title from "../title/title";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getPhotoGalleries,
  getPhotoGalleriesLoadingStatus
} from "../../../core/store/photos-data/selectors";
import {filterItemsByMain, sortItemsByPublicationDate} from "../../../core/utils/array-prepare-helpers";
import {fetchPhotoGalleries} from "../../../core/store/api-actions";
import Loader from "../loader/loader";

function PhotosWidget() {
  const dispatch = useAppDispatch();
  const photoGalleries = useAppSelector(getPhotoGalleries);
  const photoGalleriesLoading = useAppSelector(getPhotoGalleriesLoadingStatus);
  const filteredPhotoGalleries = filterItemsByMain(photoGalleries);
  const sortedPhotoGalleries = sortItemsByPublicationDate(filteredPhotoGalleries)

  useEffect(() => {
    dispatch(fetchPhotoGalleries());
  }, [dispatch]);

  return (
    <div hidden={sortedPhotoGalleries.length === 0} className="block-press">
      <div className="container-center" hidden={photoGalleriesLoading}>
        <Title title="Фотоальбомы"/>
        <div className="all-news">
          <Link to={AppRoute.PhotoGalleries.url}>Все фото</Link>
        </div>
        <div className="media media--dark">
          <PhotosSlider photos={sortedPhotoGalleries}/>
        </div>
      </div>
      <Loader hidden={!photoGalleriesLoading}/>
    </div>
  );
}

export default PhotosWidget;
