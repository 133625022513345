import {createSlice} from '@reduxjs/toolkit';
import {fetchVideos} from '../api-actions';
import {VideosState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: VideosState = {
  isVideosLoading: false,
  isVideosError: false,
  videos: [],
};

export const videosData = createSlice({
  name: NameSpace.Videos,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.isVideosLoading = true;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.videos = action.payload;
        state.isVideosLoading = false;
      })
      .addCase(fetchVideos.rejected, (state) => {
        state.isVideosError = true;
        state.isVideosLoading= false;
      })
  }
});
