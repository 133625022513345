import React, { useEffect } from "react";
import { getLastTwoMatches } from "../../../core/utils/array-prepare-helpers";
import MatchTabsItem from "../match-tabs-item/match-tabs-item";
import HeaderLogo from "../../simple/header-logo/header-logo";
import { Matches } from "../../../core/types/match";
import TabsList from "../tabs-list/tabs-list";
import { TabType } from "../../../core/types/common";
import { TEAM_IDS } from "../../../core/constants/common";

function MatchTabs({
  matchesTeam1,
  matchesTeam2,
  selectedTab,
  onTabChange,
}: {
  matchesTeam1: Matches;
  matchesTeam2: Matches;
  selectedTab: TabType;
  onTabChange: (newTab: TabType) => void;
}) {
  const getMatchesForTeam = (teamId: number) => {
    if (matchesTeam1?.length === 0 || matchesTeam2?.length === 0) {
      return null;
    }
    const teamMatches =
      teamId === TEAM_IDS.YENISEY ? matchesTeam1 : matchesTeam2;
    return getLastTwoMatches(teamMatches);
  };

  const firstYeniseyMatch = getMatchesForTeam(TEAM_IDS.YENISEY)?.[0];
  const secondYeniseyMatch = getMatchesForTeam(TEAM_IDS.YENISEY)?.[1];
  const firstYenisey2Match = getMatchesForTeam(TEAM_IDS.YENISEY_2)?.[0];
  const secondYenisey2Match = getMatchesForTeam(TEAM_IDS.YENISEY_2)?.[1];

  const hasYeniseyMatches =
    firstYeniseyMatch !== null || secondYeniseyMatch !== null;
  const hasYenisey2Matches =
    firstYenisey2Match !== null || secondYenisey2Match !== null;

  useEffect(() => {
    if (selectedTab === "yenisey" && !hasYeniseyMatches && hasYenisey2Matches) {
      onTabChange("yenisey-2");
    }
  }, [hasYeniseyMatches, hasYenisey2Matches, selectedTab, onTabChange]);

  return (
    <>
      <TabsList
        activeTab={selectedTab}
        onTabClick={onTabChange}
        showYeniseyTab={hasYeniseyMatches}
        showYenisey2Tab={hasYenisey2Matches}
      />
      <div className="block-top-games">
        <MatchTabsItem
          match={firstYeniseyMatch}
          hidden={
            selectedTab === "yenisey-2" || firstYeniseyMatch === undefined
          }
        />
        <MatchTabsItem
          match={firstYenisey2Match}
          hidden={selectedTab === "yenisey" || firstYenisey2Match === undefined}
        />
        <HeaderLogo />
        <MatchTabsItem
          match={secondYeniseyMatch}
          hidden={
            selectedTab === "yenisey-2" || secondYeniseyMatch === undefined
          }
        />
        <MatchTabsItem
          match={secondYenisey2Match}
          hidden={
            selectedTab === "yenisey" || secondYenisey2Match === undefined
          }
        />
      </div>
    </>
  );
}

export default MatchTabs;
