import React from 'react';
import {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {
  getTournaments,
  getTournamentsErrorStatus,
  getTournamentsLoadingStatus
} from '../../core/store/tournaments-data/selectors';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import {AppRoute} from "../../core/constants/routes";
import {
  filterTournamentsByCategory,
  splitTournaments
} from "../../core/utils/array-prepare-helpers";
import {useParams} from "react-router-dom";
import TournamentsList from "../../components/ordinary/tournaments-lists/tournaments-lists";
import {tournamentTypes} from "../../core/constants/tournament";
import {fetchTournaments} from "../../core/store/api-actions";

function TournamentsCompleted() {
  const dispatch = useAppDispatch();
  const {type: type} = useParams();
  const tournaments = useAppSelector(getTournaments);
  const {completedTournaments} = splitTournaments(tournaments);
  const filteredTournaments = filterTournamentsByCategory(completedTournaments, type);
  const isTournamentsLoading = useAppSelector(getTournamentsLoadingStatus);
  const isTournamentsError = useAppSelector(getTournamentsErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Tournaments, {
      title: `${tournamentTypes[type || '']}. Архив`,
      url: '#',
    }]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    dispatch(fetchTournaments());
  }, [dispatch]);

  return (
    <>
      <div hidden={!isTournamentsLoading}>
        <Loader/>
      </div>
      <div hidden={!isTournamentsError}>
        <Error/>
      </div>
      <div className="container-center" hidden={isTournamentsLoading}>
        <main className="full">
          <div className="detail">
            <div className="easy-text stat-colums">
              <TournamentsList tournaments={filteredTournaments} title={`${tournamentTypes[type || '']}. Архив`}/>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default TournamentsCompleted;
