import React from 'react';
import {Team} from "../../../core/types/team";
import {Link, useParams} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function TournamentTeamRow({team}: {team: Team}) {
  const {id: tournamentId} = useParams();

  return (
    <tr>
      <td>
        <Link to={`${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Teams.url}/${team.team_id}`} className={team.team_id === 1 || team.team_id === 2 ? 'red' : ''}>{team.full_name}</Link>
        <span className="gray_sm"> {team?.city?.title}</span>
      </td>
      <td>
        <Link to={`${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Teams.url}/${team.team_id}`}>Смотреть</Link>
      </td>
    </tr>
  );
}

export default TournamentTeamRow;
