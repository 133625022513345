import React from "react";
import { Partner } from "../../../core/types/partners";
import { Link } from "react-router-dom";

function PartnersItem({ partner }: { partner: Partner }) {
  return (
    <li>
      <Link to={partner.url} target="_blank" rel="noopener noreferrer">
        <img
          src={partner.logo_url}
          width="170"
          height="170"
          alt={partner.name}
        />
      </Link>
    </li>
  );
}

export default PartnersItem;
