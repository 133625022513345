import React from 'react';
import {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {
  getTournaments,
  getTournamentsErrorStatus,
  getTournamentsLoadingStatus
} from '../../core/store/tournaments-data/selectors';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import {fetchTournaments} from '../../core/store/api-actions';
import {AppRoute} from "../../core/constants/routes";
import {filterTournamentsByCategory} from "../../core/utils/array-prepare-helpers";
import TournamentsGroup from "../../components/ordinary/tournaments-group/tournaments-group";
import {tournamentTypes} from "../../core/constants/tournament";
import {getGroupSeasonLoading} from "../../core/store/seasons-data/selectors";

function Tournaments() {
  const dispatch = useAppDispatch();
  const tournaments = useAppSelector(getTournaments);
  const isTournamentsLoading = useAppSelector(getTournamentsLoadingStatus);
  const isTournamentsError = useAppSelector(getTournamentsErrorStatus);
  const seasonGroupLoading = useAppSelector(getGroupSeasonLoading);
  const tournamentTypesKeys = Object.keys(tournamentTypes);
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const tournamentsPageLoading = isTournamentsLoading || seasonGroupLoading;

  useEffect(() => {
    dispatch(fetchTournaments());
  }, [dispatch]);

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.Tournaments]);
  }, [setBreadcrumbs]);

  return (
    <>
      <div hidden={!tournamentsPageLoading}>
        <Loader/>
      </div>
      <div hidden={!isTournamentsError}>
        <Error/>
      </div>
      <div className="container-center" hidden={tournamentsPageLoading}>
        <main className="full">
          <div className="detail">
            {tournamentTypesKeys.map((tournamentType) => <TournamentsGroup key={tournamentType} tournamentsType={tournamentType} tournaments={filterTournamentsByCategory(tournaments, tournamentType)}/>)}
          </div>
        </main>
      </div>
    </>
  );
}

export default Tournaments;
