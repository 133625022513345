import React from "react";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";
import {Match} from "../../../core/types/match";
import {findFirstPlayerByTeamId} from "../../../core/utils/array-prepare-helpers";

function MatchGoalkeepers({ match }: { match: Match | null}) {
  const goalkeepers = match?.goalkeepers || [];

  if (goalkeepers.length === 0) {
    return null;
  }

  const firstTeamGoalkeeper = match?.team1_id !== undefined ? findFirstPlayerByTeamId(goalkeepers, match.team1_id) : undefined;
  const firstTeamGoalkeeperLink = `${AppRoute.Tournaments.url}/${match?.tournament_id}${AppRoute.Players.url}/${firstTeamGoalkeeper?.player_id}`;
  const firstTeamGoalkeeperInfo = `${firstTeamGoalkeeper?.last_name} ${firstTeamGoalkeeper?.first_name?.charAt(0)}.`;
  const secondTeamGoalkeeper = match?.team2_id !== undefined ? findFirstPlayerByTeamId(goalkeepers, match.team2_id) : undefined;
  const secondTeamGoalkeeperLink = `${AppRoute.Tournaments.url}/${match?.tournament_id}${AppRoute.Players.url}/${secondTeamGoalkeeper?.player_id}`;
  const secondTeamGoalkeeperInfo = `${secondTeamGoalkeeper?.last_name} ${secondTeamGoalkeeper?.first_name?.charAt(0)}.`;

  return (
    <table className="stat-table char" hidden={goalkeepers.length === 0}>
      <tbody>
        <tr>
          <th colSpan={2}>Вратари</th>
        </tr>
        <tr>
          <td hidden={firstTeamGoalkeeper === undefined}>
            <Link to={firstTeamGoalkeeperLink}>{firstTeamGoalkeeperInfo}</Link>
          </td>
          <td hidden={firstTeamGoalkeeper !== undefined}>-</td>
          <td hidden={secondTeamGoalkeeper === undefined}>
            <Link to={secondTeamGoalkeeperLink}>{secondTeamGoalkeeperInfo}</Link>
          </td>
          <td hidden={secondTeamGoalkeeper !== undefined}>-</td>
        </tr>
      </tbody>
    </table>
  );
}

export default MatchGoalkeepers;
