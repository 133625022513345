import React from "react";
import { Link, useParams } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { Match } from "../../../core/types/match";

function UnrealizedPenalties({ match }: { match: Match | null }) {
  const { tournamentId: tournamentId } = useParams();
  const unPenalties = match?.unrealizedPenalties || [];

  return (
    <table className="stat-table char" hidden={unPenalties.length === 0}>
      <tbody>
        <tr>
          <th colSpan={2}>Нереализованные пенальти</th>
        </tr>
        {unPenalties.map((penalty) => {
          const player = penalty.player;
          if (!player) return null;

          const playerName = `${player.last_name} ${player.first_name.charAt(
            0
          )}.`;
          const playerLink = `${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Players.url}/${player.player_id}`;
          const isLeft = penalty.team_id === match?.team1_id;

          return (
            <tr key={penalty.id}>
              {isLeft ? (
                <>
                  <td>
                    <Link to={playerLink}>{playerName}</Link>, {penalty.minute}
                    &apos;
                  </td>
                  <td></td>
                </>
              ) : (
                <>
                  <td></td>
                  <td>
                    <Link to={playerLink}>{playerName}</Link>, {penalty.minute}
                    &apos;
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default UnrealizedPenalties;
