import React from "react";
import { Match } from "../../../core/types/match";

function MatchInformation({ match }: { match: Match | null }) {
  const hasData = !!(
    match?.stadium?.city.title ||
    match?.stadium?.name ||
    match?.protocol?.attendance ||
    match?.temperature
  );

  return (
    <table className="stat-table char" hidden={!hasData}>
      <tbody>
        <tr>
          <th colSpan={2}>Информация о матче</th>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{ textAlign: "center" }}>
              <span hidden={match?.stadium === null}>{match?.stadium?.city.title}. <span>Стадион {match?.stadium?.name}</span>.</span>
              <span hidden={match?.protocol?.attendance === null}> Зрители: {match?.protocol?.attendance}</span>
              <span hidden={match?.temperature === null}> Температура: {match?.temperature} °C.</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default MatchInformation;
