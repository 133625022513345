import React from 'react';
import {TeamsStats} from "../../../core/types/team";
import Title from "../title/title";

function TeamGoals({teamStats}: {teamStats: TeamsStats | undefined}) {
  const gfAverage = Math.round(parseFloat(teamStats?.stats[0]?.gf_avg ?? '0'));
  const gaAverage=Math.round(parseFloat(teamStats?.stats[0]?.ga_avg ?? '0'));

  return (
    <>
      <Title title="Мячи"/>
      <table className="stat-table char">
        <tbody>
        <tr>
          <th>Характеристика</th>
          <th style={{width: '15%'}}>Всего</th>
        </tr>
        <tr>
          <td>Забито / В среднем</td>
          <td>{teamStats?.stats[0]?.gf || 0} / {gfAverage || 0}</td>
        </tr>
        <tr>
          <td>Пропущено / В среднем</td>
          <td>{teamStats?.stats[0]?.ga || 0} / {gaAverage || 0}</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default TeamGoals;
