import React from 'react';

function SliderArrows({type}: { type: string }) {
  const className = `arrows js-${type}-controls`

  return (
    <div className={className}>
      {[1, 2].map((item) => (
        <a key={item} className={`arrows__item${item === 2 ? ' arrows__item--right' : ''}`}>
          <svg className="arrows__icon" width="30" height="70">
            <use xlinkHref="images/sprite.svg#arrow-right-full"/>
          </svg>
        </a>
      ))}
    </div>
  );
}

export default SliderArrows;