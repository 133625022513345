import React, {useEffect} from "react";
import TinySlider from "tiny-slider-react";
import {bannersSliderConfig} from "../../../core/config/slider-config";
import BannersSliderItem from "../banners-slider-item/banners-slider-item";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getBanners,
  getBannersErrorStatus
} from "../../../core/store/banners-data/selectors";
import {fetchBanners} from "../../../core/store/api-actions";
import {filterBannersByPosition} from "../../../core/utils/array-prepare-helpers";
import {BannersPositions} from "../../../core/constants/common";

function BannersSlider() {
  const dispatch = useAppDispatch();
  const banners = useAppSelector(getBanners);
  const isBannersError = useAppSelector(getBannersErrorStatus);
  const filteredBanners = filterBannersByPosition(banners, BannersPositions.MainPage);

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  return (
    <div className="promo-banner" hidden={isBannersError || filteredBanners.length <= 0}>
      <div className="container-center">
        <TinySlider settings={bannersSliderConfig}>
          {filteredBanners.map((banner) =>
            <BannersSliderItem key={banner.banner_id} banner={banner}/>
          )}
        </TinySlider>
      </div>
    </div>
  );
}

export default BannersSlider;