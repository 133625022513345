import React from 'react';
import TinySlider from 'tiny-slider-react';
import "tiny-slider/dist/tiny-slider.css";
import SliderArrows from "../slider-arrows/slider-arrows";
import PointsItem from "../../simple/points-item/points-item";
import {Videos} from "../../../core/types/media";
import VideosSliderItem from "../videos-slider-item/videos-slider-item";
import {videosSliderConfig} from "../../../core/config/slider-config";

function VideosSlider({videos}: { videos: Videos }) {
  return (
    <div hidden={videos.length === 0} className="slides slides--light">
      <TinySlider settings={videosSliderConfig}>
        {videos.map((video) =>
          <VideosSliderItem key={video.video_id} video={video}/>
        )}
      </TinySlider>
      <SliderArrows type="videos"/>
      <ul className="points points--light js-videos-nav">
        {videos.map((video) =>
          <PointsItem key={video.video_id}/>
        )}
      </ul>
    </div>
  );
}

export default VideosSlider;