import { NameSpace } from '../../constants/api-constants'
import { Posts } from '../../types/post'
import { State } from '../../types/state'


export const getSearchedPostsLoading = (state: State): boolean =>
  state[NameSpace.Searches].searchedPostsLoading;
export const getSearchedPostsError = (state: State): boolean =>
  state[NameSpace.Searches].searchedPostsError;
export const getSearchedPosts = (state: State): Posts =>
  state[NameSpace.Searches].searchedPosts;
export const getSearchStatus = (state: State): string =>
  state[NameSpace.Searches].searchStatus;
export const getSearchString = (state: State): string =>
  state[NameSpace.Searches].searchString;
