import React, { useEffect } from "react";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { AppRoute } from "../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import { ClubNavigations } from "../../core/constants/navigations";
import { fetchProgramsPage } from "../../core/store/api-actions";
import Page from "../../components/ordinary/page/page";
import {
  getProgramsPage,
  getProgramsPageErrorStatus,
  getProgramsPageLoadingStatus,
} from "../../core/store/clubs-data/selectors";
import { store } from "../../core/store";

store.dispatch(fetchProgramsPage());
function Programs() {
  const dispatch = useAppDispatch();
  const programsPage = useAppSelector(getProgramsPage);
  const isProgramsPageLoading = useAppSelector(getProgramsPageLoadingStatus);
  const isProgramsPageError = useAppSelector(getProgramsPageErrorStatus);
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchProgramsPage());
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.Programs]);
  }, []);

  return (
    <div className="container-center">
      <main>
        <Page
          content={programsPage?.text}
          isPageLoading={isProgramsPageLoading}
          isPageError={isProgramsPageError}
        />
      </main>
      <aside>
        <SidebarNavigation navigationArray={ClubNavigations} />
      </aside>
    </div>
  );
}
export default Programs;
