import {createSlice} from '@reduxjs/toolkit';
import {fetchMediaPage} from '../api-actions';
import {MediaState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: MediaState = {
  isMediaPageLoading: false,
  isMediaPageError: false,
  mediaPage: null
};

export const mediaData = createSlice({
  name: NameSpace.Media,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMediaPage.pending, (state) => {
        state.isMediaPageLoading = true;
      })
      .addCase(fetchMediaPage.fulfilled, (state, action) => {
        state.mediaPage = action.payload;
        state.isMediaPageLoading = false;
      })
      .addCase(fetchMediaPage.rejected, (state) => {
        state.isMediaPageError = true;
        state.isMediaPageLoading= false;
      });
  }
});
