import React, { useEffect, useState } from "react";
import Title from "../../ordinary/title/title";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  fetchPlayerStatsGroup,
  fetchTeamPlayers,
} from "../../../core/store/api-actions";
import StatsWidgetHead from "../../simple/stats-widget-head/stats-widget-head";
import StatsWidgetRow from "../../ordinary/stats-widget-row/stats-widget-row";
import { playersTypeOptions } from "../../../core/constants/common";
import ButtonsList from "../../ordinary/buttons-list/buttons-list";
import LinkFull from "../../ordinary/link-full/link-full";
import { AppRoute } from "../../../core/constants/routes";
import {
  getWidgetPlayersTypeFilter,
  getWidgetRoundFilter,
  getWidgetTeamFilter,
} from "../../../core/store/widget-data/selectors";
import { getTeamPlayers } from "../../../core/store/team-players-data/selectors";
import { resetPlayerStatsGroups } from "../../../core/store/player-stats-data/player-stats-data";
import {
  getPlayerStatsGroupLoading,
  getPlayerStatsGroups,
} from "../../../core/store/player-stats-data/selectors";
import {
  filterPlayersByPositionId,
  findPlayerStatsGroupById,
} from "../../../core/utils/array-prepare-helpers";
import { getTeamRoundsLoadingStatus } from "../../../core/store/team-rounds-data/selectors";
import Loader from "../../ordinary/loader/loader";

function StatsWidget() {
  const dispatch = useAppDispatch();
  const widgetTeamFilter = useAppSelector(getWidgetTeamFilter);
  const teamPlayers = useAppSelector(getTeamPlayers);
  const widgetPlayersTypeFilter = useAppSelector(getWidgetPlayersTypeFilter);
  const widgetRoundFilter = useAppSelector(getWidgetRoundFilter);
  const playerStatsGroups = useAppSelector(getPlayerStatsGroups);
  const playerStatsGroupsLoading = useAppSelector(getPlayerStatsGroupLoading);
  const teamRoundsLoading = useAppSelector(getTeamRoundsLoadingStatus);
  const [filteredPlayers, setFilteredPlayers] = useState(teamPlayers);
  const statsWidgetLoading = playerStatsGroupsLoading || teamRoundsLoading;

  useEffect(() => {
    dispatch(fetchTeamPlayers(Number(widgetTeamFilter.value)));
  }, [dispatch, widgetTeamFilter]);

  useEffect(() => {
    dispatch(resetPlayerStatsGroups());
    teamPlayers.map((player) => {
      const params = {
        playerId: Number(player.player_id),
        groupBy: "tournament_id",
        roundId: widgetRoundFilter
          ? widgetRoundFilter.value.round_id
          : undefined,
      };

      dispatch(fetchPlayerStatsGroup(params));
    });
  }, [dispatch, widgetRoundFilter, teamPlayers]);

  useEffect(() => {
    if (widgetPlayersTypeFilter.value === "all") {
      setFilteredPlayers(
        teamPlayers.filter((teamPlayers) => teamPlayers.position_id !== 1)
      );
    } else if (widgetPlayersTypeFilter.value === "goalkeepers") {
      const filteredPlayers = filterPlayersByPositionId(teamPlayers, 1);
      setFilteredPlayers(filteredPlayers || []);
    }
  }, [widgetPlayersTypeFilter, teamPlayers]);

  return (
    <div className="tables__item">
      <Title title="Статистика" />
      <ButtonsList />
      <Loader hidden={!statsWidgetLoading} />
      <table
        className="stat-table stat-table--red"
        hidden={statsWidgetLoading || filteredPlayers.length === 0}
      >
        <StatsWidgetHead
          newLetter={
            widgetPlayersTypeFilter === playersTypeOptions[0] ? "Г" : "ПГ"
          }
        />
        <tbody>
          {filteredPlayers?.slice(0, 6).map((player) => (
            <StatsWidgetRow
              key={player.player_id}
              player={player}
              playerStatsGroup={findPlayerStatsGroupById(
                playerStatsGroups,
                player.player_id
              )}
            />
          ))}
        </tbody>
      </table>
      <div
        className="easy-text"
        hidden={statsWidgetLoading || filteredPlayers.length > 0}
      >
        <br />
        <br />
        <p>По заданным параметрам статистика отсутствует</p>
      </div>
      <LinkFull
        title="Показать полностью"
        url={`${AppRoute.Tournaments.url}/${widgetRoundFilter?.value.tournament_id}${AppRoute.Players.url}`}
        hidden={widgetRoundFilter === null || filteredPlayers.length < 6}
      />
    </div>
  );
}

export default StatsWidget;
