import React from 'react';
import {useAppSelector} from "../../../core/hooks";
import {getSendFeedbackStatus} from "../../../core/store/create-feedback-data/selectors";
import Message from "../message/message";
import {SendFeedbackStatus} from "../../../core/constants/common";
import Loader from "../loader/loader";
import FeedbackForm from "../../smart/feedback-form/feedback-form";

function FeedbackComponent({title}: { title: string }) {
  const sendFeedbackStatus = useAppSelector(getSendFeedbackStatus);

  return (
    <>
      <div hidden={sendFeedbackStatus !== SendFeedbackStatus.Pending}>
        <Loader/>
      </div>
      <div
        hidden={sendFeedbackStatus === SendFeedbackStatus.UnSuccess || sendFeedbackStatus === SendFeedbackStatus.Pending}
      >
        <Message message="Данные успешно отправлены"/>
      </div>
      <div
        hidden={sendFeedbackStatus === SendFeedbackStatus.Success || sendFeedbackStatus === SendFeedbackStatus.Pending}
        className="sale-form"
      >
        <FeedbackForm title={title}/>
      </div>
    </>
  );
}

export default FeedbackComponent;