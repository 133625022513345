import {createSlice} from '@reduxjs/toolkit';
import {fetchTeamRounds} from '../api-actions';
import {TeamRoundsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: TeamRoundsState = {
  teamRoundsLoading: false,
  teamRoundsError: false,
  teamRounds: [],
};

export const teamRoundsData = createSlice({
  name: NameSpace.Rounds,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamRounds.pending, (state) => {
        state.teamRoundsLoading = true;
      })
      .addCase(fetchTeamRounds.fulfilled, (state, action) => {
        state.teamRounds = action.payload;
        state.teamRoundsLoading = false;
      })
      .addCase(fetchTeamRounds.rejected, (state) => {
        state.teamRoundsError = true;
        state.teamRoundsLoading = false;
      })
  }
});
