import React from 'react';
import {NavigationsType} from "../../../core/types/navigation";
import {Link, useLocation} from "react-router-dom";
import Title from "../title/title";

function SidebarNavigation({navigationArray} : {navigationArray: NavigationsType}) {
  const location = useLocation();

  return (
    <>
      <Title title="Разделы"/>
      <ul className="root-list">
        {navigationArray.map((navigation) => {
          const isActive = location.pathname === navigation.url;
          const className = isActive ? 'active' : '';
          return (
            <li key={navigation.url} className={className}>
              <Link to={navigation.url}>{navigation.title}</Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default SidebarNavigation;