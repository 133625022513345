import React from 'react';
import {Link} from "react-router-dom";
import {FooterNavigations} from "../../../core/constants/navigations";

function Footer() {
  return (
    <footer>
      <div className="container-center">
        <nav className="bottom-menu">
          <ul>
            {FooterNavigations.map((footerNavigation) =>
              <li key={footerNavigation.title}>
                <Link to={footerNavigation.url}>
                  {footerNavigation.title}
                </Link>
              </li>
            )}
          </ul>
        </nav>
        <div className="foot-line">© 2011-{new Date().getFullYear()} АНО ПКХМ Енисей. Все права защищены.</div>
        <div className="cpr">
          <Link to="https://sportsoft.ru/" target="_blank" rel="noreferrer">Создание сайта - SportSoft</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
