import React from "react";
import { Link, useParams } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { Team } from "../../../core/types/team";

function MatchTeamItem({ team }: { team?: Team }) {
  const { id: tournamentId } = useParams();
  return (
    <div className="team">
      <Link
        to={`${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Teams.url}/${team?.team_id}`}
        className={
          team?.full_name === "Енисей" || team?.full_name === "Енисей-2"
            ? "red"
            : ""
        }
      >
        {team?.full_name}
      </Link>
      <p className="city">{team?.city?.title}</p>
    </div>
  );
}

export default MatchTeamItem;
