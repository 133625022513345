import React, {
  useEffect,
  useState
} from "react";
// import Filter from "../../smart/filter/filter";
import {
  PlayerItem,
  TotalStats
} from "../../../core/types/player";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {useParams} from "react-router-dom";
import {
  calculateTotalStats,
  findPlayerStatsGroupById,
} from "../../../core/utils/array-prepare-helpers";
import {fetchPlayerStatsGroup} from "../../../core/store/api-actions";
import {getPlayerStatsGroups} from "../../../core/store/player-stats-data/selectors";

function PlayerCareer({player}: { player: PlayerItem | null }) {
  const dispatch = useAppDispatch();
  const {id: playerId} = useParams();
  const playersStats = useAppSelector(getPlayerStatsGroups);
  const playerStats = findPlayerStatsGroupById(playersStats, Number(playerId));
  const [totalStats, setTotalStats] = useState<TotalStats>({
    games: 0,
    assists: 0,
    goals: "",
    goals_corner: 0,
    penalties_12: 0,
    goals_assists: "",
    white_cards: 0,
    red_cards: 0,
    blue_cards: 0,
  });

  useEffect(() => {
    if (playerId) {
      dispatch(
        fetchPlayerStatsGroup({playerId: Number(playerId), groupBy: "season_id"})
      );
    }
  }, [playerId]);

  useEffect(() => {
    if (playerStats) {
      const calculatedTotalStats = calculateTotalStats(playerStats.stats);
      setTotalStats(calculatedTotalStats);
    }
  }, [playerStats]);

  return (
    <div className="tab-content">
      {playerStats && (<div className="gap gap--20">
        {/* <Filter/> */}
        <div className="gap gap--30">
          {player?.teams.map((team, index) => (
            <div className="tcontainer" key={index}>
              <div className="theader">
                <img
                  className="theader__img"
                  src={team._links.logo.href}
                  alt={team.full_name}
                />
                <p className="theader__text">{team.full_name}</p>
              </div>
              <div className="table-scroll">
                <table className="table">
                  <thead className="table__thead">
                  <tr className="table__row">
                    <th className="table__th table__width-150">Сезон</th>
                    <th className="table__th table__width-95">Игры</th>
                    <th className="table__th table__width-95">Передачи</th>
                    <th className="table__th table__width-95">Мячи</th>
                    <th className="table__th table__width-95">Угловые</th>
                    <th className="table__th table__width-95">Пенальти</th>
                    <th className="table__th table__width-95">Гол+Пас</th>
                    <th className="table__th table__width-95">БК</th>
                    <th className="table__th table__width-95">КК</th>
                    <th className="table__th table__width-95">СК</th>
                  </tr>
                  </thead>
                  <tbody className="table__tbody">
                  {playerStats?.stats.map((stat, index) => (
                    <tr
                      key={index}
                      className={
                        index === playerStats.stats.length - 1
                          ? "table__row"
                          : ""
                      }
                    >
                      <td className="table__cell">{stat.season.title}</td>
                      <td className="table__cell">{stat.games}</td>
                      <td className="table__cell">{stat.assists}</td>
                      <td className="table__cell">{stat.goals}</td>
                      <td className="table__cell">{stat.goals_corner}</td>
                      <td className="table__cell">{stat.penalties_12}</td>
                      <td className="table__cell">{stat.goals_assists}</td>
                      <td className="table__cell">{stat.white_cards}</td>
                      <td className="table__cell">{stat.red_cards}</td>
                      <td className="table__cell">{stat.blue_cards}</td>
                    </tr>
                  ))}
                  {playerStats?.stats && playerStats?.stats.length > 1 && (
                    <tr className="table__row table__row--blue">
                      <td className="table__cell">Всего</td>
                      <td className="table__cell">{totalStats.games}</td>
                      <td className="table__cell">{totalStats.assists}</td>
                      <td className="table__cell">{totalStats.goals}</td>
                      <td className="table__cell">
                        {totalStats.goals_corner}
                      </td>
                      <td className="table__cell">
                        {totalStats.penalties_12}
                      </td>
                      <td className="table__cell">
                        {totalStats.goals_assists}
                      </td>
                      <td className="table__cell">
                        {totalStats.white_cards}
                      </td>
                      <td className="table__cell">{totalStats.red_cards}</td>
                      <td className="table__cell">{totalStats.blue_cards}</td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>)}
    </div>
  );
}

export default PlayerCareer;
