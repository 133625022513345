import {NavigationsType, HeaderNavigationsType} from "../types/navigation";
import {AppRoute} from "./routes";

export const HeaderNavigations: HeaderNavigationsType = [
  {
    title: AppRoute.Main.title,
    url: AppRoute.Main.url,
    submenu: null
  },
  {
    title: 'Клуб',
    url: AppRoute.AboutClub.url,
    submenu: [
      {
        title: AppRoute.Programs.title,
        url: AppRoute.Programs.url,
        submenu: null
      },
      {
        title: AppRoute.Attributes.title,
        url: AppRoute.Attributes.url,
        submenu: null
      },
      {
        title: AppRoute.History.title,
        url: AppRoute.History.url,
        submenu: null
      },
      {
        title: AppRoute.Achievements.title,
        url: AppRoute.Achievements.url,
        submenu: null
      },
      {
        title: AppRoute.Veterans.title,
        url: AppRoute.Veterans.url,
        submenu: null
      },
      {
        title: AppRoute.SymbolismAnthem.title,
        url: AppRoute.SymbolismAnthem.url,
        submenu: null
      },
      {
        title: AppRoute.GoalkeeperSchool.title,
        url: AppRoute.GoalkeeperSchool.url,
        submenu: null
      },
      {
        title: AppRoute.ClubProcurements.title,
        url: AppRoute.ClubProcurements.url,
        submenu: null
      },
    ]
  },
  {
    title: AppRoute.Tournaments.title,
    url: AppRoute.Tournaments.url,
    submenu: null
  },
  {
    title: AppRoute.School.title,
    url: AppRoute.School.url,
    submenu: null
  },
  {
    title: AppRoute.MassSkating.title,
    url: AppRoute.MassSkating.url,
    submenu: null
  },
  {
    title: AppRoute.Media.title,
    url: AppRoute.Media.url,
    submenu: [
      {
        title: AppRoute.PhotoGalleries.title,
        url: AppRoute.PhotoGalleries.url,
        submenu: null
      },
      {
        title: AppRoute.Videos.title,
        url: AppRoute.Videos.url,
        submenu: null
      },
    ]
  },
  {
    title: AppRoute.Procurements.title,
    url: AppRoute.Procurements.url,
    submenu: null
  },
  {
    title: AppRoute.Contacts.title,
    url: AppRoute.Contacts.url,
    submenu: [
      {
        title: AppRoute.Feedbacks.title,
        url: AppRoute.Feedbacks.url,
        submenu: null
      },
    ]
  },
];

export const FooterNavigations: NavigationsType = [
  AppRoute.Main,
  AppRoute.AboutClub,
  AppRoute.Posts,
  {
    title: 'Пресса',
    url: '/press',
  },
  AppRoute.Tournaments,
  AppRoute.Media,
  AppRoute.Feedbacks,
  AppRoute.Contacts,
];

export const TournamentsNavigations: NavigationsType = [
  {
    title: 'Общая информация',
    url: '/info',
  },
  {
    title: 'Статистика турнира',
    url: '/statistics',
  },
  {
    title: 'Турнирные таблицы',
    url: '/tables',
  },
  {
    title: 'Календарь и результаты',
    url: '/calendar',
  },
  {
    title: 'Енисей в турнире',
    url: '/teams',
  },
  {
    title: 'Игроки Енисея',
    url: '/players',
  },
];

export const MassSkatingNavigations: NavigationsType = [
  {
    title: 'Сведения об образовательной организации',
    url: 'https://www.хсмшколаенисей.рф/information/'
  },
  AppRoute.MassSkating,
  {
    title: 'Антитеррор',
    url: 'https://www.хсмшколаенисей.рф/antiterror/'
  },
  {
    title: 'Платформа обратной связи',
    url: 'https://www.хсмшколаенисей.рф/pos/'
  },
  {
    title: 'Приём в СШОР',
    url: 'https://www.хсмшколаенисей.рф/nabor/'
  },
  {
    title: 'Антидопинг',
    url: 'https://www.хсмшколаенисей.рф/antidoping/'
  },
  // {
  //   title: 'Наши тренеры',
  //   url: 'https://www.school.hc-enisey.ru/nashi-trenery/'
  // },
  {
    title: 'Платные услуги',
    url: 'https://www.хсмшколаенисей.рф/platnye-uslugi/'
  },
  {
    title: 'О школе',
    url: 'https://www.хсмшколаенисей.рф/about/'
  },
  {
    title: 'Контакты',
    url: 'https://www.хсмшколаенисей.рф/contacts/'
  },
];

export const ClubNavigations: NavigationsType = [
  AppRoute.Programs,
  AppRoute.Attributes,
  AppRoute.History,
  AppRoute.Achievements,
  AppRoute.Veterans,
  AppRoute.SymbolismAnthem,
  AppRoute.GoalkeeperSchool,
  AppRoute.ClubProcurements,
];

export const ClubHistoryNavigations: NavigationsType = [
  AppRoute.InternationalMatches,
  AppRoute.CountryCups,
  AppRoute.CountryTournaments,
];

export const CountryCupsNavigations: NavigationsType = [
  AppRoute.PastPresentFuture,
];