import React from 'react';

function Message({message, hidden}: {
  message: string;
  hidden?: boolean;
}) {
  return (
    <div className={`detail ${hidden ? 'hidden' : ''}`}>
      <div className="easy-text">
        <p>{message}</p>
      </div>
    </div>
  );
}

export default Message;