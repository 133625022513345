import React from 'react';
import {useLocation} from 'react-router-dom';
import classnames from 'classnames';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import {useBreadcrumbsContext} from '../breadcrumbs-provider/breadcrumbs-provider';
import 'font-awesome/css/font-awesome.min.css';
import OrganizationsLinks from "../../simple/organizations-links/organizations-links";
import SearchWidget from "../search-widget/search-widget";
import SocialsLinks from "../../simple/socials-links/socials-links";
import Navigation from "../navigation/navigation";
import MainLogo from "../../simple/main-logo/main-logo";
import AccessibleToggle from "../accessible-toggle/accessible-toggle";
import MobileLogo from "../../simple/mobile-logo/mobile-logo";
import MatchWidget from "../match-widget/match-widget";

function Header() {
  const { breadcrumbs } = useBreadcrumbsContext();
  const isMainPage = useLocation().pathname === '/';
  const headerClasses = classnames({
    'other': !isMainPage,
    '': isMainPage,
  });

  return (
    <header className={headerClasses}>
      <div className="top-red">
        <div className="container-center">
          <div className="left">
            <OrganizationsLinks/>
          </div>
          <div className="right">
            <AccessibleToggle/>
            <SearchWidget/>
            <SocialsLinks/>
          </div>
        </div>
      </div>
      <div className="container-center">
        <Navigation/>
        <div className="header-top-other" hidden={isMainPage}>
          <MainLogo/>
          <Breadcrumbs breadcrumbs={breadcrumbs}/>
        </div>
        <div className="header-top" hidden={!isMainPage}>
          <MobileLogo/>
          <MatchWidget/>
        </div>
      </div>
    </header>
  );
}

export default Header;
