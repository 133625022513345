import React from 'react';
import {PlayerItems} from "../../../core/types/player";
import PagePlayersItem from "../page-players-item/page-players-item";
import Title from "../title/title";

function PagePlayersList({players, title}: {players: PlayerItems | undefined, title?: string | undefined}) {
  return (
    <>
      <div hidden={players?.length === 0}>
        <Title title={title}/>
        <ul className="persons">
          {players?.map((player) => <PagePlayersItem key={player.player_id} player={player}/>)}
        </ul>
      </div>
    </>
  );
}

export default PagePlayersList;
