import React, {useState} from 'react';
import {Feedback} from "../../../core/types/feedback";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";

function FeedbackItem({feedback}: {feedback: Feedback}) {
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);
  const feedbackDate = new Date(feedback.created_at * 1000);

  const handleStrClick = () => {
    setIsAnswerVisible(!isAnswerVisible);
  };

  return (
    <div className="faq-item">
      <div className={`str ${isAnswerVisible ? 'down' : ''}`} onClick={handleStrClick}/>
      <div className="qestion">
        <h3>{feedback.first_name} из г. {feedback.city} {simpleDateFormat(feedbackDate, '(dd.MM.yyyy)')}</h3>
        <p>{feedback.request}</p>
      </div>
      <div hidden={!isAnswerVisible} className="answer">
        <div className="answ-photo">
          <img src="/images/logo-sm.png" alt="Лого"/>
        </div>
        <div className="answ-text">
          <strong>Ответ на вопрос</strong>
          <span>СШОР &quot;ЕНИСЕЙ&quot;</span>
          <p hidden={feedback.response === ''}>{feedback.response}</p>
          <p hidden={feedback.response !== ''}>Вопрос не имеет ответа</p>
        </div>
      </div>
    </div>
  );
}

export default FeedbackItem;