import React from 'react';
import {PlayerItems} from "../../../core/types/player";
import Title from "../title/title";
import GoalkeepersRow from "../goalkeepers-row/goalkeepers-row";
import GoalkeepersHead from "../goalkeepers-head/goalkeepers-head";

function GoalkeepersGroup({players, title}: {players: PlayerItems | undefined, title: string}) {
  return (
    <>
      <Title title={title}/>
      <table className="stat-table">
        <tbody>
        <GoalkeepersHead/>
        {players?.map((player) =>
          <GoalkeepersRow key={player.player_id} player={player}/>
        )}
        <tr hidden={players !== undefined && players.length > 0}>
          <td colSpan={10} style={{textAlign: "center"}}>Таблица пуста</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default GoalkeepersGroup;