import React from 'react';
import {
  PlayerItem,
  PlayerStatsGroup
} from "../../../core/types/player";

function StatsWidgetRow({player, playerStatsGroup}: {
  player: PlayerItem,
  playerStatsGroup?: PlayerStatsGroup
}) {
  return (
    <tr>
      <td>
        <div className="profile">
          <div className="logo">
            <img
              src={player._links.photo.href.includes('photo_placeholder_thumb.png') ? '/images/defaults/person.svg' : player._links.photo.href}
              alt={`${player.first_name} ${player.last_name}`} loading="lazy"
            />
          </div>
          {`${player.first_name} ${player.last_name}`}
        </div>
      </td>
      <td>{playerStatsGroup?.stats[0]?.games || 0}</td>
      <td>{playerStatsGroup?.stats[0]?.goals || 0}</td>
      <td>{playerStatsGroup?.stats[0]?.goals_assists || 0}</td>
      <td>{playerStatsGroup?.stats[0]?.white_cards || 0}</td>
      <td>{playerStatsGroup?.stats[0]?.blue_cards || 0}</td>
      <td>{playerStatsGroup?.stats[0]?.red_cards || 0}</td>
    </tr>
  );
}

export default StatsWidgetRow;
