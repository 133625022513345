import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import Loader from "../../ordinary/loader/loader";
import React, {useEffect} from "react";
import {fetchTeamPlayersGroup} from "../../../core/store/api-actions";
import PlayerItem from "../../ordinary/players-item/players-item";
import {filterPlayersByAchievements} from "../../../core/utils/array-prepare-helpers";
import Message from "../../ordinary/message/message";
import {AppRoute} from "../../../core/constants/routes";
import Title from "../../ordinary/title/title";
import {Team} from "../../../core/types/team";
import {
  getTeamPlayersGroupError,
  getTeamPlayersGroupLoading,
  getTeamPlayersGroups
} from "../../../core/store/team-players-data/selectors";

function PlayersList({team}: { team: Team }) {
  const dispatch = useAppDispatch();
  const players = useAppSelector(getTeamPlayersGroups);
  const teamPlayersObject = players.find(player => player.teamId === team.team_id);
  const filteredPlayers = filterPlayersByAchievements(teamPlayersObject?.players)
  const isPlayersError = useAppSelector(getTeamPlayersGroupError);
  const isPlayersLoading = useAppSelector(getTeamPlayersGroupLoading);

  useEffect(() => {
    dispatch(fetchTeamPlayersGroup(Number(team.team_id)));
  }, [team.team_id]);

  return (
    <>
      <Title title={team.full_name} url={`${AppRoute.Teams.url}/${team.team_id}`}/>
      <div hidden={!isPlayersLoading}>
        <Loader/>
      </div>
      <div hidden={!isPlayersError} className="easy-text">
        <Message message="Произошла ошибка при загрузке игроков."/>
      </div>
      <div hidden={filteredPlayers === undefined || filteredPlayers?.length > 0}>
        <Message message="Список игроков пуст"/>
      </div>
      <div hidden={filteredPlayers?.length === 0}>
        <ul className="articles-list">
          {filteredPlayers?.map((player) =>
            <PlayerItem key={player.player_id} player={player}/>
          )}
        </ul>
      </div>
    </>
  );
}

export default PlayersList;