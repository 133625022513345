import {createSlice} from '@reduxjs/toolkit';
import {
  fetchChapterById,
  fetchChaptersChild
} from '../api-actions';
import {ChaptersState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: ChaptersState = {
  isChaptersLoading: false,
  isChaptersError: false,
  chapters: [],
  isChapterLoading: false,
  isChapterError: false,
  chapter: null,
};

export const chaptersData = createSlice({
  name: NameSpace.Chapters,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchChaptersChild.pending, (state) => {
        state.isChaptersLoading = true;
      })
      .addCase(fetchChaptersChild.fulfilled, (state, action) => {
        state.chapters = action.payload;
        state.isChaptersLoading = false;
      })
      .addCase(fetchChaptersChild.rejected, (state) => {
        state.isChaptersError = true;
        state.isChaptersLoading = false;
      })
      .addCase(fetchChapterById.pending, (state) => {
        state.isChapterLoading = true;
      })
      .addCase(fetchChapterById.fulfilled, (state, action) => {
        state.chapter = action.payload;
        state.isChapterLoading = false;
      })
      .addCase(fetchChapterById.rejected, (state) => {
        state.isChapterError = true;
        state.isChapterLoading = false;
      });
  }
});
