import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import React, {useEffect} from "react";
import {fetchGroupTeam} from "../../../core/store/api-actions";
import {getGroupTeam} from "../../../core/store/teams-data/selectors";
import {findById} from "../../../core/utils/array-prepare-helpers";

function MatchTabsTeam({teamId}: {teamId: number}) {
  const dispatch = useAppDispatch();
  const teamGroups = useAppSelector(getGroupTeam);
  const teamGroup = findById(teamGroups, teamId);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchGroupTeam(teamId));
    }
  }, [teamId, dispatch])

  return (
    <div className="team">
      <div className="team-logo">
        <img src={teamGroup?.team._links.logo.href.includes('logo_placeholder_thumb.png') ? '/images/defaults/team.svg' : teamGroup?.team._links.logo.href} width="65" height="65" alt={teamGroup?.team.full_name} loading="lazy"/>
      </div>
      <div className="team-title">{teamGroup?.team.full_name}</div>
    </div>
  );
}

export default MatchTabsTeam;