import React from 'react';
import {Link} from "react-router-dom";

function LinkFull({title, url, hidden}: {
  title: string,
  url: string,
  hidden?: boolean
}) {
  return (
    <div className={`all-table ${hidden ? 'hidden' : ''}`}>
      <Link to={url}>{title}</Link>
    </div>
  );
}

export default LinkFull;