import React, {useEffect} from 'react';
import MatchEvent from "../match-event/match-event";
import {Match} from "../../../core/types/match";
import {useParams} from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {getMatchGoals} from "../../../core/store/matches-data/selectors";
import {fetchMatchGoals} from "../../../core/store/api-actions";

function MatchEvents({match}: { match: Match | null }) {
  const dispatch = useAppDispatch();
  const matchGoals = useAppSelector(getMatchGoals);
  const {matchId: matchId} = useParams();

  useEffect(() => {
    dispatch(fetchMatchGoals(Number(matchId)));
  }, [matchId]);

  return (
    <table className="stat-table char" hidden={matchGoals.length === 0}>
      <tbody>
      {matchGoals.map((matchGoal) =>
        <MatchEvent
          key={matchGoal.id}
          matchGoal={matchGoal}
          match={match}
        />)}
      </tbody>
    </table>
  );
}

export default MatchEvents;
