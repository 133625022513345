import {sendFeedback} from '../api-actions';
import {createSlice} from '@reduxjs/toolkit';
import {CreateFeedbackState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {SendFeedbackStatus} from "../../constants/common";

const initialState: CreateFeedbackState = {
  sendStatus: SendFeedbackStatus.UnSuccess,
};

export const createFeedbackData = createSlice({
  name: NameSpace.CreateFeedback,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendFeedback.pending, (state) => {
        state.sendStatus = SendFeedbackStatus.Pending;
      })
      .addCase(sendFeedback.fulfilled, (state) => {
        state.sendStatus = SendFeedbackStatus.Success;
      })
      .addCase(sendFeedback.rejected, (state) => {
        state.sendStatus = SendFeedbackStatus.UnSuccess;
      });
  }
});
