import React from 'react';
import {Match, MatchGoal} from "../../../core/types/match";
import MatchEventItem from "../match-event-item/match-event-item";

function MatchEvent({matchGoal, match}: {matchGoal: MatchGoal, match: Match | null}) {
  const secondTeam = match?.team2_id;
  return (
    <tr>
      <td hidden={matchGoal.team_id !== secondTeam} width="50%"/>
      <MatchEventItem matchGoal={matchGoal} direction={matchGoal.team_id === secondTeam ? 'left' : 'right'}/>
      <td hidden={matchGoal.team_id === secondTeam} width="50%"/>
    </tr>
  );
}

export default MatchEvent;
