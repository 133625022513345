import React from 'react';
import {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from '../../core/constants/routes';
import {useAppSelector} from '../../core/hooks';
import {store} from '../../core/store';
import Page from "../../components/ordinary/page/page";
import {fetchClubAttributesPage} from "../../core/store/api-actions";
import {getClubAttributesPage, getClubAttributesPageErrorStatus, getClubAttributesPageLoadingStatus} from "../../core/store/clubs-data/selectors";

store.dispatch(fetchClubAttributesPage());

function Attributes() {
  const AttributesPage = useAppSelector(getClubAttributesPage);
  const isAttributesPageLoading = useAppSelector(getClubAttributesPageLoadingStatus);
  const isAttributesPageError = useAppSelector(getClubAttributesPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.Attributes]);
  }, []);

  return (
    <div className="container-center">
      <main className="full">
        <Page content={AttributesPage?.text} isPageLoading={isAttributesPageLoading} isPageError={isAttributesPageError}/>
      </main>
    </div>
  );
}

export default Attributes;