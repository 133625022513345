import React from "react";
import { Match } from "../../../core/types/match";

function PenaltyMinutes({ match }: { match: Match | null }) {
  const stats = match?.stats || [];
  const team1PenaltyMinutes = match?.stats?.["1"]?.penalty_min;
  const team2PenaltyMinutes = match?.stats?.["2"]?.penalty_min;
  if (team1PenaltyMinutes == null && team2PenaltyMinutes == null) {
    return null;
  }
  const leftPenaltyMinutes =
    team1PenaltyMinutes != null ? team1PenaltyMinutes : 0;
  const rightPenaltyMinutes =
    team2PenaltyMinutes != null ? team2PenaltyMinutes : 0;

  return (
    <table className="stat-table char" hidden={stats.length === 0}>
      <tbody>
        <tr>
          <th colSpan={2}>Штрафные минуты</th>
        </tr>
        <tr>
          <td>{leftPenaltyMinutes} минут</td>
          <td>{rightPenaltyMinutes} минут</td>
        </tr>
      </tbody>
    </table>
  );
}

export default PenaltyMinutes;
