import React from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchMatch } from "../../core/store/api-actions";
import Loader from "../../components/ordinary/loader/loader";
import Error from "../error/error";
import "font-awesome/css/font-awesome.min.css";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { AppRoute } from "../../core/constants/routes";
import { TournamentsNavigations } from "../../core/constants/navigations";
import TournamentNavigation from "../../components/ordinary/tournament-navigation/tournament-navigation";
import MatchHead from "../../components/ordinary/match-head/match-head";
import MatchEvents from "../../components/ordinary/match-events/match-events";
import MatchGoalkeepers from "../../components/ordinary/match-goalkeepers/match-goalkeepers";
import {
  getMatch,
  getMatchErrorStatus,
  getMatchGoalsLoadingStatus,
  getMatchLoadingStatus, getMatchRefereesLoadingStatus,
} from "../../core/store/matches-data/selectors";
import MatchInformation from "../../components/ordinary/match-information/match-information";
import MatchReferees from "../../components/ordinary/match-referees/match-referees";
import Inspector from "../../components/ordinary/inspector/inspector";
import TeamCompositions from "../../components/ordinary/team-compositions/team-compositions";
import PenaltyMinutes from '../../components/ordinary/penalty-minutes/penalty-minutes'
import UnrealizedPenalties from '../../components/ordinary/unrealized-penalties/unrealized-penalties'

function TournamentMatch() {
  const dispatch = useAppDispatch();
  const {matchId: matchId } = useParams();
  const match = useAppSelector(getMatch);
  const isMatchError = useAppSelector(getMatchErrorStatus);
  const isMatchLoading = useAppSelector(getMatchLoadingStatus);
  const isMatchGoalsLoading = useAppSelector(getMatchGoalsLoadingStatus);
  const isRefereesLoading = useAppSelector(getMatchRefereesLoadingStatus)
  const matchPageLoading = isMatchLoading || isMatchGoalsLoading || isRefereesLoading;
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchMatch(Number(matchId)));
  }, [matchId]);

  useEffect(() => {
    if (match?.tournament) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.Tournaments,
        {
          title: match.tournament.full_name,
          url: `${AppRoute.Tournaments.url}/${match.tournament_id}/info`,
        },
        {
          title: AppRoute.TournamentCalendar.title,
          url: `${AppRoute.Tournaments.url}/${match.tournament_id}/calendar`,
        },
        {
          title: AppRoute.TournamentMatch.title,
          url: "#",
        },
      ]);
    }
  }, [match?.tournament]);

  return (
    <div className="container-center">
      <main>
        <div hidden={!matchPageLoading}>
          <Loader />
        </div>
        <div hidden={!isMatchError || matchPageLoading}>
          <Error />
        </div>
        <div className="stats other" hidden={isMatchError || matchPageLoading}>
          <MatchHead match={match} tournament={match?.tournament} />
          <MatchEvents match={match} />
          <MatchGoalkeepers match={match} />
          <PenaltyMinutes match={match}/>
          <UnrealizedPenalties match={match}/>
          <TeamCompositions match={match} />
          <MatchReferees />
          <Inspector inspector={match?.protocol?.inspector} />
          <MatchInformation match={match} />
        </div>
      </main>
      <aside>
        <TournamentNavigation navigationArray={TournamentsNavigations} id={match?.tournament_id.toString()}/>
      </aside>
    </div>
  );
}

export default TournamentMatch;
