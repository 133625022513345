import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {
  Season,
  SeasonGroups
} from "../../types/season";

export const getSeasonLoading = (state: State): boolean => state[NameSpace.Seasons].isSeasonLoading;
export const getSeasonError = (state: State): boolean => state[NameSpace.Seasons].isSeasonError;
export const getSeason = (state: State): Season | null => state[NameSpace.Seasons].season;
export const getGroupSeasonLoading = (state: State): boolean => state[NameSpace.Seasons].isGroupSeasonLoading;
export const getGroupSeasonError = (state: State): boolean => state[NameSpace.Seasons].isGroupSeasonError;
export const getSeasonGroups = (state: State): SeasonGroups => state[NameSpace.Seasons].seasonGroups;