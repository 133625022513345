import React from "react";

function TablesWidgetHead() {
  return (
    <thead>
    <tr>
      <th>М</th>
      <th>Команда</th>
      <th>И</th>
      <th>В</th>
      <th>Н</th>
      <th>П</th>
      <th>ГЗ-ГП</th>
      <th>О</th>
    </tr>
    </thead>
  );
}

export default TablesWidgetHead;