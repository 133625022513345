import React from 'react';
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function HeaderLogo() {
  return (
    <div className="header-logo">
      <Link to={AppRoute.Main.url}>
        <img src="images/logo.png" width="190" height="190" alt="Лого Енисей"/>
      </Link>
    </div>
  );
}

export default HeaderLogo;