import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  fetchTeamPlayersGroup,
  fetchTournament,
} from "../../core/store/api-actions";
import Loader from "../../components/ordinary/loader/loader";
import Error from "../error/error";
import "font-awesome/css/font-awesome.min.css";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { AppRoute } from "../../core/constants/routes";
import {
  getTournament,
  getTournamentErrorStatus,
  getTournamentLoadingStatus,
} from "../../core/store/tournaments-data/selectors";
import { TournamentsNavigations } from "../../core/constants/navigations";
import TournamentNavigation from "../../components/ordinary/tournament-navigation/tournament-navigation";
import {
  excludePlayersByPositionId,
  filterPlayersByPositionId,
} from "../../core/utils/array-prepare-helpers";
import PlayersGroup from "../../components/ordinary/players-group/players-group";
import GoalkeepersGroup from "../../components/ordinary/goalkeepers-group/goalkeepers-group";
import { tournamentTypes } from "../../core/constants/tournament";
import { TEAM_IDS } from "../../core/constants/common";
import { getTeamPlayersGroups } from "../../core/store/team-players-data/selectors";

function TournamentPlayers() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { id: tournamentId } = useParams();
  const tournament = useAppSelector(getTournament);
  const isTournamentError = useAppSelector(getTournamentErrorStatus);
  const isTournamentLoading = useAppSelector(getTournamentLoadingStatus);
  const players = useAppSelector(getTeamPlayersGroups);
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchTournament(Number(tournamentId)));
  }, [tournamentId]);

  useEffect(() => {
    if (tournament) {
      const teamId =
        tournament?.category === "junior"
          ? TEAM_IDS.YENISEY_2
          : TEAM_IDS.YENISEY;
      dispatch(fetchTeamPlayersGroup(teamId)).then(() => setIsLoading(false));
    }
  }, [dispatch, tournament?.category, TEAM_IDS.YENISEY, TEAM_IDS.YENISEY_2]);

  useEffect(() => {
    if (tournament) {
      setBreadcrumbs([
        AppRoute.Main,
        {
          title: "Статистика",
          url: AppRoute.Tournaments.url,
        },
        {
          title: `${tournamentTypes[tournament.category]}.${
            tournament.full_name
          }`,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/info`,
        },
        {
          title: "Статистика игры команды",
          url: "#",
        },
      ]);
    }
  }, [tournament, tournamentId, setBreadcrumbs]);

  if (isLoading) {
    return <Loader />;
  }

  const teamId =
    tournament?.category === "junior" ? TEAM_IDS.YENISEY_2 : TEAM_IDS.YENISEY;
  const teamName = tournament?.category === "junior" ? "Енисей-2" : "Енисей";
  const teamPlayers = players.find(
    (playerGroup) => playerGroup.teamId === teamId
  )?.players;
  const sortedTeamPlayers = teamPlayers
    ? teamPlayers.slice().sort((a, b) => a.number - b.number)
    : [];
  const pitchPlayers = excludePlayersByPositionId(sortedTeamPlayers, 1);
  const goalkeepers = filterPlayersByPositionId(sortedTeamPlayers, 1);
  return (
    <>
      <div hidden={!isTournamentError}>
        <Error />
      </div>
      <div className="container-center" hidden={isTournamentLoading}>
        <main>
          <div className="stats other">
            <PlayersGroup
              players={pitchPlayers}
              title={`Полевые игроки команды "${teamName}" в турнире`}
            />
            <br />
            <GoalkeepersGroup
              players={goalkeepers}
              title={`Вратари "${teamName}" в турнире`}
            />
            <br />
            <br />
            <div className="all-table">
              <Link
                to={`${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Teams.url}/${teamId}`}
              >
                {`Статистика по команде "${teamName}" в турнире`}
              </Link>
            </div>
          </div>
        </main>
        <aside>
          <TournamentNavigation
            navigationArray={TournamentsNavigations}
            id={tournamentId}
          />
        </aside>
      </div>
    </>
  );
}

export default TournamentPlayers;
