import React from 'react';

function PlayersHead() {
  return (
    <tr>
      <th style={{width: '8%'}}>N</th>
      <th>Игрок</th>
      <th>Дата рождения</th>
      <th colSpan={2}>Передачи / угл.</th>
      <th colSpan={4}>Мячи / угл. / пен. / штр.</th>
      <th style={{width: '10%'}}>Гол+пас.</th>
    </tr>
  );
}

export default PlayersHead;