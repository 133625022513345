import React from 'react';

function GoalkeepersHead() {
  return (
    <tr>
      <th>
        <div>N</div>
      </th>
      <th>Игрок</th>
      <th>Дата рождения</th>
      <th>Матчи</th>
      <th colSpan={2}>Пропущено / сред.</th>
    </tr>
  );
}

export default GoalkeepersHead;