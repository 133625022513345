import {createSlice} from '@reduxjs/toolkit';
import {
  fetchTeamPlayers,
  fetchTeamPlayersGroup
} from '../api-actions';
import {TeamPlayersState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: TeamPlayersState = {
  teamPlayersLoading: false,
  teamPlayersError: false,
  teamPlayers: [],
  teamPlayersGroupLoading: false,
  teamPlayersGroupError: false,
  teamPlayersGroups: [],
};

export const teamPlayersData = createSlice({
  name: NameSpace.TeamPlayers,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTeamPlayers.pending, (state) => {
        state.teamPlayersLoading = true;
      })
      .addCase(fetchTeamPlayers.fulfilled, (state, action) => {
        state.teamPlayers = action.payload;
        state.teamPlayersGroupLoading = false;
      })
      .addCase(fetchTeamPlayers.rejected, (state) => {
        state.teamPlayersError = true;
        state.teamPlayersLoading = false;
      })
      .addCase(fetchTeamPlayersGroup.pending, (state) => {
        state.teamPlayersGroupLoading = true;
      })
      .addCase(fetchTeamPlayersGroup.fulfilled, (state, action) => {
        const existingTeamPlayersGroups = state.teamPlayersGroups.find(teamPlayersGroup => teamPlayersGroup.teamId === action.payload.teamId);
        if (!existingTeamPlayersGroups) {
          state.teamPlayersGroups.push(action.payload);
        }
        state.teamPlayersGroupLoading = false;
      })
      .addCase(fetchTeamPlayersGroup.rejected, (state) => {
        state.teamPlayersGroupError = true;
        state.teamPlayersGroupLoading = false;
      })
  }
});