import React, {useEffect} from 'react';
import {fetchPlayerStatsGroup} from "../../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import Title from "../title/title";
import {useParams} from "react-router-dom";
import {findPlayerStatsGroupById} from "../../../core/utils/array-prepare-helpers";
import {getPlayerStatsGroups} from "../../../core/store/player-stats-data/selectors";

function PlayerStats() {
  const {playerId: playerId} = useParams();
  const dispatch = useAppDispatch();
  const playersStats = useAppSelector(getPlayerStatsGroups);
  const playerStats = findPlayerStatsGroupById(playersStats, Number(playerId));

  useEffect(() => {
    dispatch(fetchPlayerStatsGroup({playerId: Number(playerId), groupBy: 'player_id'}));
  }, [playerId]);

  return (
    <>
      <Title title="Статистика игрока"/>
      <table className="stat-table char">
        <tbody>
        <tr>
          <th>Характеристика</th>
          <th style={{width: '35%'}}>Показатели</th>
        </tr>
        <tr>
          <td>Провел игр (за &quot;Енисей&quot;)</td>
          <td>{playerStats?.stats[0]?.games}</td>
        </tr>
        <tr>
          <td>Забитые мячи (за &quot;Енисей&quot;)
          </td>
          <td>{playerStats?.stats[0]?.goals}</td>
        </tr>
        <tr>
          <td>Передачи (за &quot;Енисей&quot;)</td>
          <td>{playerStats?.stats[0]?.assists}</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default PlayerStats;
