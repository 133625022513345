import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { useNavigate } from "react-router-dom";
import {
  getSearchString,
} from "../../../core/store/searches-data/selectors";
import { savingSearchString } from "../../../core/store/searches-data/searches-data";
import { AppRoute } from "../../../core/constants/routes";
import { fetchSearchedPosts } from "../../../core/store/api-actions";

function SearchWidget() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const searchString = useAppSelector(getSearchString);
  const [isSearchActive, setSearchActive] = useState(false);
  const [search, setSearch] = useState("");
  const searchRef = useRef<HTMLDivElement>(null);

  const handleSearchClick = () => {
    setSearchActive(!isSearchActive);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setSearchActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearch(event.target.value);
    dispatch(savingSearchString({ searchString: event.target.value }));
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchString.length > 0) {
      dispatch(fetchSearchedPosts(searchString));
    }
    navigate(AppRoute.SearchPage.url);
    setSearch("");
  };

  return (
    <>
      <a
        hidden={false}
        className={`search ${isSearchActive ? "active" : ""}`}
        onClick={handleSearchClick}
      >
        <i className="fa fa-search" aria-hidden="true" />
      </a>
      <div
        className={`bl-search ${isSearchActive ? "active" : ""}`}
        ref={searchRef}
      >
        <form className="searchform" onSubmit={handleSubmit}>
          <input
            name="text"
            type="text"
            id="q"
            placeholder="Введите слово для поиска"
            onChange={handleChange}
            value={search}
          />
          <button className="s-button" type="submit">
            Найти
          </button>
        </form>
      </div>
    </>
  );
}

export default SearchWidget;

