import React, {useEffect, useState} from 'react';
import {useBreadcrumbsContext} from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import {AppRoute} from "../../core/constants/routes";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import Pagination from "../../components/smart/pagination/pagination";
import {useAppSelector} from "../../core/hooks";
import {getPosts} from "../../core/store/posts-data/selectors";
import {filterPostsByTag} from "../../core/utils/array-prepare-helpers";
import {POSTS_PER_PAGE} from "../../core/constants/common";
import {ClubHistoryNavigations} from "../../core/constants/navigations";
import { store } from '../../core/store'
import { fetchPosts } from '../../core/store/api-actions'

store.dispatch(fetchPosts())

function History() {
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByTag(posts, AppRoute.History.title);
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE);
  const start = (currentPage - 1) * POSTS_PER_PAGE;
  const end = start + POSTS_PER_PAGE;
  const currentPosts = filteredPosts.slice(start, end);

  const goToPage = (page : number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.History]);
  }, []);

  return (
    <div className="container-center">
      <main>
        <div className="block-press other">
          <div className="container-center">
            <PostsList currentPosts={currentPosts} tag={AppRoute.History.title}/>
            <Pagination currentPage={currentPage} totalPages={totalPages} goToPage={goToPage}/>
          </div>
        </div>
      </main>
      <aside>
        <SidebarNavigation navigationArray={ClubHistoryNavigations}/>
      </aside>
    </div>
  );
}

export default History;