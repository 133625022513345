import {createSlice} from '@reduxjs/toolkit';
import {NameSpace} from '../../constants/api-constants';
import {
    fetchFeedbacks,
    fetchFeedbacksPage
} from '../api-actions';
import {FeedbacksState} from '../../types/state';

const initialState: FeedbacksState = {
    isFeedbacksLoading: false,
    isFeedbacksError: false,
    feedbacks: [],
    isFeedbacksPageLoading: false,
    isFeedbacksPageError: false,
    feedbacksPage: null
};

export const feedbacksData = createSlice({
    name: NameSpace.Feedbacks,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchFeedbacks.pending, (state) => {
                state.isFeedbacksLoading = true;
            })
            .addCase(fetchFeedbacks.fulfilled, (state, action) => {
                state.feedbacks = action.payload;
                state.isFeedbacksLoading = false;
            })
            .addCase(fetchFeedbacks.rejected, (state) => {
                state.isFeedbacksError = true;
                state.isFeedbacksLoading = false;
            })
            .addCase(fetchFeedbacksPage.pending, (state) => {
                state.isFeedbacksPageLoading = true;
            })
            .addCase(fetchFeedbacksPage.fulfilled, (state, action) => {
                state.feedbacksPage = action.payload;
                state.isFeedbacksPageLoading = false;
            })
            .addCase(fetchFeedbacksPage.rejected, (state) => {
                state.isFeedbacksPageError = true;
                state.isFeedbacksPageLoading = false;
            });
    }
});
