import React from 'react';
import {Photo} from '../../../core/types/media';
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {Link} from "react-router-dom";

function PhotoItem({photo} : {photo: Photo}) {
  const photoDate = new  Date(photo.updated_at * 1000)

  return (
    <li>
      <Link data-fancybox="gallery" to={photo._links.image.href}>
        <div className="preview">
          <img src={photo._links.image.href} alt={photo.description}/>
          <i className="fa fa-search-plus" aria-hidden="true"/>
        </div>
        <div className="addit">{simpleDateFormat(photoDate, 'dd MMMM yyyy, HH:mm')}</div>
      </Link>
    </li>
  );
}

export default PhotoItem;
