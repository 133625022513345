import React from 'react';
import Message from "../message/message";
import Title from "../title/title";
import {Tournaments} from "../../../core/types/tournament";
import TournamentsItem from "../tournaments-item/tournaments-item";

function TournamentsList({tournaments, title}: {
  tournaments: Tournaments,
  title?: string | undefined
}) {
  return (
    <>
      <Title title={title}/>
      <div hidden={tournaments.length === 0}>
        <ul>
          {tournaments.map((tournament) => <TournamentsItem key={tournament.tournament_id} tournament={tournament}/>)}
        </ul>
      </div>
      <div hidden={tournaments.length > 0}>
        <Message message="На данный момент у нас нет соревнований."/>
      </div>
    </>
  );
}

export default TournamentsList;
