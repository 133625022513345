import {ChaptersState, State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import { Chapter, Chapters } from '../../types/common'

export const getChaptersLoadingStatus = (state: State): boolean => state[NameSpace.Chapters].isChaptersLoading;
export const getChaptersErrorStatus = (state: State): ChaptersState['isChaptersError'] => state[NameSpace.Chapters].isChaptersError;
export const getChapters = (state: State): Chapters => state[NameSpace.Chapters].chapters;
export const getChapterLoadingStatus = (state: State): boolean => state[NameSpace.Chapters].isChapterLoading;
export const getChapterErrorStatus = (state: State): ChaptersState['isChapterError'] => state[NameSpace.Chapters].isChapterError;
export const getChapter = (state: State): Chapter | null => state[NameSpace.Chapters].chapter;
