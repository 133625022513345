import { Post, Posts } from "../types/post";
import {
  PlayerGroups,
  PlayerItem,
  PlayerItems,
  PlayerStats,
  PlayerStatsGroup,
  PlayerStatsGroups,
  TeamPlayersGroups,
  TotalStats,
} from "../types/player";
import {
  TournamentGroup,
  TournamentGroups,
  Tournaments,
  TournamentsStats,
  TournamentsStatsArray,
} from "../types/tournament";
import { Team, Teams, TeamsStats, TeamsStatsArray } from "../types/team";
import { GroupedMatchesArray, Match, Matches } from "../types/match";
import { RoundOption, Socials } from "../types/common";
import { Banners } from "../types/banner";
import { Video } from "../types/media";
import { Rounds } from "../types/rounds";

export function filterPostsByTag(
  posts: Posts,
  tagName: string | undefined
): Posts {
  return posts.filter((post) => post.tags.some((tag) => tag.name === tagName));
}

export function getActualItem<T extends { is_actual?: number }>(
  items: T[] | null | undefined
): T | undefined {
  if (!items || items.length === 0) {
    console.error("Входные данные отсутствуют или равны null/undefined");
    return undefined;
  }
  if (!Array.isArray(items)) {
    console.error("Входные данные не являются массивом объектов");
    return undefined;
  }
  const actualItem = items.find((item) => item && item.is_actual !== undefined);
  if (!actualItem) {
    console.error("Не удалось найти актуальный объект");
    return undefined;
  }
  return actualItem;
}

export function filterItemsByMain<T extends { on_main?: number }>(
  items: T[] | null | undefined
): T[] {
  if (!items) {
    console.error("Входные данные отсутствуют или равны null/undefined");
    return [];
  }
  if (!Array.isArray(items)) {
    console.error("Входные данные не являются массивом объектов");
    return [];
  }
  return items.filter((item) => item && item.on_main);
}

export function filterTeamsByClubId(
  teams: Teams,
  clubIdToFilter: number | undefined
): Teams {
  if (clubIdToFilter === undefined) {
    return [];
  }

  return teams.filter((item) => item.club_id === clubIdToFilter);
}

export function filterPostsByAchievements(posts: Posts): Posts {
  return posts.filter((post) => post?.in_achievements);
}

export function filterPlayersByPositionId(
  players: PlayerItems | undefined,
  positionId: number
): PlayerItems | undefined {
  if (!Array.isArray(players)) {
    console.error("Ошибка: ожидался массив игроков.");
    return;
  }
  if (isNaN(positionId)) {
    console.error("Ошибка: positionId должен быть числом.");
    return;
  }
  const filteredPlayers = players.filter((player) => {
    return player.position_id === positionId && player.position_id !== null;
  });
  if (filteredPlayers.length === 0) {
    console.error(`Игроки с position_id ${positionId} не найдены.`);
  }
  return filteredPlayers;
}

export function findFirstPlayerByTeamId(
  players: PlayerItems | undefined,
  teamId: number
): PlayerItem | undefined {
  if (!Array.isArray(players)) {
    console.error("Ошибка: ожидался массив игроков.");
    return;
  }
  if (isNaN(teamId as number)) {
    console.error("Ошибка: teamId должен быть числом.");
    return;
  }
  for (const player of players) {
    if (player.team.team_id === teamId && player.team.team_id !== null) {
      return player;
    }
  }
  console.error(`Игроки с team_id ${teamId} не найдены.`);
  return;
}

export function filterTeamPlayersByTeamId(
  teamPlayers: TeamPlayersGroups,
  teamId: number
) {
  return teamPlayers.find((player) => player?.teamId === Number(teamId));
}

export function getRelevantRounds(rounds: Rounds): Rounds {
  const activeTournaments = rounds
    .filter((round) => {
      const endDate = new Date(round.tournament.end_dt);
      const currentDate = new Date();
      return endDate >= currentDate;
    })
    .map((round) => round.tournament.tournament_id);
  let relevantRounds: Rounds;
  if (activeTournaments.length === 0) {
    const finishedTournaments = [
      ...new Set(rounds.map((round) => round.tournament.tournament_id)),
    ];
    const lastFinishedTournamentId = Math.max(...finishedTournaments);
    relevantRounds = rounds.filter(
      (round) => round.tournament.tournament_id === lastFinishedTournamentId
    );
  } else {
    relevantRounds = rounds.filter((round) =>
      activeTournaments.includes(round.tournament.tournament_id)
    );
  }
  return relevantRounds;
}

export function excludePlayersByPositionId(
  players: PlayerItems | undefined,
  positionId: number
) {
  return players?.filter((player) => player.position_id !== positionId);
}

export function filterPlayersByAchievements(
  players: PlayerItems | undefined
): PlayerItems | undefined {
  return players?.filter((player) => player.in_achievements);
}

export function filterTournamentsByCategory(
  tournaments: Tournaments,
  category: string | undefined
): Tournaments {
  if (!Array.isArray(tournaments)) {
    console.error("Переданные данные не являются массивом турниров");
    return [];
  }
  if (typeof category !== "string" || category.trim() === "") {
    console.error("Некорректное значение категории");
    return [];
  }
  return tournaments.filter((tournament) => {
    const tournamentCategory = tournament?.category?.trim() || "";
    return category === tournamentCategory;
  });
}

export function splitTournaments(tournaments: Tournaments): {
  completedTournaments: Tournaments;
  activeTournaments: Tournaments;
} {
  if (!Array.isArray(tournaments)) {
    console.error("Переданные данные не являются массивом турниров");
    return {
      completedTournaments: [],
      activeTournaments: [],
    };
  }
  const currentDate = new Date();
  const completedTournaments: Tournaments = [];
  const activeTournaments: Tournaments = [];
  for (const tournament of tournaments) {
    const endDate = new Date(tournament.end_dt);
    if (isNaN(endDate.getTime())) {
      console.error(
        `Некорректная дата окончания турнира: ${tournament.end_dt}`
      );
      continue;
    }
    if (currentDate >= endDate) {
      completedTournaments.push(tournament);
    } else {
      activeTournaments.push(tournament);
    }
  }
  return {
    completedTournaments,
    activeTournaments,
  };
}

export function sortItemsByPoints<T extends { points?: number }>(
  items: T[] | null | undefined
): T[] {
  if (!items || items.length === 0) {
    return [];
  }
  const clonedItems = items.slice();
  return clonedItems.sort((a, b) => {
    const pointsA = a.points !== undefined ? a.points : 0;
    const pointsB = b.points !== undefined ? b.points : 0;
    return pointsB - pointsA;
  });
}

export function findTeamById(teams: Teams, teamId: number): Team | undefined {
  if (!Array.isArray(teams)) {
    console.error("Ошибка: Первый аргумент должен быть массивом команд.");
    return undefined;
  }
  const foundTeam = teams.find((team) => team.team_id === teamId);
  if (!foundTeam) {
    console.error(`Ошибка: Команда с идентификатором ${teamId} не найдена.`);
    return undefined;
  }
  return foundTeam;
}

export function filterCompletedMatches(matches: Matches): Matches {
  return matches.filter((match) => match.overtime === 1);
}

export function groupMatchesByDay(matches: Matches): GroupedMatchesArray {
  const groupedMatches: Record<string, Matches> = {};

  for (const match of matches) {
    const date = new Date(match.start_dt);
    const day = date.toISOString().split("T")[0];

    if (day in groupedMatches) {
      groupedMatches[day].push(match);
    } else {
      groupedMatches[day] = [match];
    }
  }

  return Object.entries(groupedMatches).map(([date, matches]) => ({
    date,
    matches,
  }));
}

export function sortGroupedMatchesByDate(
  groupedMatches: GroupedMatchesArray
): GroupedMatchesArray {
  return groupedMatches.sort((a, b) => {
    return b.date.localeCompare(a.date);
  });
}

export function findById<T extends { id: number | undefined }>(
  groups: T[],
  idToFind?: number
): T | undefined {
  if (!Array.isArray(groups)) {
    console.error("Ошибка: groups должен быть массивом");
    return undefined;
  }
  if (idToFind && isNaN(idToFind)) {
    console.error("Ошибка: idToFind должен быть числом");
    return undefined;
  }
  return groups.find((group) => group.id === idToFind);
}

export function findTeamStatsById(
  teamsStatsArray: TeamsStatsArray,
  teamIdToFind: number | undefined
): TeamsStats | undefined {
  return teamsStatsArray.find(
    (teamsStats) => teamsStats.teamId === teamIdToFind
  );
}

export function findPlayerStatsGroupById(
  playersStatsArray: PlayerStatsGroups,
  playerIdToFind: number | undefined
): PlayerStatsGroup | undefined {
  if (!playersStatsArray || playerIdToFind === undefined) {
    console.error(
      "Отсутствуют параметры: требуются playersStatsArray и playerIdToFind."
    );
    return undefined;
  }
  if (!Array.isArray(playersStatsArray)) {
    console.error("Неверный параметр: playersStatsArray должен быть массивом.");
    return undefined;
  }
  if (playersStatsArray.length === 0) {
    console.warn("Массив playersStatsArray пуст.");
    return undefined;
  }
  if (!playersStatsArray.every(() => true)) {
    console.error(
      "Неверные данные: каждый элемент в playersStatsArray должен иметь числовое свойство playerId."
    );
    return undefined;
  }
  return playersStatsArray.find(
    (playersStats) => playersStats.playerId === playerIdToFind
  );
}

export function findTournamentStatsById(
  tournamentsStatsArray: TournamentsStatsArray,
  TournamentIdToFind: number | undefined
): TournamentsStats | undefined {
  return tournamentsStatsArray.find(
    (tournamentsStats) => tournamentsStats.tournamentId === TournamentIdToFind
  );
}

export function findPlayerById(
  players: PlayerGroups,
  playerId: number | undefined
) {
  return players.find((playerData) => playerData.playerId === playerId);
}

export function findGoalkeeper(players: PlayerItems): PlayerItem | undefined {
  if (Array.isArray(players)) {
    return players.find((player) => player.position_id === 1);
  } else {
    return undefined;
  }
}

export function getLastMatches(matches: Matches, count: number): Matches {
  if (!Array.isArray(matches)) {
    return [];
  }

  const now = Date.now();
  let futureMatchesExist = false;

  matches.forEach((match) => {
    const matchDate = new Date(match.start_dt).getTime();
    if (matchDate > now) {
      futureMatchesExist = true;
    }
  });
  const matchesCopy = [...matches];

  let sortedMatches;
  if (futureMatchesExist) {
    sortedMatches = matchesCopy
      .filter((match) => new Date(match.start_dt).getTime() > now)
      .sort(
        (a, b) =>
          new Date(a.start_dt).getTime() - new Date(b.start_dt).getTime()
      );
  } else {
    sortedMatches = matchesCopy.sort(
      (a, b) => new Date(b.start_dt).getTime() - new Date(a.start_dt).getTime()
    );
  }

  return sortedMatches.slice(0, count);
}

export function filterMatchesByTeamId(
  matches: Matches,
  teamId: number
): Matches {
  if (!Array.isArray(matches) || !Number.isFinite(teamId) || teamId < 0) {
    console.error(
      "Неверные входные данные: matches должен быть массивом, teamId - конечным числом больше 0"
    );
    return [];
  }
  return matches.filter((match) => {
    const hasValidStructure =
      match &&
      typeof match === "object" &&
      "team1_id" in match &&
      "team2_id" in match;
    const hasValidIds =
      hasValidStructure &&
      Number.isFinite(match.team1_id) &&
      Number.isFinite(match.team2_id);
    return (
      hasValidIds && (match.team1_id === teamId || match.team2_id === teamId)
    );
  });
}

export function getLastTwoMatches(
  matches: Match[]
): [Match] | [Match, Match] | null {
  if (!Array.isArray(matches)) {
    console.error("Входной аргумент должен быть массивом");
    return null;
  }

  const now = Date.now();
  const matchesCopy = [...matches];
  let futureMatchesExist = false;

  matchesCopy.forEach((match) => {
    if (match && typeof match === "object" && "start_dt" in match) {
      const matchDate = new Date(match.start_dt).getTime();
      if (!isNaN(matchDate) && matchDate > now) {
        futureMatchesExist = true;
      }
    } else {
      console.error("Некорректный элемент в массиве matches:", match);
    }
  });

  let sortedMatches;
  if (futureMatchesExist) {
    sortedMatches = matchesCopy
      .filter((match) => {
        const matchDate = new Date(match.start_dt).getTime();
        return !isNaN(matchDate) && matchDate > now;
      })
      .sort(
        (a, b) =>
          new Date(a.start_dt).getTime() - new Date(b.start_dt).getTime()
      );
  } else {
    sortedMatches = matchesCopy.sort(
      (a, b) => new Date(b.start_dt).getTime() - new Date(a.start_dt).getTime()
    );
  }

  if (sortedMatches.length >= 1) {
    return sortedMatches.slice(0, 2) as [Match] | [Match, Match];
  } else {
    console.error("Нет доступных матчей для возвращения");
    return null;
  }
}

export function filterPlayersWithBirthdayInCurrentWeek(
  players: PlayerItems
): PlayerItems {
  const currentDate = new Date();
  const uniquePlayerIds = new Set<number>();

  const filteredPlayers = players.filter((player) => {
    const birthdayDate = new Date(player.birthday);

    const isInCurrentWeek =
      birthdayDate.getDate() >= currentDate.getDate() &&
      birthdayDate.getMonth() === currentDate.getMonth() &&
      birthdayDate.getDate() <= currentDate.getDate() + 7;

    if (isInCurrentWeek && !uniquePlayerIds.has(player.player_id)) {
      uniquePlayerIds.add(player.player_id);
      return true;
    }

    return false;
  });

  filteredPlayers.sort((a, b) => {
    const dateA = new Date(a.birthday).getDate();
    const dateB = new Date(b.birthday).getDate();
    return dateA - dateB;
  });

  return filteredPlayers;
}

export const filterSocialNetworks = (
  socialData: Socials,
  module: string
): Socials => {
  return socialData.filter((item) => item.module === module);
};

export const sortByRank = (socialData: Socials): Socials => {
  const sortedData = [...socialData];
  sortedData.sort((a, b) => a.rank - b.rank);

  return sortedData;
};

export function findGroupTournamentById(
  tournamentGroups: TournamentGroups,
  tournamentIdToFind: number | undefined
): TournamentGroup | undefined {
  return tournamentGroups.find(
    (tournamentGroup) => tournamentGroup.tournamentId === tournamentIdToFind
  );
}

export function filterBannersByPosition(
  banners: Banners,
  position: string
): Banners {
  if (!Array.isArray(banners)) {
    console.error("Ошибка: предоставленные данные не являются массивом.");
    return [];
  }
  if (banners.some((banner) => typeof banner !== "object" || banner === null)) {
    console.error("Ошибка: все элементы массива должны быть объектами.");
    return [];
  }
  const requiredKeys = ["banner_id", "name", "size", "position"];
  if (banners.some((banner) => !requiredKeys.every((key) => key in banner))) {
    console.error("Ошибка: некоторые баннеры не содержат необходимых ключей.");
    return [];
  }
  return banners.filter((banner) => banner.position === position);
}

export function excludePostById(
  posts: Post[],
  postId: string | number | undefined
): Post[] {
  if (!Array.isArray(posts)) {
    console.error("Ошибка: posts должен быть массивом.");
    return [];
  }
  const postIdNumber = Number(postId);
  if (isNaN(postIdNumber)) {
    console.error("Ошибка: postId не является числом.");
    return [];
  }
  if (posts.some((post) => post.post_id === undefined)) {
    console.error("Ошибка: некоторые посты не содержат post_id.");
    return [];
  }
  return posts.filter((post) => post.post_id !== postIdNumber);
}

export function excludeVideoById(
  videos: Video[],
  videoId: string | number | undefined
): Video[] {
  if (!Array.isArray(videos)) {
    console.error("Ошибка: videos должен быть массивом.");
    return [];
  }
  const videoIdNumber = Number(videoId);
  if (isNaN(videoIdNumber)) {
    console.error("Ошибка: videoId не является числом.");
    return [];
  }
  if (videos.some((video) => video.video_id === undefined)) {
    console.error("Ошибка: некоторые видео не содержат video_id.");
    return [];
  }
  return videos.filter((video) => video.video_id !== videoIdNumber);
}

export function sortItemsByPublicationDate<T extends { published_dt?: string }>(
  items: T[] | null | undefined
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items
    .map((item) => ({
      ...item,
      publishedDate: item.published_dt ? new Date(item.published_dt) : null,
    }))
    .sort((a, b) => {
      if (!b.publishedDate && !a.publishedDate) {
        return 0;
      } else if (!a.publishedDate) {
        return 1;
      } else if (!b.publishedDate) {
        return -1;
      } else {
        return b.publishedDate.getTime() - a.publishedDate.getTime();
      }
    });
}

export function getTopItems<T extends { published_dt?: string }>(
  items: T[] | null | undefined,
  count: number
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  if (count <= 0) {
    throw new Error("Количество элементов должно быть положительным числом");
  }
  const sortedItems = sortItemsByPublicationDate(items);
  return sortedItems.slice(0, count);
}

export const transformRoundsToOptions = (
  rounds: Rounds | null | undefined
): RoundOption[] => {
  if (!rounds || !Array.isArray(rounds)) {
    throw new Error("Неверный ввод: rounds должны быть массивом");
  }
  return rounds.map((round) => {
    if (!round || typeof round !== "object") {
      throw new Error(
        "Неверный ввод: каждый элемент rounds должен быть объектом"
      );
    }
    return {
      value: round,
      label: `${round.tournament.full_name} ${round.name}`,
    };
  });
};

export function calculateTotalStats(playerStats: PlayerStats[]): TotalStats {
  const totalStats: TotalStats = {
    games: 0,
    assists: 0,
    goals: "",
    goals_corner: 0,
    penalties_12: 0,
    goals_assists: "",
    white_cards: 0,
    red_cards: 0,
    blue_cards: 0,
  };

  playerStats.forEach((stat) => {
    totalStats.games += stat.games;
    totalStats.goals_corner += stat.goals_corner;
    totalStats.penalties_12 += stat.penalties_12;
    totalStats.goals_assists += stat.goals_assists;
    totalStats.white_cards += stat.white_cards;
    totalStats.red_cards += stat.red_cards;
    totalStats.blue_cards += stat.blue_cards;
    totalStats.assists += stat.assists;
    totalStats.goals_assists = (
      Number(stat.goals_assists) + Number(totalStats.goals_assists)
    ).toString();
    totalStats.goals = (
      Number(stat.goals) + Number(totalStats.goals)
    ).toString();
  });

  return totalStats;
}

export const sortByStartDate = (tournaments: Tournaments): Tournaments => {
  return [...tournaments].sort((a, b) => {
    return new Date(a.start_dt) > new Date(b.start_dt) ? -1 : 1;
  });
};

export const filterRoundsByHasTable = (rounds: Rounds): Rounds => {
  if (!Array.isArray(rounds)) {
    console.error("Входные данные не являются массивом объектов");
  }
  return rounds.filter((round) => {
    if (round.has_table === undefined) {
      console.error("Ошибка: В объекте раунда отсутствует поле has_table");
      return false;
    }
    return round.has_table === 1;
  });
};

export const filterMatchesForSlider = (allMatches: Matches, matchesToExclude: Matches): Matches => {
  const excludeIds = new Set(matchesToExclude.map((match) => match.match_id));
  return allMatches.filter((match) => !excludeIds.has(match.match_id));
};