import React from 'react';
import {useParams} from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useEffect} from 'react';
import {fetchPhotoGallery} from '../../core/store/api-actions';
import {
  getCurrentPhotoGallery,
  getPhotoGalleryErrorStatus,
  getPhotoGalleryLoadingStatus
} from '../../core/store/photos-data/selectors';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import PhotosList from '../../components/ordinary/photos-list/photos-list';
import {AppRoute} from "../../core/constants/routes";

function PhotoGallery() {
  const dispatch = useAppDispatch();
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const {id: photoGalleryId} = useParams();
  const currentPhotoGallery = useAppSelector(getCurrentPhotoGallery);
  const isPhotoGalleryError = useAppSelector(getPhotoGalleryErrorStatus);
  const isPhotoGalleryLoading = useAppSelector(getPhotoGalleryLoadingStatus);

  useEffect(() => {
    dispatch(fetchPhotoGallery(Number(photoGalleryId)));
  }, [photoGalleryId]);

  useEffect(() => {
    if (currentPhotoGallery) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.PhotoGalleries,
        {title: currentPhotoGallery.title, url: AppRoute.PhotoGallery + String(currentPhotoGallery.album_id)}
      ]);
    }
  }, [currentPhotoGallery]);

  return (
    <>
      <div hidden={!isPhotoGalleryLoading}>
        <Loader/>
      </div>
      <div hidden={!isPhotoGalleryError}>
        <Error/>
      </div>
      <div className="container-center" hidden={isPhotoGalleryLoading}>
        <main className="full">
          <PhotosList currentPhotos={currentPhotoGallery?.photos}/>
        </main>
      </div>
    </>
  );
}

export default PhotoGallery;
