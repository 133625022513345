import {RoundOption} from "../../../core/types/common";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {DropdownPlaceholders} from "../../../core/constants/filters";
import {
  getWidgetRoundFilter,
  getWidgetTeamFilter
} from "../../../core/store/widget-data/selectors";
import {
  changeWidgetRoundFilter,
  chooseFirstWidgetRoundFilter,
  resetWidgetRoundFilter
} from "../../../core/store/widget-data/widget-data";
import Select, {SingleValue} from "react-select";
import React, {useEffect} from "react";
import {getTeamRounds} from "../../../core/store/team-rounds-data/selectors";
import {
  getRelevantRounds,
  transformRoundsToOptions
} from "../../../core/utils/array-prepare-helpers";
import {fetchTeamRounds} from "../../../core/store/api-actions";
import {RoundsTypes} from "../../../core/constants/common";

function RoundDropdown() {
  const dispatch = useAppDispatch();
  const widgetTeamFilter = useAppSelector(getWidgetTeamFilter);
  const teamRounds = useAppSelector(getTeamRounds);
  const relevantRounds = getRelevantRounds(teamRounds);
  const filteredRounds = relevantRounds.filter(round => round.type !== RoundsTypes.PlayoffOlympic);
  const teamRoundsOptions: RoundOption[] = transformRoundsToOptions(filteredRounds);

  useEffect(() => {
    dispatch(fetchTeamRounds(Number(widgetTeamFilter.value)))
  }, [dispatch, widgetTeamFilter])

  useEffect(() => {
    if (teamRoundsOptions.length > 0) {
      dispatch(chooseFirstWidgetRoundFilter({firstRoundOption: teamRoundsOptions[0]}));
    } else {
      dispatch(resetWidgetRoundFilter());
    }
  }, [dispatch, teamRounds])

  const handleFilterChange = (selectedOption: SingleValue<RoundOption>) => {
    if (selectedOption) {
      dispatch(changeWidgetRoundFilter({widgetRoundFilter: selectedOption}))
    }
  };

  return (
    <Select
      isSearchable={false}
      placeholder={DropdownPlaceholders.Round}
      classNamePrefix="dropdown"
      className="dropdown"
      defaultValue={teamRoundsOptions[0]}
      options={teamRoundsOptions}
      onChange={handleFilterChange}
      value={useAppSelector(getWidgetRoundFilter)}
    />
  );
}

export default RoundDropdown;