import React from 'react';
import {Link} from 'react-router-dom';
import {Post} from '../../../core/types/post';
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {AppRoute} from "../../../core/constants/routes";

function PostPreview({post}: {post: Post | undefined}) {
  const postDate = post && post.published_dt ? new Date(post.published_dt) : null;

  return (
    <div hidden={post === undefined}>
      <Link className="media__preview" to={`${AppRoute.Posts.url}/${post?.post_id}`}>
        <div className="media__content">
          <p className="media__info">Актуальная новость</p>
          <div className="media__container">
            <p className="media__name">{post?.title}</p>
            <p className="media__text">{post?.lead}</p>
            <p className="media__date">{simpleDateFormat(postDate, 'dd.MM.yyyy')}</p>
          </div>
        </div>
        <img className="media__img" src={post?._links.image870x490.href || '/images/defaults/news.svg'} width="930" height="520" alt={post?.title}/>
      </Link>
    </div>
  );
}

export default PostPreview;
