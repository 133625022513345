import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {HeaderNavigationType} from '../../../core/types/navigation';

function HeaderNavigation({headerNavigation, isActive, onClick} : {headerNavigation: HeaderNavigationType, isActive: boolean, onClick: () => void}) {
  const location = useLocation();
  const isCurrentPage = headerNavigation.url === location.pathname;

  return (
    <li className={isActive || isCurrentPage ? 'active' : ''}>
      <Link to={headerNavigation.url} onClick={onClick}>
        {headerNavigation.title}
      </Link>
      <div hidden={headerNavigation.submenu === null}>
        <ul className={isActive ? 'active' : ''}>
          {headerNavigation.submenu?.map((headerSubNavigation) =>
            <HeaderNavigation
              key={headerSubNavigation.title}
              headerNavigation={headerSubNavigation}
              isActive={false}
              onClick={() => {}}
            />
          )}
        </ul>
      </div>
    </li>
  );
}

export default HeaderNavigation;
