import {
  TournamentsState,
  State
} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {
  Tournament,
  TournamentGroups,
  Tournaments,
  TournamentsStatsArray
} from "../../types/tournament";

export const getTournamentsLoadingStatus = (state: State): boolean => state[NameSpace.Tournaments].isTournamentsLoading;
export const getTournamentLoadingStatus = (state: State): boolean => state[NameSpace.Tournaments].isTournamentLoading;
export const getTournamentsErrorStatus = (state: State): TournamentsState['isTournamentsError'] => state[NameSpace.Tournaments].isTournamentsError;
export const getTournamentErrorStatus = (state: State): TournamentsState['isTournamentError'] => state[NameSpace.Tournaments].isTournamentError;
export const getTournaments = (state: State): Tournaments => state[NameSpace.Tournaments].tournaments;
export const getTournament = (state: State): Tournament | null => state[NameSpace.Tournaments].tournament;
export const getTournamentsStatsLoadingStatus = (state: State): boolean => state[NameSpace.Tournaments].isTournamentsStatsLoading;
export const getTournamentsStatsErrorStatus = (state: State): TournamentsState['isTournamentsStatsError'] => state[NameSpace.Tournaments].isTournamentsStatsError;
export const getTournamentsStats = (state: State): TournamentsStatsArray => state[NameSpace.Tournaments].tournamentsStats;
export const getGroupTournamentLoadingStatus = (state: State): boolean => state[NameSpace.Tournaments].isGroupTournamentLoading;
export const getGroupTournamentErrorStatus = (state: State): TournamentsState['isGroupTournamentError'] => state[NameSpace.Tournaments].isGroupTournamentError;
export const getGroupTournament = (state: State): TournamentGroups => state[NameSpace.Tournaments].groupTournament;