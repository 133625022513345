import {createSlice} from '@reduxjs/toolkit';
import {
  fetchPhotoGalleries,
  fetchPhotoGallery
} from '../api-actions';
import {PhotosState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: PhotosState = {
  isPhotoGalleriesLoading: false,
  isPhotoGalleryLoading: false,
  isPhotoGalleriesError: false,
  isPhotoGalleryError: false,
  photoGalleries: [],
  currentPhotoGallery: null,
};

export const photosData = createSlice({
  name: NameSpace.Photos,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPhotoGalleries.pending, (state) => {
        state.isPhotoGalleriesLoading = true;
      })
      .addCase(fetchPhotoGalleries.fulfilled, (state, action) => {
        state.photoGalleries = action.payload;
        state.isPhotoGalleriesLoading = false;
      })
      .addCase(fetchPhotoGalleries.rejected, (state) => {
        state.isPhotoGalleriesError = true;
        state.isPhotoGalleriesLoading= false;
      })
      .addCase(fetchPhotoGallery.pending, (state) => {
        state.isPhotoGalleryLoading = true;
      })
      .addCase(fetchPhotoGallery.fulfilled, (state, action) => {
        state.currentPhotoGallery = action.payload;
        state.isPhotoGalleryLoading = false;
      })
      .addCase(fetchPhotoGallery.rejected, (state) => {
        state.isPhotoGalleriesError = true;
        state.isPhotoGalleryLoading = false;
      });
  }
});
