import React, {useEffect} from 'react';
import {fetchMatchReferees} from "../../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {getMatchReferees} from "../../../core/store/matches-data/selectors";
import {useParams} from "react-router-dom";

function MatchReferees() {
  const dispatch = useAppDispatch();
  const {matchId: matchId} = useParams();
  const referees = useAppSelector(getMatchReferees);

  useEffect(() => {
    dispatch(fetchMatchReferees(Number(matchId)));
  }, [matchId]);

  return (
    <table className="stat-table char" hidden={referees.length == 0}>
      <tbody>
      <tr>
        <th colSpan={2}>Судейская бригада</th>
      </tr>
      <tr>
        <td colSpan={2}>
          {referees.map((referee, index) => (
            <span key={referee.referee_id}>
              {`${referee.last_name} ${referee.first_name.charAt(0)}.`}
              {referee.city?.title && `(${referee.city.title})`}
              {index !== referees.length - 1 && ', '}
            </span>
          ))}
        </td>
      </tr>
      </tbody>
    </table>
  );
}

export default MatchReferees;
