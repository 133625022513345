import React, { useEffect } from "react";
import Message from "../message/message";
import Title from "../title/title";
import TournamentTableRow from "../tournament-table-row/tournament-table-row";
import { tournamentTableHeaders } from "../../../core/constants/tournament";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  fetchTeamsTablesGroup,
  fetchTournamentTeams,
} from "../../../core/store/api-actions";
import {
  findById,
  findTeamById,
} from "../../../core/utils/array-prepare-helpers";
import { useParams } from "react-router-dom";
import { Round } from "../../../core/types/rounds";
import { getTournamentTeams } from "../../../core/store/teams-data/selectors";
import { getTeamsTablesGroups } from "../../../core/store/teams-tables-data/selectors";

function TournamentTable({ round }: { round: Round | undefined }) {
  const dispatch = useAppDispatch();
  const { id: tournamentId } = useParams();
  const tableTeamsGroups = useAppSelector(getTeamsTablesGroups);
  const tableTeamsGroup =
    tableTeamsGroups.length > 0
      ? findById(tableTeamsGroups, round?.round_id)
      : null;
  const teams = useAppSelector(getTournamentTeams);

  useEffect(() => {
    dispatch(fetchTournamentTeams(Number(tournamentId)));
  }, [tournamentId, dispatch]);

  useEffect(() => {
    dispatch(
      fetchTeamsTablesGroup({
        tournamentId: Number(tournamentId),
        roundId: round?.round_id,
      })
    );
  }, [dispatch, tournamentId, round?.round_id]);

  return (
    <>
      <Title title={round?.name} />
      <table className="stat-table" hidden={tableTeamsGroup === undefined}>
        <thead>
          <tr>
            {tournamentTableHeaders.map((tournamentTableHeader) => (
              <th key={tournamentTableHeader}>{tournamentTableHeader}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableTeamsGroup?.teamsTables
            .slice()
            .sort((a, b) => b.points - a.points)
            .map((tableTeam, index) => (
              <TournamentTableRow
                key={tableTeam.id}
                team={findTeamById(teams, tableTeam.team_id)}
                tableTeam={tableTeam}
                index={index + 1}
              />
            ))}
        </tbody>
      </table>
      <div hidden={tableTeamsGroup !== undefined}>
        <Message message="Информация отсутствует" />
      </div>
    </>
  );
}

export default TournamentTable;
