import React from 'react';
import {ProcurementType} from '../../../core/types/procurement';
import HtmlRenderer from '../../smart/html-renderer/html-renderer';
import {withTextDateFormat, yearDateFormat} from "../../../core/utils/date-time-helpers";
import {Link} from "react-router-dom";

function ProcurementItem({procurement}: {procurement: ProcurementType}) {
  const procurementDate = new Date(procurement.end_date);

  return (
    <li>
      <div className="number">{procurement.code}/{yearDateFormat(procurementDate)}</div>
      <div className="title">{procurement.title}<p>{procurement.category?.title}</p></div>
      {withTextDateFormat(procurementDate, 'Дата окончания подачи заявок:')}
      <div className="files">
        <span><Link to={procurement._links.document.href}>{procurement.document}</Link> {procurement.documentSize} {procurement.documentExtension}</span>
        <span><Link to={procurement._links.protocol.href}>{procurement.protocol}</Link> {procurement.protocolSize} {procurement.protocolExtension}</span>
      </div>
      <div className="desc">
        <HtmlRenderer htmlString={procurement.description} />
      </div>
    </li>
  );
}

export default ProcurementItem;
