import React from "react";
import { Link, useParams } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { MatchGoal } from "../../../core/types/match";

function MatchEventItem({
  matchGoal,
  direction,
}: {
  matchGoal: MatchGoal;
  direction: string;
}) {
  const { tournamentId: tournamentId } = useParams();
  return (
    <td>
      <div style={{ textAlign: direction === "left" ? "left" : "right" }}>
        {matchGoal.timeline_score && `${matchGoal.timeline_score}. `}
        <Link
          to={`${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Players.url}/${matchGoal.player_id}`}
        >
          {matchGoal.player.last_name} {matchGoal.player.first_name[0]}.
        </Link>{" "}
        {matchGoal.assistant && (
          <>
            (
            <Link
              to={`${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Players.url}/${matchGoal.assistant.player_id}`}
            >
              {`${matchGoal.assistant.last_name} ${matchGoal.assistant.first_name[0]}.`}
            </Link>
            )
          </>
        )}
        {matchGoal.minute ? `, ${matchGoal.minute + 1}'` : ""}{" "}
        {matchGoal.situationAbbr ? `, ${matchGoal.situationAbbr}` : ""}
      </div>
    </td>
  );
}

export default MatchEventItem;
