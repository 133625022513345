import React, { useEffect } from "react";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { Match } from "../../../core/types/match";
import { fetchGroupTournament } from "../../../core/store/api-actions";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import { getGroupTournament } from "../../../core/store/tournaments-data/selectors";
import { findGroupTournamentById } from "../../../core/utils/array-prepare-helpers";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import MatchTabsTeam from "../../smart/match-tabs-team/match-tabs-team";
import MatchTabsStadium from "../../smart/match-tabs-stadium/match-tabs-stadium";
import { formatValue } from "../../../core/utils/common-helpers";

function MatchTabsItem({
  match,
  hidden,
}: {
  match: Match | null | undefined;
  hidden: boolean;
}) {
  const dispatch = useAppDispatch();
  const startDate = match && match.start_dt ? new Date(match.start_dt) : null;
  const groupTournaments = useAppSelector(getGroupTournament);
  const groupTournament = findGroupTournamentById(
    groupTournaments,
    match?.tournament_id
  );

  useEffect(() => {
    if (match?.tournament_id && !groupTournament) {
      dispatch(fetchGroupTournament(match.tournament_id));
    }
  }, [match?.tournament_id, groupTournament, dispatch]);

  return (
    <div className="top-game" hidden={hidden}>
      <div className="title">
        {groupTournament?.tournament?.full_name}<br />
        <MatchTabsStadium stadiumId={match?.stadium_id} />
      </div>
      <div className="result">
        <MatchTabsTeam teamId={Number(match?.team1_id)} />
        <div className="count">
          <b>{formatValue(match?.gf)}</b>
          <span>:</span>
          <b>{formatValue(match?.ga)}</b>
        </div>
        <MatchTabsTeam teamId={Number(match?.team2_id)} />
      </div>
      <div className="desc">
        {simpleDateFormat(startDate, "dd MMMM yyyy, HH:mm")}
        <Link
          to={`${AppRoute.Tournaments.url}/${match?.tournament_id}/calendar/${match?.match_id}`}
        >
          Протокол
        </Link>
      </div>
    </div>
  );
}

export default MatchTabsItem;
