import React, { useEffect, useState } from "react";
import PartnersWidget from "../../components/ordinary/partners-widget/partners-widget";
import PlayerHeadCard from "../../components/ordinary/player-head-card/player-head-card";
import PlayerButtons from "../../components/ordinary/player-buttons/player-buttons";
import PlayerInfo from "../../components/ordinary/player-info/player-info";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import {
  getPlayer,
  getPlayerErrorStatus,
  getPlayerLoadingStatus,
} from "../../core/store/players-data/selectors";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import { fetchPlayer } from "../../core/store/api-actions";
import { useParams } from "react-router-dom";
import { AppRoute } from "../../core/constants/routes";
import Loader from "../../components/ordinary/loader/loader";
import Error from "../error/error";

function Player() {
  const dispatch = useAppDispatch();
  const { id: playerId } = useParams();
  const player = useAppSelector(getPlayer);
  const isPlayerError = useAppSelector(getPlayerErrorStatus);
  const isPlayerLoading = useAppSelector(getPlayerLoadingStatus);
  const { setBreadcrumbs } = useBreadcrumbsContext();

  useEffect(() => {
    dispatch(fetchPlayer(Number(playerId)));
  }, [playerId]);

  useEffect(() => {
    if (player) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.AboutClub,
        {
          title: `${player.last_name} ${player.first_name} ${player.middle_name}`,
          url: "#",
        },
      ]);
    }
  }, [player]);

  const [selectedView, setSelectedView] = useState<
    "bio" | "statistics" | "career" | "matches"
  >("bio");
  const handleSwitchView = (
    view: "bio" | "statistics" | "career" | "matches"
  ) => {
    setSelectedView(view);
  };
  return (
    <>
      <div hidden={!isPlayerLoading}>
        <Loader />
      </div>
      <div hidden={!isPlayerError}>
        <Error />
      </div>
      <div className="container-center" hidden={isPlayerLoading}>
        <div className="player">
          <PlayerHeadCard player={player}/>
          <PlayerButtons
            selectedView={selectedView}
            onSwitchView={handleSwitchView}
          />
          <PlayerInfo selectedView={selectedView} player={player} />
        </div>
        <PartnersWidget />
      </div>
    </>
  );
}
export default Player;
