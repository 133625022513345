import React from 'react';
import TrophiesItem from "../trophies-item/trophies-item";
import {Trophies} from "../../../core/types/trophy";

function TrophiesList({trophies}: { trophies: Trophies }) {
  return (
    <ul className="trophies">
      {trophies.map((trophy) => <TrophiesItem key={trophy.trophy_id} trophy={trophy}/>)}
    </ul>
  );
}

export default TrophiesList;
