import React, {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {useAppSelector} from "../../core/hooks";
import {fetchCountryCupsPage} from "../../core/store/api-actions";
import {store} from "../../core/store";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import {CountryCupsNavigations} from "../../core/constants/navigations";
import {getPosts} from "../../core/store/posts-data/selectors";
import {filterPostsByTag} from "../../core/utils/array-prepare-helpers";
import {
  getCountryCupsPage,
  getCountryCupsPageErrorStatus,
  getCountryCupsPageLoadingStatus
} from "../../core/store/clubs-data/selectors";
import Page from "../../components/ordinary/page/page";

store.dispatch(fetchCountryCupsPage());

function CountryCups() {
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByTag(posts, AppRoute.CountryCups.title);
  const countryCupsPage = useAppSelector(getCountryCupsPage);
  const isCountryCupsPageLoading = useAppSelector(getCountryCupsPageLoadingStatus);
  const isCountryCupsPageError = useAppSelector(getCountryCupsPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.History, AppRoute.CountryCups]);
  }, [setBreadcrumbs]);

  return (
    <div className="container-center">
      <main>
        <Page content={countryCupsPage?.text} isPageLoading={isCountryCupsPageLoading} isPageError={isCountryCupsPageError}/>
        <div className="block-press other">
          <div className="container-center">
            <PostsList currentPosts={filteredPosts} tag={AppRoute.CountryCups.title}/>
          </div>
        </div>
      </main>
      <aside>
        <SidebarNavigation navigationArray={CountryCupsNavigations}/>
      </aside>
    </div>
  );
}

export default CountryCups;