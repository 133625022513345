import React, {useEffect} from 'react';
import {useBreadcrumbsContext} from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import {AppRoute} from "../../core/constants/routes";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import {ClubNavigations} from "../../core/constants/navigations";
import {useAppSelector} from "../../core/hooks";
import {addTeamsToNavigation, filterAndFindClub} from "../../core/utils/common-helpers";
import {getClubs, getClubsErrorStatus, getClubsLoadingStatus} from "../../core/store/clubs-data/selectors";
import {store} from "../../core/store";
import {fetchClubs, fetchTeams} from "../../core/store/api-actions";
import Page from "../../components/ordinary/page/page";
import ClubContent from "../../components/ordinary/club-content/club-content";
import {getTeams} from "../../core/store/teams-data/selectors";
import {filterTeamsByClubId} from "../../core/utils/array-prepare-helpers";

store.dispatch(fetchClubs());
store.dispatch(fetchTeams());

function AboutClub() {
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const clubs = useAppSelector(getClubs);
  const isClubsLoading = useAppSelector(getClubsLoadingStatus);
  const isClubsError = useAppSelector(getClubsErrorStatus);
  const allTeams = useAppSelector(getTeams);
  const mainClub = filterAndFindClub(clubs, 'Енисей');
  const teams = filterTeamsByClubId(allTeams, mainClub?.club_id)
  const clubNavigations = ClubNavigations;

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub]);
  }, [setBreadcrumbs]);

  addTeamsToNavigation(teams, clubNavigations)

  return (
    <div className="container-center">
      <main>
        <Page content={mainClub?.description} isPageLoading={isClubsLoading} isPageError={isClubsError}/>
        <ClubContent/>
      </main>
      <aside>
        <SidebarNavigation navigationArray={clubNavigations}/>
      </aside>
    </div>
  );
}

export default AboutClub;
