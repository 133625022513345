import React from "react";
import {
  Link,
  useParams
} from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { Team } from "../../../core/types/team";

function MatchTeamUnit({ team }: { team?: Team }) {
  const { tournamentId: tournamentId } = useParams();
  const teamLogo = team?._links.logo.href.includes('logo_placeholder_thumb.png') ? '/images/defaults/team.svg' : team?._links.logo.href;
  const teamLink = `${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Teams.url}/${team?.team_id}`;

  return (
    <div className="team-title">
      <div className="team-logo">
        <img src={teamLogo} alt={team?.full_name} />
      </div>
      <Link to={teamLink}>{team?.full_name}</Link>
      <p>{team?.city?.title || 'Город не указан'}</p>
    </div>
  );
}

export default MatchTeamUnit;
