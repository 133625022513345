import React from 'react';
import SidebarFilterItem from "../sidebar-filter-item/sidebar-filter-item";
import Title from "../../ordinary/title/title";

function SidebarFilter({activeSection, onSectionClick, filterArray} : {activeSection: string | null, onSectionClick: (sectionTitle: string) => void, filterArray: string[]}) {
  return (
    <>
      <Title title="Разделы" />
      <ul className="root-list">
        {filterArray.map((filterItem) => <SidebarFilterItem key={filterItem} activeSection={activeSection} onSectionClick={onSectionClick} filterItem={filterItem}/>)}
      </ul>
    </>
  );
}

export default SidebarFilter;