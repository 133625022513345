import React from "react";
import PlayerBiography from "../player-biography/player-biography";
import PlayerStatistics from "../player-statistics/player-statistics";
import PlayerCareer from "../player-career/player-career";
import PlayerMatches from "../player-matches/player-matches";
import { PlayerItem } from "../../../core/types/player";

function PlayerInfo({
  selectedView,
  player,
}: {
  selectedView: string;
  player: PlayerItem | null;
}) {
  return (
    <div className="pbutton__content" id="tabs-content-player">
      {selectedView === "bio" && <PlayerBiography player={player} />}
      {selectedView === "statistics" && <PlayerStatistics />}
      {selectedView === "career" && <PlayerCareer player={player} />}
      {selectedView === "matches" && <PlayerMatches player={player} />}
    </div>
  );
}
export default PlayerInfo;
