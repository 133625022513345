import React from 'react';
import {Post} from "../../../core/types/post";
import Title from "../title/title";
import PostItem from "../post-item/post-item";

function SidebarInformation({post}: {post: Post}) {
  return (
    <div hidden={post === undefined}>
      <Title title="Информация"/>
      <div className="block-press">
        <ul className="articles-list">
          {post ? <PostItem post={post}/> : ''}
        </ul>
      </div>
    </div>
  );
}

export default SidebarInformation;
