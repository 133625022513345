import React from 'react';
import Title from "../title/title";
import {TournamentsStats} from "../../../core/types/tournament";

function TournamentStats({tournamentStats}: {tournamentStats: TournamentsStats | undefined}) {
  return (
    <>
      <Title title="Мячи"/>
      <table className="stat-table char">
        <tbody>
        <tr>
          <th>Характеристика</th>
          <th>Показатель</th>
        </tr>
        <tr>
          <td>Всего забито мячей</td>
          <td>{tournamentStats?.stats?.goals}</td>
        </tr>
        <tr>
          <td>Забито мячей хозяевами</td>
          <td>{tournamentStats?.stats?.goals_home}</td>
        </tr>
        <tr>
          <td>Забито мячей гостями</td>
          <td>{tournamentStats?.stats?.goals_away}</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default TournamentStats;
