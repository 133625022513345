import React from "react";
import Select from "react-select";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {Option} from "../../../core/types/common";
import {
  DropdownPlaceholders,
  DropdownTypes,
  ItemsForFilter
} from "../../../core/constants/filters";
import {getWidgetTeamFilter} from "../../../core/store/widget-data/selectors";
import {changeWidgetTeamFilter} from "../../../core/store/widget-data/widget-data";

function Dropdown({options, type, items}: {
  options: Option[],
  type: string,
  items: string
}) {
  const dispatch = useAppDispatch();
  const createPlaceholder = (type: string) => {
    switch (type) {
      case DropdownTypes.Team:
        return DropdownPlaceholders.Team;
      default:
        return DropdownPlaceholders.Undefined;
    }
  };

  const createValue = (items: string) => {
    switch (type) {
      case DropdownTypes.Team:
        switch (items) {
          case ItemsForFilter.TableTeams:
            return useAppSelector(getWidgetTeamFilter);
        }
        break
    }
  };

  const handleFilterChange = (selectedOption: Option | null) => {
    if (selectedOption) {
      switch (type) {
        case DropdownTypes.Team:
          switch (items) {
            case ItemsForFilter.TableTeams:
              dispatch(changeWidgetTeamFilter({widgetTeamFilter: selectedOption}));
              break;
            default:
              break;
          }
          break;
      }
    }
  };

  return (
    <Select
      isSearchable={false}
      placeholder={createPlaceholder(type)}
      classNamePrefix="dropdown"
      className="dropdown"
      defaultValue={options[0]}
      options={options}
      onChange={handleFilterChange}
      value={createValue(items)}
    />
  );
}

export default Dropdown;
