import React from 'react';
import {Option} from "../../../core/types/common";
import ButtonsItem from "../buttons-item/buttons-item";
import {playersTypeOptions} from "../../../core/constants/common";

function ButtonsList() {
  return (
    <ul className="buttons__navigation">
      {playersTypeOptions.map((option: Option) => (
        <ButtonsItem key={option.value} option={option}/>
      ))}
    </ul>
  );
}

export default ButtonsList;