import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import React, {useEffect} from "react";
import {fetchGroupTeam} from "../../../core/store/api-actions";
import {getGroupTeam} from "../../../core/store/teams-data/selectors";
import {findById} from "../../../core/utils/array-prepare-helpers";

function MatchesSliderTeam({teamId}: {teamId: number}) {
  const dispatch = useAppDispatch();
  const teamGroups = useAppSelector(getGroupTeam);
  const teamGroup = findById(teamGroups, teamId);

  useEffect(() => {
    if (teamId) {
      dispatch(fetchGroupTeam(teamId));
    }
  }, [teamId, dispatch])

  return (
    <div className="matches__team">
      <img className="matches__img" src={teamGroup?.team._links.logo.href.includes('logo_placeholder_thumb.png') ? '/images/defaults/team.svg' : teamGroup?.team._links.logo.href} width="40" height="40" alt={teamGroup?.team.full_name} loading="lazy"/>
      <p className="matches__name">{teamGroup?.team.full_name}</p>
    </div>
  );
}

export default MatchesSliderTeam;