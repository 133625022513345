export const postsSliderConfig = {
  container: '.slides__list',
  controlsContainer: `.js-posts-controls`,
  controls: true,
  nav: true,
  navContainer: `.js-posts-nav`,
  items: 3,
  loop: true,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayOverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  mouseDrag: true,
  gutter: 20,
  touch: true,
  responsive: {
    320: {
      items: 1,
      nav: true
    },
    780: {
      items: 2,
      nav: false
    },
    1250: {
      items: 3
    }
  }
};

export const photosSliderConfig = {
  container: '.slides__list',
  controlsContainer: `.js-photos-controls`,
  controls: true,
  nav: true,
  navContainer: `.js-photos-nav`,
  items: 3,
  loop: true,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayOverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  mouseDrag: true,
  gutter: 20,
  touch: true,
  responsive: {
    320: {
      items: 1,
      nav: true
    },
    780: {
      items: 2,
      nav: false
    },
    1250: {
      items: 3
    }
  }
};

export const videosSliderConfig = {
  container: '.slides__list',
  controlsContainer: `.js-videos-controls`,
  controls: true,
  nav: true,
  navContainer: `.js-videos-nav`,
  items: 3,
  loop: false,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayOverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  mouseDrag: true,
  gutter: 20,
  touch: true,
  responsive: {
    320: {
      items: 1,
      nav: true
    },
    780: {
      items: 2,
      nav: false
    },
    1250: {
      items: 3
    }
  }
};

export const matchesSliderConfig = {
  container: '.matches__list',
  controlsContainer: `.js-matches-controls`,
  controls: true,
  nav: true,
  navContainer: `.js-matches-nav`,
  items: 3,
  loop: false,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayOverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  mouseDrag: true,
  gutter: 20,
  touch: true,
  responsive: {
    320: {
      items: 1,
      nav: true
    },
    780: {
      items: 2,
      nav: false
    },
    1250: {
      items: 3
    }
  }
};

export const bannersSliderConfig = {
  container: '.matches__list',
  controls: false,
  nav: false,
  items: 1,
  loop: false,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayOverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  mouseDrag: true,
  touch: true,
};