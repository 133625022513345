import {FeedbacksState, State} from '../../types/state';
import {NameSpace} from '../../constants/api-constants';
import {Feedbacks} from "../../types/feedback";
import {PageType} from "../../types/common";

export const getFeedbacksLoadingStatus = (state: State): boolean => state[NameSpace.Feedbacks].isFeedbacksLoading;
export const getFeedbacksErrorStatus = (state: State): FeedbacksState['isFeedbacksError'] => state[NameSpace.Feedbacks].isFeedbacksError;
export const getFeedbacks = (state: State): Feedbacks => state[NameSpace.Feedbacks].feedbacks;
export const getFeedbacksPageLoadingStatus = (state: State): boolean => state[NameSpace.Feedbacks].isFeedbacksPageLoading;
export const getFeedbacksPageErrorStatus = (state: State): FeedbacksState['isFeedbacksPageError'] => state[NameSpace.Feedbacks].isFeedbacksPageError;
export const getFeedbacksPage = (state: State): PageType | null => state[NameSpace.Feedbacks].feedbacksPage;