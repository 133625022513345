import React from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {
  fetchTournament,
  fetchTournamentMatches
} from '../../core/store/api-actions';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import 'font-awesome/css/font-awesome.min.css';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {
  getTournament,
  getTournamentErrorStatus,
  getTournamentLoadingStatus
} from "../../core/store/tournaments-data/selectors";
import {TournamentsNavigations} from "../../core/constants/navigations";
import TournamentNavigation from "../../components/ordinary/tournament-navigation/tournament-navigation";
import {
  groupMatchesByDay,
  sortGroupedMatchesByDate
} from "../../core/utils/array-prepare-helpers";
import {simpleDateFormat} from "../../core/utils/date-time-helpers";
import TournamentMatches from "../../components/ordinary/tournament-matches/tournament-matches";
import {getTournamentMatches} from "../../core/store/matches-data/selectors";
import {tournamentTypes} from "../../core/constants/tournament";

function TournamentCalendar() {
  const dispatch = useAppDispatch();
  const {id: tournamentId} = useParams();
  const tournament = useAppSelector(getTournament);
  const isTournamentError = useAppSelector(getTournamentErrorStatus);
  const isTournamentLoading = useAppSelector(getTournamentLoadingStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const matches = useAppSelector(getTournamentMatches);
  const groupedMatches = groupMatchesByDay(matches);
  const sortedMatches = sortGroupedMatchesByDate(groupedMatches);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchTournament(Number(tournamentId)));
        await dispatch(fetchTournamentMatches(Number(tournamentId)));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [tournamentId]);


  useEffect(() => {
    if (tournament) {
      setBreadcrumbs([
        AppRoute.Main,
        {
          title: 'Статистика',
          url: AppRoute.Tournaments.url
        },
        {
          title: `${tournamentTypes[tournament.category]}.${tournament.full_name}`,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/info`
        },
        {
          title: AppRoute.Calendar.title,
          url: '#'
        }
      ]);
    }
  }, [tournament]);

  return (
    <>
      <div className="container-center">
        <main>
          <div hidden={!isTournamentLoading}>
            <Loader/>
          </div>
          <div hidden={!isTournamentError || isTournamentLoading}>
            <Error/>
          </div>
          <div className="stat" hidden={isTournamentError || isTournamentLoading}>
            {sortedMatches.map((sortedMatchesItem) => <TournamentMatches key={sortedMatchesItem.date} tournament={Number(tournamentId)} title={simpleDateFormat(new Date(sortedMatchesItem.date), "d MMMM yyyy 'года,' EEEE:")} matches={sortedMatchesItem.matches}/>)}
          </div>
        </main>
        <aside>
          <TournamentNavigation navigationArray={TournamentsNavigations} id={tournamentId}/>
        </aside>
      </div>
    </>
  );
}

export default TournamentCalendar;
