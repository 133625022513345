import React from "react";
import {TeamsTable} from "../../../core/types/teams-tables";
import {Team} from "../../../core/types/team";

function TablesWidgetRow({tableTeam, index, team}: {
  tableTeam: TeamsTable,
  index: number,
  team?: Team;
}) {
  return (
    <tr hidden={team === undefined}>
      <td>{index + 1}</td>
      <td className={team?.team_id === 1 || team?.team_id === 2 ? 'myteam' : ''}>
        <div className="logo">
          <img
            src={team?._links.logo.href.includes('logo_placeholder_thumb.png') ? '/images/defaults/team.svg' : team?._links.logo.href}
            alt={team?.full_name} loading="lazy"/>
        </div>
        {team?.full_name}
      </td>
      <td>{tableTeam.games}</td>
      <td>{tableTeam.wins}</td>
      <td>{tableTeam.draws}</td>
      <td>{tableTeam.losses}</td>
      <td>{tableTeam.gf}-{tableTeam.ga} <span className="tb_g">{tableTeam.gf - tableTeam.ga >= 0 ? '+' : '-'}{Math.abs(tableTeam.gf - tableTeam.ga)}</span>
      </td>
      <td>{tableTeam.points}</td>
    </tr>
  );
}

export default TablesWidgetRow;