import React, {useEffect, useRef, useState} from "react";
import HeaderNavigation from "../header-navigation/header-navigation";
import {HeaderNavigationType} from "../../../core/types/navigation";
import {HeaderNavigations} from "../../../core/constants/navigations";
import {addTeamsToSubMenu, filterAndFindClub} from "../../../core/utils/common-helpers";
import {useAppSelector} from "../../../core/hooks";
import {getTeams} from "../../../core/store/teams-data/selectors";
import {filterTeamsByClubId} from "../../../core/utils/array-prepare-helpers";
import {store} from "../../../core/store";
import {fetchClubs, fetchTeams} from "../../../core/store/api-actions";
import {getClubs} from "../../../core/store/clubs-data/selectors";

store.dispatch(fetchClubs());
store.dispatch(fetchTeams());

function Navigation() {
  const [isMobileMenuActive, setMobileMenuActive] = useState(false);
  const [activeNavigation, setActiveNavigation] = useState<HeaderNavigationType | null>(null);
  const menuRef = useRef<HTMLUListElement>(null);
  const clubs = useAppSelector(getClubs);
  const teams = useAppSelector(getTeams);
  const mainClub = filterAndFindClub(clubs, 'Енисей');
  const filteredTeams = filterTeamsByClubId(teams, mainClub?.club_id)

  const headerNavigations = HeaderNavigations;

  addTeamsToSubMenu(filteredTeams, headerNavigations)

  const handleMobileMenuClick = () => {
    setMobileMenuActive(!isMobileMenuActive);
  };

  const handleNavigationClick = (navigation: HeaderNavigationType) => {
    if (activeNavigation === navigation) {
      setActiveNavigation(null);
    } else {
      setActiveNavigation(navigation);
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setActiveNavigation(null);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="main-menu">
      <span id="mobmenu" onClick={handleMobileMenuClick}>
        <i className="fa fa-bars" aria-hidden="true"/>
      </span>
      <ul className={`mobile-menu ${isMobileMenuActive ? 'active' : ''}`} ref={menuRef}>
        {headerNavigations.map((headerNavigation) =>
          <HeaderNavigation
            key={headerNavigation.title}
            headerNavigation={headerNavigation}
            isActive={activeNavigation === headerNavigation}
            onClick={() => handleNavigationClick(headerNavigation)}
          />
        )}
      </ul>
    </nav>
  );
}

export default Navigation;