import React from 'react';
import {Teams} from "../../../core/types/team";
import TournamentTeamRow from "../tournament-team-row/tournament-team-row";

function TournamentTeamTable({teams}: {teams: Teams}) {
  return (
    <table className="stat-table char">
      <tbody>
      <tr>
        <th>Команда</th>
        <th>Данные</th>
      </tr>
      {teams.map((team) => <TournamentTeamRow key={team.team_id} team={team}/>)}
      </tbody>
    </table>
  );
}

export default TournamentTeamTable;
