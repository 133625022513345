import React, { useEffect } from "react";
import Title from "../title/title";
import { Round } from "../../../core/types/rounds";
import { useParams } from "react-router-dom";
import { fetchChess } from "../../../core/store/api-actions";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getChess,
  getChessErrorStatus,
  getChessLoadingStatus,
} from "../../../core/store/chess-data/selectors";
import TournamentChessRow from "../tournament-chess-row/tournament-chess-row";
import Error from "../../../pages/error/error";
import Loader from "../loader/loader";

function TournamentChessTable({ round }: { round: Round }) {
  const dispatch = useAppDispatch();
  const { id: tournamentId } = useParams();
  const chess = useAppSelector(getChess);
  const isChessErrorStatus = useAppSelector(getChessErrorStatus);
  const isChessLoadingStatus = useAppSelector(getChessLoadingStatus);
  useEffect(() => {
    if (tournamentId && round?.round_id) {
      dispatch(
        fetchChess({
          tournamentId: Number(tournamentId),
          roundId: round.round_id,
        })
      );
    }
  }, [tournamentId, dispatch, round?.round_id]);

  const positions = chess?.positions || [];

  if (!round) return null;

  return (
    <>
      <div hidden={!isChessLoadingStatus}>
        <Loader />
      </div>
      <div hidden={!isChessErrorStatus}>
        <Error />
      </div>
      <Title title={round.name} />
      {round && (
        <table className="stat-table">
          <tbody>
            <tr>
              <th style={{ width: "5%" }}>№</th>
              <th style={{ width: "40%" }}>Команда</th>
              {chess &&
                positions.map((team, columnIndex) => (
                  <th key={columnIndex}>{Number(columnIndex) + 1}</th>
                ))}
            </tr>
            {chess &&
              positions.map((team, rowIndex) => {
                const matches = chess[rowIndex];
                return (
                  matches && (
                    <TournamentChessRow
                      key={rowIndex}
                      matches={matches}
                      rowIndex={rowIndex.toString()}
                      positions={positions}
                    />
                  )
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
}

export default TournamentChessTable;
