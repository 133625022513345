import React from 'react';
import TinySlider from 'tiny-slider-react';
import "tiny-slider/dist/tiny-slider.css";
import {PhotoGalleries} from "../../../core/types/media";
import PhotosSliderItem from "../photos-slider-item/photos-slider-item";
import SliderArrows from "../slider-arrows/slider-arrows";
import PointsItem from "../../simple/points-item/points-item";
import {photosSliderConfig} from "../../../core/config/slider-config";

function PhotosSlider({photos}: {photos: PhotoGalleries}) {
  return (
    <div hidden={photos.length === 0} className="slider slider--dark">
      <TinySlider settings={photosSliderConfig}>
        {photos.map((photo) =>
          <PhotosSliderItem key={photo.album_id} photo={photo}/>
        )}
      </TinySlider>
      <SliderArrows type="photos"/>
      <ul className="points points--dark js-photos-nav">
        {photos.map((photo) =>
          <PointsItem key={photo.album_id}/>
        )}
      </ul>
    </div>
  );
}

export default PhotosSlider;