import React from 'react';
import {Link} from "react-router-dom";

function Title({title, url}: {
  title?: string | undefined | null,
  url?: string
}) {
  return (
    <h2 className="title-h" hidden={title === undefined}>
      {url ? <Link to={url}>{title || ''}</Link> : <>{title || ''}</>}
    </h2>
  );
}

export default Title;