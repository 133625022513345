import React from 'react';
import {Link} from "react-router-dom";

function OrganizationsLinks() {
  return (
    <>
      <Link to="http://www.krskstate.ru/" target="_blank" rel="noreferrer">
        <img src="images/logo-gov.png" width="110" height="36" alt="Лого Красноярского Правительства"/>
      </Link>
      <Link to="http://www.kraysport.ru" target="_blank" rel="noreferrer">
        <img src="images/logo-msp.png" width="110" height="36" alt="Лого Красноярского Министерства спорта"/>
      </Link>
      <Link to="http://www.rusbandy.ru" target="_blank" rel="noreferrer">
        <img src="images/logo-fhmr.png" width="110" height="36" alt="Лого Красноярского Федерации Хокея с мячом"/>
      </Link>
    </>
  );
}

export default OrganizationsLinks;