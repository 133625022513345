import React, {ReactElement} from 'react';
import {createContext, useContext, useState} from 'react';
import {BreadcrumbsContextValue, BreadcrumbsType} from '../../../core/types/breadcrumbs';

const BreadcrumbsContext = createContext<BreadcrumbsContextValue | undefined>(undefined);

export const useBreadcrumbsContext = () => {
  const context = useContext(BreadcrumbsContext);
  if (!context) {
    throw new Error('Контекст использования Breadcrumbs должен использоваться в BreadcrumbsProvider');
  }
  return context;
};

export const BreadcrumbsProvider = ({children}: {children: ReactElement}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsType>([]);

  return (
    <BreadcrumbsContext.Provider value={{breadcrumbs, setBreadcrumbs}}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
