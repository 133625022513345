import {ClubsState, State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {Clubs} from "../../types/club";
import {PageType} from "../../types/common";

export const getClubsLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isClubsLoading;
export const getClubsErrorStatus = (state: State): ClubsState['isClubsError'] => state[NameSpace.Clubs].isClubsError;
export const getClubs = (state: State): Clubs => state[NameSpace.Clubs].clubs;
export const getClubAttributesPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isClubAttributesPageLoading;
export const getClubAttributesPageErrorStatus = (state: State): ClubsState['isClubAttributesPageError'] => state[NameSpace.Clubs].isClubAttributesPageError;
export const getClubAttributesPage = (state: State): PageType | null => state[NameSpace.Clubs].clubAttributesPage;
export const getInternationalMatchesPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isInternationalMatchesPageLoading;
export const getInternationalMatchesPageErrorStatus = (state: State): ClubsState['isInternationalMatchesPageError'] => state[NameSpace.Clubs].isInternationalMatchesPageError;
export const getInternationalMatchesPage = (state: State): PageType | null => state[NameSpace.Clubs].internationalMatchesPage;
export const getCountryCupsPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isCountryCupsPageLoading;
export const getCountryCupsPageErrorStatus = (state: State): ClubsState['isCountryCupsPageError'] => state[NameSpace.Clubs].isCountryCupsPageError;
export const getCountryCupsPage = (state: State): PageType | null => state[NameSpace.Clubs].countryCupsPage;
export const getPastPresentFuturePageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isCountryCupsPageLoading;
export const getPastPresentFuturePageErrorStatus = (state: State): ClubsState['isPastPresentFuturePageError'] => state[NameSpace.Clubs].isPastPresentFuturePageError;
export const getPastPresentFuturePage = (state: State): PageType | null => state[NameSpace.Clubs].pastPresentFuturePage;
export const getCountryTournamentsPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isCountryTournamentsPageLoading;
export const getCountryTournamentsPageErrorStatus = (state: State): ClubsState['isCountryTournamentsPageError'] => state[NameSpace.Clubs].isCountryTournamentsPageError;
export const getCountryTournamentsPage = (state: State): PageType | null => state[NameSpace.Clubs].countryTournamentsPage;
export const getAchievementsPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isAchievementsPageLoading;
export const getAchievementsPageErrorStatus = (state: State): ClubsState['isAchievementsPageError'] => state[NameSpace.Clubs].isAchievementsPageError;
export const getAchievementsPage = (state: State): PageType | null => state[NameSpace.Clubs].achievementsPage;
export const getSymbolismAnthemPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isSymbolismAnthemPageLoading;
export const getSymbolismAnthemPageErrorStatus = (state: State): ClubsState['isSymbolismAnthemPageError'] => state[NameSpace.Clubs].isSymbolismAnthemPageError;
export const getSymbolismAnthemPage = (state: State): PageType | null => state[NameSpace.Clubs].symbolismAnthemPage;
export const getProgramsPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isProgramsPageLoading;
export const getProgramsPageErrorStatus = (state: State): ClubsState['isProgramsPageError'] => state[NameSpace.Clubs].isProgramsPageError;
export const getProgramsPage = (state: State): PageType | null => state[NameSpace.Clubs].programsPage;
export const getGoalkeeperSchoolPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isGoalkeeperSchoolPageLoading;
export const getGoalkeeperSchoolPageErrorStatus = (state: State): ClubsState['isGoalkeeperSchoolPageError'] => state[NameSpace.Clubs].isGoalkeeperSchoolPageError;
export const getGoalkeeperSchoolPage = (state: State): PageType | null => state[NameSpace.Clubs].goalkeeperSchoolPage;
export const getPressPageLoadingStatus = (state: State): boolean => state[NameSpace.Clubs].isPressPageLoading;
export const getPressPageErrorStatus = (state: State): ClubsState['isPressPageError'] => state[NameSpace.Clubs].isPressPageError;
export const getPressPage = (state: State): PageType | null => state[NameSpace.Clubs].pressPage;