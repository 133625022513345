import React from "react";
import {PlayerItem} from "../../../core/types/player";
import {calculateAge, simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {AppRoute} from "../../../core/constants/routes";
import {Link} from "react-router-dom";

function PagePlayersItem({player}: {player: PlayerItem}) {
  const birthDate = player?.birthday ? new Date(player.birthday) : null;
  const src = player._links.photo.href.includes('photo_placeholder_thumb.png') ? '/images/defaults/person.svg' : player._links.photo.href;

  return (
    <li className="persons__item">
      <Link className="persons__link" to={`${AppRoute.Players.url}/${player.player_id}`}>
        <div className="persons__wrap">
          <img className="persons__img" src={src} width="350" height="350" alt={`${player.first_name} ${player.last_name}`}/>
          {/*Этот блок не доработан, данные не приходят, ждем бэк*/}
          {/*<ul className="persons__infos">
            <li className="persons__info">
              <p className="persons__digit">35</p>
              <p className="persons__text">игры</p>
            </li>
            <li className="persons__info">
              <p className="persons__digit">7</p>
              <p className="persons__text">голов</p>
            </li>
          </ul>*/}
        </div>
        <div className="persons__content">
          <div className="persons__row">
            <p hidden={player.number === null} className="persons__number">{player.number}</p>
            <div className="persons__column">
              <p className="persons__name">{player.first_name} {player.last_name}</p>
            </div>
          </div>
          <p className="persons__name" hidden={!birthDate}>{simpleDateFormat(birthDate, 'dd.MM.yyyy')} ({calculateAge(birthDate)})</p>
        </div>
      </Link>
    </li>
  );
}

export default PagePlayersItem;
