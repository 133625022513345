import React from 'react';
import {TeamsStats} from "../../../core/types/team";
import Title from "../title/title";

function TeamMatches({teamStats}: {teamStats: TeamsStats | undefined}) {
  return (
    <>
      <Title title="Матчи"/>
      <table className="stat-table char">
        <tbody>
        <tr>
          <th>Характеристика</th>
          <th style={{width: '15%'}}>Всего</th>
        </tr>
        <tr>
          <td>Всего сыграно матчей</td>
          <td>{teamStats?.stats[0]?.games || 0}</td>
        </tr>
        <tr>
          <td>Побед / Крупных</td>
          <td>{teamStats?.stats[0]?.wins || 0} / {teamStats?.stats[0]?.wins_big || 0}</td>
        </tr>
        <tr>
          <td>Поражений / Крупных</td>
          <td>{teamStats?.stats[0]?.losses || 0} / {teamStats?.stats[0]?.losses_big || 0}</td>
        </tr>
        <tr>
          <td>Ничейных результатов</td>
          <td>{teamStats?.stats[0]?.draws || 0}</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default TeamMatches;
