import React from 'react';
import {TournamentsStats} from "../../../core/types/tournament";
import Title from "../title/title";

function TournamentScore({tournamentStats}: {tournamentStats: TournamentsStats | undefined}) {
  return (
    <>
      <Title title="Крупный счет"/>
      <table className="stat-table char">
        <tbody>
        <tr>
          <th>Характеристика</th>
          <th>Показатель</th>
        </tr>
        <tr>
          <td>Всего зафиксировано побед с крупным счетом</td>
          <td>{tournamentStats?.stats?.wins_big}</td>
        </tr>
        <tr>
          <td>Одержано крупных побед хозяевами</td>
          <td>{tournamentStats?.stats?.wins_big_home}</td>
        </tr>
        <tr>
          <td>Одержано крупных побед гостями</td>
          <td>{tournamentStats?.stats?.wins_big_away}</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default TournamentScore;
