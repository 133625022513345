import React, { useEffect, useState } from "react";
import PostsList from "../../components/ordinary/posts-list/posts-list";
import { AppRoute } from "../../core/constants/routes";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import { getPosts } from "../../core/store/posts-data/selectors";
import { filterPostsByTag } from "../../core/utils/array-prepare-helpers";
import { useBreadcrumbsContext } from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import {

  fetchPosts,
  fetchPressPage,
} from "../../core/store/api-actions";
import {
  getPressPage,
  getPressPageErrorStatus,
  getPressPageLoadingStatus,
} from "../../core/store/clubs-data/selectors";
import Page from "../../components/ordinary/page/page";
import { store } from "../../core/store";
import PressSidebar from '../../components/ordinary/press-sidebar/press-sidebar'
import { POSTS_PER_PAGE } from '../../core/constants/common'
import Pagination from '../../components/smart/pagination/pagination'

store.dispatch(fetchPressPage());

function PressPage() {
  const dispatch = useAppDispatch();
  const pressPage = useAppSelector(getPressPage);
  const isPressPageLoading = useAppSelector(
    getPressPageLoadingStatus
  );
  const isPressPageError = useAppSelector(
    getPressPageErrorStatus
  );
  const posts = useAppSelector(getPosts);
  const filteredPosts = filterPostsByTag(
    posts,
    AppRoute.PressPage.title
  );

  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchPosts());
    setBreadcrumbs([
      AppRoute.Main,
      AppRoute.PressPage,
      {title:"ПУБЛИКАЦИИ (О КЛУБЕ, ПРЕССА)", url:'#'},
    ]);
  }, [dispatch]);

  const totalPages =
    Math.ceil((filteredPosts?.length || 0) / POSTS_PER_PAGE) || 1;
  const start = (currentPage - 1) * POSTS_PER_PAGE;
  const end = start + POSTS_PER_PAGE;
  const currentPosts = filteredPosts.slice(start, end);
  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="container-center">
      <main>
        <Page
          content={pressPage?.text}
          isPageLoading={isPressPageLoading}
          isPageError={isPressPageError}
        />
        <div className="block-press other">
          <div className="container-center">
            <PostsList
              currentPosts={currentPosts}
              tag={AppRoute.PressPage.title}
            />
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              goToPage={goToPage}
            />
          </div>
        </div>
      </main>
      <aside>
        <PressSidebar/>
      </aside>
    </div>
  );
}

export default PressPage;
