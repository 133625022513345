import React from 'react';
import {Team} from "../../../core/types/team";
import PlayersList from "../../smart/players-list/players-list";

function TeamsItem({team}: {team: Team}) {
  return (
    <div hidden={team === null}>
      <PlayersList team={team}/>
    </div>
  );
}

export default TeamsItem;