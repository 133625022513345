import {createSlice} from '@reduxjs/toolkit';
import {fetchRepresentative, fetchRepresentatives} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {RepresentativesState} from "../../types/state";

const initialState: RepresentativesState = {
  isRepresentativesLoading: false,
  isRepresentativesError: false,
  representatives: [],
  isRepresentativeLoading: false,
  isRepresentativeError: false,
  representative: null,
};

export const representativesData = createSlice({
  name: NameSpace.Representatives,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRepresentatives.pending, (state) => {
        state.isRepresentativesLoading = true;
      })
      .addCase(fetchRepresentatives.fulfilled, (state, action) => {
        state.representatives = action.payload;
        state.isRepresentativesLoading = false;
      })
      .addCase(fetchRepresentatives.rejected, (state) => {
        state.isRepresentativesError = true;
        state.isRepresentativesLoading = false;
      })
      .addCase(fetchRepresentative.pending, (state) => {
        state.isRepresentativeLoading = true;
      })
      .addCase(fetchRepresentative.fulfilled, (state, action) => {
        state.representative = action.payload;
        state.isRepresentativeLoading = false;
      })
      .addCase(fetchRepresentative.rejected, (state) => {
        state.isRepresentativeError = true;
        state.isRepresentativeLoading = false;
      })
  }
});
