import Dropdown from "../../ordinary/dropdown/dropdown";
import React from "react";
import {teamOptions} from "../../../core/constants/common";
import {
  DropdownTypes,
  ItemsForFilter
} from "../../../core/constants/filters";
import RoundDropdown from "../../ordinary/round-dropdown/round-dropdown";

function Filters() {
  return (
    <ul className="filters">
      <li className="filters__item filters__item--105">
        <Dropdown options={teamOptions} type={DropdownTypes.Team} items={ItemsForFilter.TableTeams}/>
      </li>
      <li className="filters__item filters__item--260">
        <RoundDropdown/>
      </li>
    </ul>
  );
}

export default Filters;
