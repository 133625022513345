import React from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../core/hooks';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {
  fetchTeam,
  fetchTeamStats,
  fetchTournament
} from '../../core/store/api-actions';
import Loader from '../../components/ordinary/loader/loader';
import Error from '../error/error';
import 'font-awesome/css/font-awesome.min.css';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import {getTournament} from "../../core/store/tournaments-data/selectors";
import {TournamentsNavigations} from "../../core/constants/navigations";
import TournamentNavigation from "../../components/ordinary/tournament-navigation/tournament-navigation";
import {
  getTeam,
  getTeamErrorStatus,
  getTeamLoadingStatus,
  getTeamStats
} from "../../core/store/teams-data/selectors";
import {findTeamStatsById} from "../../core/utils/array-prepare-helpers";
import TeamMatches from "../../components/ordinary/team-matches/team-matches";
import TeamGoals from "../../components/ordinary/team-goals/team-goals";

function TournamentTeam() {
  const {setBreadcrumbs} = useBreadcrumbsContext();
  const dispatch = useAppDispatch();
  const {tournamentId: tournamentId, teamId: teamId} = useParams();
  const tournament = useAppSelector(getTournament);
  const team = useAppSelector(getTeam);
  const isTeamError = useAppSelector(getTeamErrorStatus);
  const isTeamLoading = useAppSelector(getTeamLoadingStatus);
  const teamsStats = useAppSelector(getTeamStats);
  const teamStats = findTeamStatsById(teamsStats, Number(teamId));
  useEffect(() => {
    dispatch(fetchTournament(Number(tournamentId)));
  }, [tournamentId]);

  useEffect(() => {
    dispatch(fetchTeam(Number(teamId)));
    dispatch(fetchTeamStats({teamId:(Number(teamId)),groupBy:'tournament_id', tournamentId:(Number(tournamentId))}));
  }, [teamId]);

  useEffect(() => {
    if (tournament && team) {
      setBreadcrumbs([
        AppRoute.Main,
        AppRoute.Tournaments,
        {
          title: tournament.full_name,
          url: `${AppRoute.Tournaments.url}/${tournamentId}/info`
        },
        {
          title: AppRoute.TournamentTeams.title,
          url: `${AppRoute.Tournaments.url}/${tournamentId}${AppRoute.Teams.url}`
        },
        {
          title: team.full_name,
          url: "#"
        },
      ]);
    }
  }, [tournament, team]);

  return (
    <>
      <div className="container-center" hidden={isTeamLoading}>
        <main>
          <div hidden={!isTeamLoading}>
            <Loader/>
          </div>
          <div hidden={!isTeamError || isTeamLoading}>
            <Error/>
          </div>
          <div className="stats other" hidden={isTeamError || isTeamLoading}>
            <TeamMatches teamStats={teamStats}/>
            <br/>
            <TeamGoals teamStats={teamStats}/>
          </div>
        </main>
        <aside>
          <TournamentNavigation navigationArray={TournamentsNavigations} id={tournamentId}/>
        </aside>
      </div>
    </>
  );
}

export default TournamentTeam;
