import React from 'react';
import {PlayerItems} from "../../../core/types/player";
import PlayersRow from "../players-row/players-row";
import Title from "../title/title";
import PlayersHead from "../players-head/players-head";

function PlayersGroup({players, title}: {players: PlayerItems | undefined, title: string}) {
  return (
    <>
      <Title title={title}/>
      <table className="stat-table">
        <tbody>
        <PlayersHead/>
        {players?.map((player) =>
          <PlayersRow key={player.player_id} player={player}/>
        )}
        <tr hidden={players !== undefined && players.length > 0}>
          <td colSpan={10} style={{textAlign: "center"}}>Таблица пуста</td>
        </tr>
        </tbody>
      </table>
    </>
  );
}

export default PlayersGroup;
