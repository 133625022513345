import React from "react";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { Tournament } from "../../../core/types/tournament";
import { tournamentTypes } from "../../../core/constants/tournament";
import MatchTeamUnit from "../match-team-unit/match-team-unit";
import { Match } from "../../../core/types/match";
import { formatValue } from '../../../core/utils/common-helpers'

function MatchHead({ match, tournament }: {
  match: Match | null;
  tournament: Tournament | null | undefined;
}) {
  const startDate = match && match.start_dt ? new Date(match.start_dt) : null;
  let tournamentType;

  if (
    tournament &&
    tournament.category &&
    tournamentTypes[tournament.category]
  ) {
    tournamentType = tournamentTypes[tournament.category];
  } else {
    tournamentType = tournamentTypes.undefined;
  }

  return (
    <div className="match-detail">
      <div className="title-game">
        {simpleDateFormat(startDate, "d MMMM yyyy 'года' HH:mm")},{" "}
        {tournamentType}. {tournament?.full_name}.
      </div>
      <div className="teams-line">
        <MatchTeamUnit team={match?.team1} />
        <div className="team-result">
          <div className="team-column">
            <span>{formatValue(match?.gf)}<span>:</span>{formatValue(match?.ga)}</span>
            <span hidden={match?.gfp === null && match?.gap === null} className="team-additional">{match?.gfp}<span>:</span>{match?.gap}</span>
          </div>
        </div>
        <MatchTeamUnit team={match?.team2} />
      </div>
    </div>
  );
}

export default MatchHead;
