import {createSlice} from '@reduxjs/toolkit';
import {fetchRoundPlayers} from '../api-actions';
import {RoundPlayersState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: RoundPlayersState = {
  roundPlayersLoading: false,
  roundPlayersError: false,
  roundPlayers: [],
};

export const roundPlayersData = createSlice({
  name: NameSpace.RoundPlayers,
  initialState,
  reducers: {
    resetRoundPlayers: (state) => {
      state.roundPlayers = initialState.roundPlayers;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRoundPlayers.pending, (state) => {
        state.roundPlayersLoading = true;
      })
      .addCase(fetchRoundPlayers.fulfilled, (state, action) => {
        state.roundPlayers = action.payload;
        state.roundPlayersLoading = false;
      })
      .addCase(fetchRoundPlayers.rejected, (state) => {
        state.roundPlayersError = true;
        state.roundPlayersLoading = false;
      })
  }
});