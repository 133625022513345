import React from 'react';
import {NavigationsType} from "../../../core/types/navigation";
import {Link} from "react-router-dom";
import Title from "../title/title";
import {AppRoute} from "../../../core/constants/routes";

function TournamentNavigation({navigationArray, id} : {navigationArray: NavigationsType, id: string | undefined}) {
  return (
    <>
      <Title title="Разделы"/>
      <ul className="root-list">
        {navigationArray.map((navigation) => {
          const isActive = location.pathname.includes(navigation.url);
          const className = isActive ? 'active' : '';
          return (
            <li key={navigation.url} className={className}>
              <Link to={`${AppRoute.Tournaments.url}/${id}${navigation.url}`}>{navigation.title}</Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default TournamentNavigation;
