import {
  State,
  TeamsState
} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {
  Team,
  TeamGroups,
  Teams,
  TeamsStatsArray,
  TournamentTeamsGroups
} from "../../types/team";

export const getTeamsLoadingStatus = (state: State): boolean => state[NameSpace.Teams].isTeamsLoading;
export const getTeamsErrorStatus = (state: State): TeamsState['isTeamsError'] => state[NameSpace.Teams].isTeamsError;
export const getTeams = (state: State): Teams => state[NameSpace.Teams].teams;
export const getTeamLoadingStatus = (state: State): boolean => state[NameSpace.Teams].isTeamLoading;
export const getTeamErrorStatus = (state: State): TeamsState['isTeamError'] => state[NameSpace.Teams].isTeamError;
export const getTeam = (state: State): Team | null => state[NameSpace.Teams].team;
export const getTeamStatsLoadingStatus = (state: State): boolean => state[NameSpace.Teams].isTeamsStatsLoading;
export const getTeamStatsErrorStatus = (state: State): TeamsState['isTeamsStatsError'] => state[NameSpace.Teams].isTeamsStatsError;
export const getTeamStats = (state: State): TeamsStatsArray => state[NameSpace.Teams].teamsStats;
export const getGroupTeamLoadingStatus = (state: State): boolean => state[NameSpace.Teams].isGroupTeamLoading;
export const getGroupTeamErrorStatus = (state: State): TeamsState['isGroupTeamError'] => state[NameSpace.Teams].isGroupTeamError;
export const getGroupTeam = (state: State): TeamGroups => state[NameSpace.Teams].groupTeam;
export const getTournamentTeamsLoadingStatus = (state: State): boolean => state[NameSpace.Teams].isTournamentTeamsLoading;
export const getTournamentTeamsErrorStatus = (state: State): TeamsState['isTournamentTeamsError'] => state[NameSpace.Teams].isTournamentTeamsError;
export const getTournamentTeams = (state: State): Teams => state[NameSpace.Teams].tournamentTeams;
export const getTournamentTeamsGroupsLoadingStatus = (state: State): boolean => state[NameSpace.Teams].isTournamentTeamsGroupsLoading;
export const getTournamentTeamsGroupsErrorStatus = (state: State): TeamsState['isTournamentTeamsGroupsError'] => state[NameSpace.Teams].isTournamentTeamsGroupsError;
export const getTournamentTeamsGroups = (state: State): TournamentTeamsGroups => state[NameSpace.Teams].tournamentTeamsGroups;
