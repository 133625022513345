import React from "react";
import Message from "../message/message";
import {RepresentativeItems} from "../../../core/types/representative";
import RepresentativesList from "../representatives-list/representatives-list";

function Representatives({representatives, title}: {representatives: RepresentativeItems | undefined, title: string}) {
  return (
    <>
      <div hidden={representatives?.length === 0}>
        <RepresentativesList representatives={representatives} title={title}/>
      </div>
      <div hidden={representatives === undefined || representatives.length > 0}>
        <Message message={`${title} отсутствуют.`}/>
      </div>
    </>
  );
}

export default Representatives;