import {createSlice} from '@reduxjs/toolkit';
import {fetchContactsPage} from '../api-actions';
import {ContactsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: ContactsState = {
  isContactsPageLoading: false,
  isContactsPageError: false,
  contactsPage: null
};

export const contactsData = createSlice({
  name: NameSpace.Contacts,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchContactsPage.pending, (state) => {
        state.isContactsPageLoading = true;
      })
      .addCase(fetchContactsPage.fulfilled, (state, action) => {
        state.contactsPage = action.payload;
        state.isContactsPageLoading = false;
      })
      .addCase(fetchContactsPage.rejected, (state) => {
        state.isContactsPageError = true;
        state.isContactsPageLoading = false;
      })
  }
});
