import React, {useEffect} from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getBanners,
  getBannersErrorStatus
} from "../../../core/store/banners-data/selectors";
import {filterBannersByPosition} from "../../../core/utils/array-prepare-helpers";
import {BannersPositions} from "../../../core/constants/common";
import {fetchBanners} from "../../../core/store/api-actions";
import SidebarBannersItem from "../../ordinary/sidebar-banners-item/sidebar-banners-item";

function SidebarBannersList() {
  const dispatch = useAppDispatch();
  const banners = useAppSelector(getBanners);
  const isBannersError = useAppSelector(getBannersErrorStatus);
  const filteredBanners = filterBannersByPosition(banners, BannersPositions.Sidebar);

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  return (
    <div hidden={isBannersError || filteredBanners.length <= 0}>
      {filteredBanners.map((banner) => <SidebarBannersItem key={banner.banner_id} banner={banner}/>)}
    </div>
  );
}

export default SidebarBannersList;