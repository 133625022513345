import React from 'react';
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function MainLogo() {
  return (
    <div className="logo-sm">
      <Link to={AppRoute.Main.url}>
        <img src="images/logo-sm.png" width="152" height="151" alt="Главное лого"/>
      </Link>
    </div>
  );
}

export default MainLogo;