import React from 'react';
import Message from "../../components/ordinary/message/message";

function Error() {
  return (
    <main className="full">
      <div className="container-center">
        <Message message="Страница ошибки..."/>
      </div>
    </main>
  );
}

export default Error;
