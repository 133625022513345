import {createSlice} from '@reduxjs/toolkit';
import {fetchRoundTeams} from '../api-actions';
import {RoundTeamsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: RoundTeamsState = {
  roundTeamsLoading: false,
  roundTeamsError: false,
  roundTeams: [],
};

export const roundTeamsData = createSlice({
  name: NameSpace.RoundTeams,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchRoundTeams.pending, (state) => {
        state.roundTeamsLoading = true;
      })
      .addCase(fetchRoundTeams.fulfilled, (state, action) => {
        state.roundTeams = action.payload;
        state.roundTeamsLoading = false;
      })
      .addCase(fetchRoundTeams.rejected, (state) => {
        state.roundTeamsError = true;
        state.roundTeamsLoading = false;
      })
  }
});
