import React from 'react';
import {Feedbacks} from "../../../core/types/feedback";
import FeedbackItem from "../feedback-item/feedback-item";
import Loader from "../loader/loader";
import {useAppSelector} from "../../../core/hooks";
import {
  getFeedbacksErrorStatus,
  getFeedbacksLoadingStatus
} from "../../../core/store/feedbacks-data/selectors";

function FeedbackList({currentFeedbacks}: { currentFeedbacks: Feedbacks }) {
  const isFeedbacksLoading = useAppSelector(getFeedbacksLoadingStatus);
  const isFeedbacksError = useAppSelector(getFeedbacksErrorStatus);

  return (
    <>
      <div hidden={!isFeedbacksLoading}>
        <Loader/>
      </div>
      <div hidden={!isFeedbacksError} className="easy-text">
        <p>Произошла ошибка при загрузке вопросов.</p>
      </div>
      <div hidden={currentFeedbacks.length === 0}>
        {currentFeedbacks.map((feedback) => <FeedbackItem key={feedback.feedback_id} feedback={feedback}/>)}
      </div>
      <div hidden={isFeedbacksLoading || currentFeedbacks.length > 0} className="easy-text">
        <p>На данный момент здесь нет часто задаваемых вопросов. Если у вас есть конкретные вопросы, пожалуйста, свяжитесь с нами.</p>
      </div>
    </>
  );
}

export default FeedbackList;
