import React, {useEffect} from 'react';
import {AppRoute} from "../../core/constants/routes";
import SidebarNavigation from "../../components/ordinary/sidebar-navigation/sidebar-navigation";
import {ClubNavigations} from "../../core/constants/navigations";
import {useAppSelector} from "../../core/hooks";
import {useBreadcrumbsContext} from "../../components/smart/breadcrumbs-provider/breadcrumbs-provider";
import {store} from "../../core/store";
import {fetchAchievementsPage, fetchTeams} from "../../core/store/api-actions";
import {
  getAchievementsPage,
  getAchievementsPageErrorStatus,
  getAchievementsPageLoadingStatus
} from "../../core/store/clubs-data/selectors";
import Page from "../../components/ordinary/page/page";
import ClubContent from "../../components/ordinary/club-content/club-content";

store.dispatch(fetchAchievementsPage());
store.dispatch(fetchTeams());

function Achievements() {
  const achievementsPage = useAppSelector(getAchievementsPage);
  const isAchievementsPageLoading = useAppSelector(getAchievementsPageLoadingStatus);
  const isAchievementsPageError = useAppSelector(getAchievementsPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AboutClub, AppRoute.Achievements]);
  }, []);

  return (
    <div className="container-center">
      <main>
        <Page content={achievementsPage?.text} isPageLoading={isAchievementsPageLoading} isPageError={isAchievementsPageError}/>
        <ClubContent/>
      </main>
      <aside>
        <SidebarNavigation navigationArray={ClubNavigations}/>
      </aside>
    </div>
  );
}

export default Achievements;