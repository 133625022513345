import React from 'react';
import PartnersItem from "../partners-item/partners-item";
import {Partners} from "../../../core/types/partners";

function PartnersList({partners}: {partners: Partners}) {
  return (
    <ul className={`partners-list ${partners.length === 0 ? 'hidden' : ''}`}>
      {partners.map((partner) => <PartnersItem key={partner.partner_id} partner={partner}/>)}
    </ul>
  );
}

export default PartnersList;