import React from 'react';
import {Link} from 'react-router-dom';
import {Post} from '../../../core/types/post';
import {boldDayDateFormat} from "../../../core/utils/date-time-helpers";
import {AppRoute} from "../../../core/constants/routes";

function PostItem({post, tag}: {post: Post, tag?: string | undefined}) {
  const postDate = new Date(post.published_dt);

  return (
    <li>
      <div hidden={tag === undefined} className="category">{tag}</div>
      <div hidden={tag !== undefined}>
        {boldDayDateFormat(postDate)}
      </div>
      <Link to={`${AppRoute.Posts.url}/${post.post_id}`}>
        <div className="image">
          <img src={post._links.image870x490.href} width="420" height="420" alt={post.title}/>
        </div>
        <div className="title">{post.title}</div>
        <div className="desc">{post.lead}</div>
      </Link>
    </li>
  );
}

export default PostItem;
