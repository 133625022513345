import React from 'react';
import Title from "../title/title";
import TournamentMatchesItem from "../tournament-matches-item/tournament-matches-item";
import {Matches} from "../../../core/types/match";

function TournamentMatches({title, matches, tournament}: {title?: string | undefined | null, matches: Matches, tournament:number}) {
  return (
    <>
      <div hidden={title === null}>
        <Title title={title === '1 января 1970 года, четверг:' ? 'Матчи без даты' : title}/>
      </div>
      <ul className="other-games-list">
        {matches.map((match) => <TournamentMatchesItem key={match.match_id} match={match} tournament={tournament}/>)}
      </ul>
    </>
  );
}

export default TournamentMatches;
