import React from "react";
import { PlayerItem } from "../../../core/types/player";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function PlayerBiography({ player }: { player: PlayerItem | null }) {
  return (
    <div className="redactor-styles">
      <HtmlRenderer htmlString={player?.about_me} />
    </div>
  );
}

export default PlayerBiography;
