import React, {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import Page from "../../components/ordinary/page/page";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {fetchMassSkatingPage} from "../../core/store/api-actions";
import MassSkatingSidebar from "../../components/ordinary/mass-skating-sidebar/mass-skating-sidebar";
import {
  getMassSkatingPage,
  getMassSkatingPageErrorStatus,
  getMassSkatingPageLoadingStatus
} from "../../core/store/mass-skating-data/selectors";
import FeedbackComponent from "../../components/ordinary/feedback-component/feedback-component";

function MassSkating() {
  const dispatch = useAppDispatch();
  const massSkatingPage = useAppSelector(getMassSkatingPage);
  const isMassSkatingPageLoading = useAppSelector(getMassSkatingPageLoadingStatus);
  const isMassSkatingPageError = useAppSelector(getMassSkatingPageErrorStatus);
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.MassSkating]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    dispatch(fetchMassSkatingPage());
  }, [dispatch]);

  return (
    <div className="container-center">
      <main>
        <Page content={massSkatingPage?.text} isPageLoading={isMassSkatingPageLoading} isPageError={isMassSkatingPageError}/>
        <FeedbackComponent title="Обратная связь"/>
      </main>
      <MassSkatingSidebar/>
    </div>
  );
}

export default MassSkating;