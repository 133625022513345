import React, {useEffect} from 'react';
import {useBreadcrumbsContext} from '../../components/smart/breadcrumbs-provider/breadcrumbs-provider';
import {AppRoute} from "../../core/constants/routes";
import FeedbackComponent from "../../components/ordinary/feedback-component/feedback-component";

function AddFaq() {
  const {setBreadcrumbs} = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumbs([AppRoute.Main, AppRoute.AddFaq]);
  }, [setBreadcrumbs]);

  return (
    <div className="container-center">
      <main>
        <FeedbackComponent title="Задать вопрос"/>
      </main>
    </div>
  );
}

export default AddFaq;