import React from 'react';
import {Tournaments} from "../../../core/types/tournament";
import {splitTournaments} from "../../../core/utils/array-prepare-helpers";
import TournamentsList from "../tournaments-lists/tournaments-lists";
import {AppRoute} from "../../../core/constants/routes";
import {Link} from "react-router-dom";
import {tournamentTypes} from "../../../core/constants/tournament";

function TournamentsGroup({tournamentsType, tournaments}: {
  tournamentsType: string,
  tournaments: Tournaments
}) {
  const {activeTournaments} = splitTournaments(tournaments);

  return (
    <div className="easy-text stat-colums">
      <TournamentsList tournaments={activeTournaments} title={tournamentTypes[tournamentsType]}/>
      <ul>
        <li>
          <Link to={`${AppRoute.Tournaments.url}/completed/${tournamentsType}`}>{tournamentTypes[tournamentsType]} - завершившиеся соревнования</Link>
        </li>
      </ul>
    </div>
  );
}

export default TournamentsGroup;