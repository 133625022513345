import React from 'react';
import {RepresentativeItems} from "../../../core/types/representative";
import RepresentativesItem from "../representative-item/representatives-item";
import Title from "../title/title";

function RepresentativesList({representatives, title}: {representatives: RepresentativeItems | undefined, title?: string | undefined}) {
  return (
    <>
      <div hidden={representatives?.length === 0}>
        <Title title={title}/>
        <ul className="persons">
          {representatives?.map((representative) => <RepresentativesItem key={representative.staff_id} representative={representative}/>)}
        </ul>
      </div>
    </>
  );
}

export default RepresentativesList;