import React, {ReactElement} from 'react';
import Header from '../../smart/header/header';
import Footer from '../../smart/footer/footer';
import BannersSlider from "../banners-slider/banners-slider";
import {useLocation} from "react-router-dom";

function Layout({children}: {children: ReactElement}) {
  const isMainPage = useLocation().pathname === '/';

  return (
    <div className="container">
      <Header/>
      <div hidden={!isMainPage}>
        <BannersSlider/>
      </div>
      <div className="content">
        {children}
      </div>
      <Footer/>
    </div>
  );
}

export default Layout;
