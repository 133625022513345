import React from "react";
import TabsItem from "../tabs-item/tabs-item";
import { TabType } from "../../../core/types/common";

function TabsList({
  activeTab,
  onTabClick,
  showYeniseyTab,
  showYenisey2Tab,
}: {
  activeTab: TabType;
  onTabClick: (tab: TabType) => void;
  showYeniseyTab: boolean;
  showYenisey2Tab: boolean;
}) {
  return (
    <div className="change-team">
      <TabsItem
        title="Енисей"
        isActive={activeTab === "yenisey"}
        onClick={() => onTabClick("yenisey")}
        hidden={!showYeniseyTab}
      />
      <TabsItem
        title="Енисей 2"
        isActive={activeTab === "yenisey-2"}
        onClick={() => onTabClick("yenisey-2")}
        hidden={!showYenisey2Tab}
      />
    </div>
  );
}

export default TabsList;
