import React from 'react';

const HtmlRenderer = ({ htmlString }: { htmlString: string | undefined }) => {
  if (htmlString !== undefined) {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  } else {
    return null;
  }
};

export default HtmlRenderer;