import React from "react";

function TabsItem({title, isActive, onClick, hidden}: {
  title: string,
  isActive: boolean,
  onClick?: () => void,
  hidden: boolean
}) {
  const className = isActive ? 'active' : '';

  if (hidden) {
    return null;
  }

  return (
    <span className={className} onClick={onClick}>{title}</span>
  );
}

export default TabsItem;