import React from 'react';
import PostsWidget from '../../components/ordinary/posts-widget/posts-widget';
import PhotosWidget from '../../components/ordinary/photos-widget/photos-widget';
import VideosWidget from "../../components/ordinary/videos-widget/videos-widget";
import InfoWidget from "../../components/simple/info-widget/info-widget";
import TrophiesWidget from "../../components/ordinary/trophies-widget/trophies-widget";
import BirthDatesWidget from "../../components/ordinary/birthdates-widget/birthdates-widget";
import PartnersWidget from "../../components/ordinary/partners-widget/partners-widget";

function Main() {
  return (
    <>
      <InfoWidget/>
      <PostsWidget/>
      <TrophiesWidget/>
      <VideosWidget/>
      <PhotosWidget/>
      <BirthDatesWidget/>
      <PartnersWidget/>
    </>
  );
}

export default Main;
