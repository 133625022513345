import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NameSpace } from "../../constants/api-constants";
import { SendRequestStatus } from "../../constants/common";
import { SearchesState } from "../../types/state";
import { fetchSearchedPosts } from "../api-actions";

const initialState: SearchesState = {
  searchedPosts: [],
  searchedPostsLoading: false,
  searchedPostsError: false,
  searchedPostsTotal: 0,
  searchStatus: SendRequestStatus.Normal,
  searchString: "",
};

export const searchesData = createSlice({
  name: NameSpace.Posts,
  initialState,
  reducers: {
    savingSearchString: (
      state,
      action: PayloadAction<{ searchString: string }>
    ) => {
      const { searchString } = action.payload;
      state.searchString = searchString;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSearchedPosts.pending, (state) => {
        state.searchedPostsLoading = true;
      })
      .addCase(fetchSearchedPosts.fulfilled, (state, action) => {
        state.searchStatus = SendRequestStatus.Success;
        state.searchedPosts = action.payload;
        state.searchedPostsLoading = false;
      })
      .addCase(fetchSearchedPosts.rejected, (state) => {
        state.searchStatus = SendRequestStatus.UnSuccess;
        state.searchedPostsError = true;
        state.searchedPostsLoading = false;
      });
  },
});

export const { savingSearchString } = searchesData.actions;
