import {createSlice} from '@reduxjs/toolkit';
import {TrophiesState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {fetchTrophies} from "../api-actions";

const initialState: TrophiesState = {
  isTrophiesLoading: false,
  isTrophiesError: false,
  trophies: [],
};

export const trophiesData = createSlice({
  name: NameSpace.Trophies,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTrophies.pending, (state) => {
        state.isTrophiesLoading = true;
      })
      .addCase(fetchTrophies.fulfilled, (state, action) => {
        state.trophies = action.payload;
        state.isTrophiesLoading = false;
      })
      .addCase(fetchTrophies.rejected, (state) => {
        state.isTrophiesError = true;
        state.isTrophiesLoading = false;
      })
  }
});
