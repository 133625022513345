import React from "react";
import { Link } from "react-router-dom";
import { PhotoGallery } from "../../../core/types/media";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { AppRoute } from "../../../core/constants/routes";

function PhotosSliderItem({ photo }: { photo: PhotoGallery }) {
  const photoDate = new Date(photo.published_dt);

  return (
    <div className="slides__item">
      <Link
        className="slides__link"
        to={`${AppRoute.PhotoGalleries.url}/${photo.album_id}`}
      >
        <div className="slides__relative">
          <img
            className="slides__img"
            src={photo._links.coverUrl.href}
            width="690"
            height="386"
            alt={photo.title}
          />
        </div>
        <div className="slides__content">
          <p className="slides__title">{photo.title}</p>
          <div className="slides__text">
            <HtmlRenderer htmlString={photo.description} />
          </div>
          <p className="slides__date">
            {simpleDateFormat(photoDate, "dd.MM.yyyy")}
          </p>
        </div>
      </Link>
    </div>
  );
}

export default PhotosSliderItem;
